import React from 'react';

import { ContainerModal, ModalContent, ModalBody, ModalHeader, ModalTitle, Description } from './styles';

const IconClose = () => {
  return (
    <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.9158 6.00409L12.2158 1.71409C12.4041 1.52579 12.5099 1.27039 12.5099 1.00409C12.5099 0.73779 12.4041 0.482395 12.2158 0.294092C12.0275 0.105788 11.7721 0 11.5058 0C11.2395 0 10.9841 0.105788 10.7958 0.294092L6.5058 4.59409L2.2158 0.294092C2.02749 0.105788 1.7721 -1.9841e-09 1.5058 0C1.2395 1.9841e-09 0.984102 0.105788 0.795798 0.294092C0.607495 0.482395 0.501707 0.73779 0.501707 1.00409C0.501707 1.27039 0.607495 1.52579 0.795798 1.71409L5.0958 6.00409L0.795798 10.2941C0.70207 10.3871 0.627676 10.4977 0.576907 10.6195C0.526138 10.7414 0.5 10.8721 0.5 11.0041C0.5 11.1361 0.526138 11.2668 0.576907 11.3887C0.627676 11.5105 0.70207 11.6211 0.795798 11.7141C0.888761 11.8078 0.999362 11.8822 1.12122 11.933C1.24308 11.9838 1.37379 12.0099 1.5058 12.0099C1.63781 12.0099 1.76852 11.9838 1.89038 11.933C2.01223 11.8822 2.12284 11.8078 2.2158 11.7141L6.5058 7.41409L10.7958 11.7141C10.8888 11.8078 10.9994 11.8822 11.1212 11.933C11.2431 11.9838 11.3738 12.0099 11.5058 12.0099C11.6378 12.0099 11.7685 11.9838 11.8904 11.933C12.0122 11.8822 12.1228 11.8078 12.2158 11.7141C12.3095 11.6211 12.3839 11.5105 12.4347 11.3887C12.4855 11.2668 12.5116 11.1361 12.5116 11.0041C12.5116 10.8721 12.4855 10.7414 12.4347 10.6195C12.3839 10.4977 12.3095 10.3871 12.2158 10.2941L7.9158 6.00409Z"
        fill="#101010"
      />
    </svg>
  );
};

const Modal = ({ children, showModal, setShowModal, title, description }: any) => {
  const content = showModal && (
    <ContainerModal className="overlay">
      <ModalContent>
        <ModalBody>
          <ModalHeader>
            <ModalTitle>{title}</ModalTitle>
            <button className="modal-close" type="button" onClick={() => setShowModal(false)}>
              <IconClose />
            </button>
          </ModalHeader>

          <Description>{description}</Description>
          {children}
        </ModalBody>
      </ModalContent>
    </ContainerModal>
  );

  return content;
};
export default Modal;
