import styled, { css } from 'styled-components';

interface InputUploadStyleProps {
  disabled?: boolean;
  hasError?: boolean;
  viewDocument?: boolean;
}

export const Container = styled.div<InputUploadStyleProps>`
  width: 100%;
  border: solid 1px #ededed;
  border-radius: 8px;
  padding: 16px;
  min-height: 76px;

  @media (max-width: 1270px) {
    width: 95%;
  }

  ${({ disabled }) =>
    disabled &&
    `  
     background: rgba(246, 246, 246, 0.5);
    `}

  ${({ hasError }) =>
    hasError &&
    `  
     background: #FFF5F5;
     border-color: #FFDBDC;
    `}

  &.Uploading {
    background: rgba(230, 245, 254, 0.4);
    border: 1px solid #b9e2fe;
  }
`;

export const Label = styled.div`
  flex-direction: row;
  align-items: center;
  width: 100%;

  @media (max-width: 1270px) {
    flex-direction: column;
    button {
      margin-right: 0px;
    }
  }
`;

export const Content = styled.div`
  flex-direction: column;
  margin: 0 16px;
  flex-wrap: wrap;
  width: auto;
  white-space: nowrap;
  overflow: hidden;
  flex: 1;

  @media (max-width: 1270px) {
    padding-bottom: 10px;
  }
`;

export const ImageSvg = styled.div`
  width: 32px;
  height: 32px;
`;

export const Title = styled.div`
  flex-direction: column;
  font-weight: bold;
  font-size: 12px;
  width: 100%;
  color: #101010;
  font-weight: 400;
  text-overflow: ellipsis;
  line-height: 16px;
  font-family: 'Clan Web Pro Medium';
  white-space: nowrap;
  overflow: hidden;
  margin-bottom: 4px;
`;

export const SubTitle = styled.div<InputUploadStyleProps>`
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: block;
  font-family: 'Clan Web Pro News';
  letter-spacing: 1%;
  color: #646464;

  ${({ hasError }) =>
    hasError &&
    `  
     color: #D02931;
    `}
`;

export const Size = styled.div`
  font-size: 12px;
  font-family: 'Clan Web Pro Medium';
  letter-spacing: 1%;
  color: #646464;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 16px;
`;

export const Circle = styled.div`
  background-color: #646464;
  height: 5px;
  width: 5px;
  border-radius: 50%;
  margin-left: 8px;
  margin-right: 8px;
  line-height: 16px;
`;

export const Upload = styled.input`
  display: none;
`;

export const SimpleLinkButton = styled.button<InputUploadStyleProps>`
  cursor: pointer;
  background: none;
  border: none;
  offset: none;
  width: auto;
  height: auto;

  ${({ viewDocument }) =>
    viewDocument &&
    `
    margin-right: 16px;
    `}
`;

export const SimpleButton = styled.button<InputUploadStyleProps>`
  cursor: initial;
  background: none;
  border: none;
  offset: none;
  width: auto;
  height: auto;

  ${({ viewDocument }) =>
    viewDocument &&
    `
    margin-right: 16px;
    `}
`;

export const SpanButton = styled.span`
  cursor: pointer;
  font-family: 'Clan Web Pro News';
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #d02931;

  svg {
    margin-left: 12px;
  }
`;

export const LoadingStyle = styled.div`
  width: auto;
  font-weight: 500;
  font-size: 10px;
  line-height: 16px;
  color: #0090ff;
  align-items: center;
`;
export const LoadingBar = styled.div`
  background: #a0d8fd;
  height: 4px;
  width: 90px;
  margin-left: 8px;
  margin-right: 16px;
  border-radius: 8px;
  overflow: hidden;
`;
export const LoadingBarComplete = styled.div`
  background: #0090ff;
  width: 0%;
`;
