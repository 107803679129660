import React from 'react';

export const Logo = () => {
  return (
    <svg width="112" height="42" viewBox="0 0 112 42" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M55.4249 2.3662C55.8535 2.3662 56.2138 2.52394 56.5254 2.82958C56.8274 3.13521 56.9832 3.50986 56.9832 3.94366V8.38028C56.9832 8.81409 56.8274 9.18873 56.5254 9.49437C56.2235 9.8 55.8535 9.95775 55.4249 9.95775H49.3867V0H50.458V2.3662H55.4249ZM55.9021 8.36056V3.96338C55.9021 3.82535 55.8535 3.70704 55.7561 3.60845C55.6587 3.50986 55.5418 3.46056 55.4055 3.46056H50.9644C50.8281 3.46056 50.7112 3.50986 50.6041 3.60845C50.5067 3.70704 50.4483 3.82535 50.4483 3.96338V8.37042C50.4483 8.50845 50.497 8.62676 50.6041 8.72535C50.7015 8.82394 50.8281 8.87324 50.9644 8.87324H55.4055C55.5418 8.87324 55.6587 8.82394 55.7561 8.72535C55.8535 8.62676 55.9021 8.50845 55.9021 8.36056Z"
        fill="white"
      />
      <path
        d="M66.1193 2.36621C66.5576 2.36621 66.9277 2.52396 67.2296 2.82959C67.5315 3.13523 67.6873 3.50987 67.6873 3.95353V9.95776H61.6491C61.2206 9.95776 60.8505 9.80001 60.5486 9.49438C60.2466 9.18875 60.0908 8.8141 60.0908 8.37044V5.61973H66.616V3.95353C66.616 3.81551 66.5673 3.6972 66.47 3.59861C66.3726 3.50001 66.2557 3.45072 66.1193 3.45072H60.0908V2.36621H66.1193ZM66.616 8.87325V6.70424H61.1621V8.37044C61.1621 8.50846 61.2108 8.62677 61.3082 8.72537C61.4056 8.82396 61.5225 8.87325 61.6588 8.87325H66.616Z"
        fill="white"
      />
      <path
        d="M77.0363 2.36621C77.4648 2.36621 77.8349 2.52396 78.1368 2.82959C78.4387 3.13523 78.5946 3.50987 78.5946 3.95353V9.95776H77.5233V3.96339C77.5233 3.82537 77.4746 3.70706 77.3772 3.60846C77.2798 3.50987 77.1629 3.46058 77.0266 3.46058H72.5855C72.4492 3.46058 72.3323 3.50987 72.2252 3.60846C72.1278 3.70706 72.0694 3.82537 72.0694 3.96339V9.95776H70.998V2.36621H77.0363Z"
        fill="white"
      />
      <path
        d="M83.3866 8.87325H89.5028V9.95776H83.4645C83.036 9.95776 82.6659 9.80001 82.364 9.49438C82.0621 9.18875 81.9062 8.8141 81.9062 8.37044V3.95353C81.9062 3.51973 82.0621 3.14508 82.364 2.82959C82.6659 2.52396 83.036 2.36621 83.4645 2.36621H89.5028V3.45072H83.3866C83.2503 3.45072 83.1236 3.50001 83.0262 3.59861C82.9289 3.6972 82.8802 3.81551 82.8802 3.95353V8.36058C82.8802 8.49861 82.9289 8.61692 83.0262 8.71551C83.1334 8.82396 83.2503 8.87325 83.3866 8.87325Z"
        fill="white"
      />
      <path
        d="M94.567 2.36621H99.0373C99.4756 2.36621 99.8457 2.52396 100.148 2.82959C100.449 3.13523 100.605 3.50987 100.605 3.95353V8.3803C100.605 8.8141 100.449 9.18875 100.148 9.50424C99.8457 9.80987 99.4756 9.96762 99.0373 9.96762H94.567C94.1385 9.96762 93.7684 9.80987 93.4665 9.50424C93.1646 9.19861 93.0088 8.82396 93.0088 8.3803V3.95353C93.0088 3.51973 93.1646 3.14508 93.4665 2.82959C93.7684 2.52396 94.1385 2.36621 94.567 2.36621ZM99.0373 3.45072H94.5865C94.4502 3.45072 94.3333 3.50001 94.2359 3.59861C94.1385 3.6972 94.0898 3.81551 94.0898 3.95353V8.36058C94.0898 8.49861 94.1385 8.61692 94.2359 8.71551C94.3333 8.8141 94.4502 8.86339 94.5865 8.86339H99.0373C99.1737 8.86339 99.2905 8.8141 99.3879 8.71551C99.4853 8.61692 99.534 8.49861 99.534 8.36058V3.96339C99.534 3.82537 99.4853 3.70706 99.3879 3.60846C99.2905 3.50001 99.1737 3.45072 99.0373 3.45072Z"
        fill="white"
      />
      <path
        d="M22.3026 14C23.8803 14 25.2341 14.5718 26.3638 15.7056C27.4838 16.8394 28.0487 18.2 28.0487 19.7972V36.1732C28.0487 37.7803 27.4838 39.1507 26.3638 40.2944C25.2438 41.4282 23.8901 42 22.3026 42H0V0H3.89565V14H22.3026ZM24.153 36.193V20.0042C24.153 19.4915 23.968 19.0577 23.6077 18.6831C23.2473 18.3183 22.809 18.131 22.3026 18.131H5.79478C5.28835 18.131 4.85009 18.3183 4.47026 18.6831C4.09044 19.0479 3.90539 19.4915 3.90539 20.0042V36.1831C3.90539 36.6958 4.09044 37.1296 4.47026 37.5042C4.85009 37.869 5.28835 38.0563 5.79478 38.0563H22.3026C22.809 38.0563 23.2473 37.869 23.6077 37.5042C23.968 37.1394 24.153 36.6958 24.153 36.193Z"
        fill="white"
      />
      <path
        d="M56.1465 14C57.7632 14 59.1267 14.5718 60.2564 15.7155C61.3764 16.8592 61.9413 18.2296 61.9413 19.8465V42H39.6387C38.0609 42 36.7072 41.4282 35.5774 40.2845C34.4574 39.1408 33.8926 37.7704 33.8926 36.1634V26.0282H58.0456V19.8268C58.0456 19.3141 57.8606 18.8704 57.5002 18.4958C57.1399 18.1211 56.7016 17.9437 56.1952 17.9437H33.8926V14H56.1465ZM58.0456 38.0563V29.9718H37.7882V36.1634C37.7882 36.6761 37.9733 37.1197 38.3336 37.4944C38.694 37.869 39.1322 38.0465 39.6387 38.0465H58.0456V38.0563Z"
        fill="white"
      />
      <path
        d="M73.5303 14H89.0155V17.9437H73.5303C73.0238 17.9437 72.5856 18.131 72.2252 18.4958C71.8649 18.8606 71.6798 19.3042 71.6798 19.8169V42H67.7842V19.8465C67.7842 18.2394 68.349 16.8592 69.4691 15.7155C70.5988 14.5718 71.9525 14 73.5303 14Z"
        fill="white"
      />
      <path d="M98.7551 14H94.8594V42H98.7551V14Z" fill="white" />
      <path
        d="M110.832 41.9998H105.767C105.125 41.9998 104.599 41.4674 104.599 40.8167V35.6899C104.599 35.0392 105.125 34.5068 105.767 34.5068H110.832C111.474 34.5068 112 35.0392 112 35.6899V40.8167C112 41.4674 111.474 41.9998 110.832 41.9998Z"
        fill="#0090FF"
      />
    </svg>
  );
};
