import React from 'react';

// COMPONENTS
import { Button } from '../../../UI/Button';

// STYLES
import { ActionButtons, ModalContainer, ModalContent, ModalTitle, ModalSubtitle } from '../styles';

// SVGS
import { ErrorProposal } from '../svgs';

interface IModalErrorProposal {
  onClose: any;
}

const ModalErrorProposal = ({ onClose }: IModalErrorProposal) => {
  return (
    <ModalContent>
      <ModalContainer>
        <ErrorProposal />
        <ModalTitle>Erro ao cadastrar proposta</ModalTitle>
      </ModalContainer>
      <ModalSubtitle>
        Os dados informados estão diferentes dos que constam no Sicred. Confira e tente novamente.
      </ModalSubtitle>
      <ActionButtons className="left">
        <Button onClick={onClose} big black textLeft>
          Conferir dados
        </Button>
      </ActionButtons>
    </ModalContent>
  );
};

export default ModalErrorProposal;
