import React from 'react';
import { Container, Content, Search } from './styles';
import { Row, Col } from '../../GRID';
import { Profile } from '../Profile';
import { useAuth } from '../../../hooks/auth';
import { useLocation } from 'react-router-dom';

interface ComponentProps {
  proposal?: boolean;
}

const Header: React.FC<ComponentProps> = ({ ...props }: ComponentProps) => {
  const { user } = useAuth();
  const location = useLocation();
  let proposal = false;

  if (location.pathname === '/proposal') {
    proposal = true;
  }

  return (
    <Container {...props} proposal={proposal}>
      <Search className="searchProposal"></Search>
      <Row className="row">
        <Col sm={18} md={18} lg={18}>
          <Content>
            <Profile username={user.name} />
          </Content>
        </Col>
      </Row>
    </Container>
  );
};

export default Header;
