// @ts-nocheck
import React from 'react';
import {
  Home,
  Terms,
  ProposaList,
  CustomTerms,
  SelfieTips,
  Selfie,
  SucessSelfie,
  SignSignature,
  Finish,
} from './index';

interface IStepDisplay {
  formValues: Signature;
  currentStep: number;
}

const StepDisplay = ({ currentStep, formValues }: IStepDisplay) => {
  switch (currentStep) {
    case 1:
      return <Home />;
    case 2:
      return <Terms formValues={formValues} />;
    case 3:
      return <ProposaList formValues={formValues} />;
    case 4:
      return <CustomTerms formValues={formValues} />;
    case 5:
      return <SelfieTips />;
    case 6:
      return <Selfie formValues={formValues} />;
    case 7:
      return <SucessSelfie />;
    case 8:
      return <SignSignature formValues={formValues} />;
    case 9:
      return <Finish />;
    default:
      return null;
  }
};

export default StepDisplay;
