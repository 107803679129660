import styled from 'styled-components';

/**
 * @author Matheus Vieira <matheus.vieira@deal.com.br>
 * @since 2020-11-19
 */
export const Container = styled.div`
  padding: 16px 34px;
  justify-content: flex-end;
  align-items: center;
  background: #fff;
  border: 1px solid #e5e5e5;
  border-top: none;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
`;

export const Button = styled.button`
  cursor: pointer;
  outline: none;
  padding: 12px 16px;
  background: none;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 8px;
  &:disabled {
    cursor: not-allowed;
    border-color: #e5e5e5;
    > svg > path {
      fill: #e5e5e5;
    }
  }
`;

export const Pages = styled.div`
  margin: 0 24px;
  align-items: center;
`;

export const CurrentPage = styled.input`
  outline: 0;
  padding: 7px 0;
  background: #ffffff;
  text-align: center;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 8px;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  display: block;
  width: 56px;
  &:disabled {
    cursor: not-allowed;
    border-bottom: 1px solid #e5e5e5 !important;
    background-color: #f6f6f6;
    color: #8e8e8e;
  }
`;

export const TotalPages = styled.label`
  margin-left: 24px;
  font-family: 'Clan Web Pro Medium';
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #8e8e8e;
`;
