import styled from 'styled-components';

// ENUMS
import { Themes } from '../../../enums/themes';

export const Dot = styled.div`
  margin-right: 6px;
  width: 6px;
  height: 6px;
  border-radius: 5px;
  background-color: #e5e5e5;
  &.PREPROPOSAL {
    background-color: #4aaef2;
  }
  &.PROPOSAL {
    background-color: #8dd942;
  }
  &.WAIVER {
    background-color: #d259e4;
  }
  &.PENDING_DATA {
    background-color: #ff923d;
  }
  &.SIGNATURE_NOT_SENT {
    background-color: #ff923d;
  }
  &.NOTSENT,
  &.PENDING {
    background-color: #f83d44;
  }
  &.SIGNATURE_WAITING {
    background-color: #4aaef2;
  }
  &.SIGNATURE_EXPIRED {
    background-color: #d259e4;
  }
  &.PENDING_DOCS {
    background-color: #ff923d;
  }
  &.WAITING,
  &.NONE {
    background-color: #4aaef2;
  }
  &.IN_ANALYSIS,
  &.CONTRACT_ISSUE_SIGNATURE {
    background-color: #8dd942;
  }
  &.PARTNER_PENDING,
  &.JURIDICAL_VALUATION_PENDING {
    background-color: #ff923d;
  }
  &.DENIED,
  &.PREDENIED {
    background-color: #d259e4;
  }
  &.PENDING_CREDIT {
    background-color: #ff923d;
  }
  &.APPROVED,
  &.PREAPPROVED,
  &.ASSIGNED,
  &.SIGNEDREGISTRATIONFORM {
    background-color: #8dd942;
  }
  &.PENDING_REPORT {
    background-color: #ff923d;
  }
  &.LEGAL_PENDING {
    background-color: #ff923d;
  }
  &.FORMALIZATION {
    background-color: #8dd942;
  }
  &.WAITING_FOR_ORIGINALS {
    background-color: #8dd942;
  }
`;

export const Container = styled.label<{ hasKebab?: boolean; color?: Themes }>`
  display: inline-flex;
  align-items: center;
  padding: 0px 11px;
  border-radius: 41px;
  border: 1px solid #e5e5e5;
  color: #555555;
  font-family: 'Clan Web Pro Medium';
  font-size: 10px;
  line-height: 24px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  white-space: nowrap;

  ${({ color }) =>
    color == Themes.WARNING &&
    `
    color: #D66700;
    border-color: #FFD8AC;
    ${Dot} {
      background: #FF923D;
    }
  `}

  ${({ color }) =>
    color == Themes.PRIMARY &&
    `
    color: #0073C8;
    border-color: #A0D8FD;
    ${Dot} {
      background: #4AAEF2;
    }
  `}
  
  ${({ color }) =>
    color == Themes.ALERT &&
    `
      color: #D02931;
      border-color: #FFC9CA;
      ${Dot} {
        background: #FF8789;
      }
    `}
    
  ${({ color }) =>
    color == Themes.SECONDARY &&
    `
    color: #569522;
    border-color: #D6F6A7;
    ${Dot} {
      background: #A3E45A;
    }
  `}

  ${({ color }) =>
    color == Themes.INFO &&
    `
    color: #6139C0;
    border-color: #D1BEFC;
    ${Dot} {
      background: #9972F6;
    }
  `}

  &.PREPROPOSAL {
    color: #0073c8;
    background: #e6f5fe;
    border: 1px solid #a0d8fd;
  }
  &.PROPOSAL {
    color: #569522;
    background: #f3fce4;
    border: 1px solid #d6f6a7;
  }
  &.WAIVER {
    color: #8a2a99;
    background: #f9f0fc;
    border: 1px solid #f1bdf8;
  }
  &.NOTSENT,
  &.PENDING_DATA {
    color: #d66700;
    border: 1px solid #ffa958;
  }
  &.SIGNATURE_NOT_SENT {
    color: #d66700;
    border: 1px solid #ffa958;
  }
  &.PENDING {
    color: #d02931;
    border: 1px solid #ffc9ca;
  }
  &.SIGNATURE_WAITING,
  &.WAITING,
  &.NONE {
    color: #0073c8 !important;
    border: 1px solid #a0d8fd;
  }
  &.SIGNATURE_EXPIRED {
    color: #8a2a99;
    border: 1px solid #f1bdf8;
  }
  &.PENDING_DOCS {
    color: #d66700;
    border: 1px solid #ffa958;
  }
  &.WAITING,
  &.NONE {
    color: #0073c8;
    border: 1px solid #a0d8fd;
  }
  &.IN_ANALYSIS,
  &.CONTRACT_ISSUE_SIGNATURE {
    color: #569522;
    border: 1px solid #d6f6a7;
  }
  &.PARTNER_PENDING,
  &.JURIDICAL_VALUATION_PENDING {
    color: #d66700;
    border: 1px solid #ffa958;
  }
  &.DENIED,
  &.PREDENIED,
  &.REFUSED {
    color: #8a2a99;
    border: 1px solid #f1bdf8;
  }
  &.PENDING_CREDIT {
    color: #d66700;
    border: 1px solid #ffa958;
  }
  &.APPROVED,
  &.PREAPPROVED,
  &.ASSIGNED,
  &.SIGNEDREGISTRATIONFORM {
    color: #569522;
    border: 1px solid #d6f6a7;
  }
  &.PENDING_REPORT {
    color: #d66700;
    border: 1px solid #ffa958;
  }
  &.LEGAL_PENDING {
    color: #d66700;
    border: 1px solid #ffa958;
  }
  &.FORMALIZATION {
    color: #569522;
    border: 1px solid #d6f6a7;
  }
  &.WAITING_FOR_ORIGINALS {
    color: #569522;
    border: 1px solid #d6f6a7;
  }
  &.ENDORSED {
    color: #569522;
    border: 1px solid #d6f6a7;
  }
  &.NOT_BADGE {
    width: 44px;
    height: 26px;
    position: relative;
    background: #f6f6f6;
    border: 1px solid #e5e5e5;
    top: 2px;
    > .Dots {
      position: absolute;
      left: 0;
      right: 0;
      top: -4px;
      font-size: 16px;
      justify-content: center;
    }
  }
  &.TableBadge {
    span {
      max-width: 20ch;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      display: block;
    }
  }
  ${({ hasKebab }) =>
    hasKebab &&
    `
    right: 0 !important
  `}
`;
