import styled from 'styled-components';

export const StyledCard = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  background-color: white;
  color: black;
  padding: 24px;
  font-family: 'Nunito', sans-serif;
  border: 1px solid #e5e5e5;
  padding: 40px;
  flex-grow: 1;
  margin: ${(props: any) =>
    !!props.simulationResume ? '7px 0px 7px 0px' : props.hasMarginRight ? '7px 15px 7px 0px' : '7px 0px 7px 0px'};

  & + div {
    margin: 7px 7px 7px 0;
  }
  /* .resume {
    padding: 32px 40px;
  } */

  @media (max-width: 1800px) {
    margin: 7px 0px 7px 0;
  }

  @media only screen and (max-width: 1279px) {
    width: 70%;
    .costs {
      margin-right: 50px !important;
    }
  }
`;
export const StyledTitleCard = styled.h2`
  font-size: 20px;
  color: #101010;
  margin: 0;
`;

export const StyledDescription = styled.p`
  font-size: 14px;
  color: #101010;
  margin-top: 4px;
`;
