import styled from 'styled-components';
import media from 'styled-media-query';

interface RowProps {
  centralized?: boolean;
}

const Row = styled.div<RowProps>`
  width: 100%;
  height: auto;
  float: left;
  display: block;
  box-sizing: border-box;
  ${media.lessThan<RowProps>('medium')`
    ${({ centralized }) =>
      centralized &&
      `
        text-align: center;
    `};
  `};
  ${media.greaterThan<RowProps>('medium')`
    ${({ centralized }) =>
      centralized &&
      `
        display: flex;
        align-items: center;
    `};
  `}
  &:before,
  &:after {
    content: '';
    display: table;
  }
  &:after {
    clear: both;
  }
`;

export default Row;
