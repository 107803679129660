import styled from 'styled-components';

/**
 * @author Matheus Vieira <matheus.vieira@deal.com.br>
 * @since 2021-01-26
 */
export const ContainerFilter = styled.div`
  display: inline-flex;
  .FilterWrapper {
    display: flex;
    max-height: 40px;
    padding: 7px 8px;
    align-items: center;
    border-radius: 8px;
    box-sizing: border-box;
    &.Active {
      padding: 7px 7px;
      color: #0090ff;
      background: #ffffff;
      border: 1px solid #0090ff;
    }
    &.HasFilter {
      color: #0090ff;
    }
  }
`;

export const Counter = styled.span`
  margin-left: 4px;
  display: flex;
  align-items: center;
  font-size: 10px;
  padding: 4px 12px 4px 8px;
  color: #fff;
  margin-left: 4px;
  background: #0090ff;
  border: 2px solid #ffffff;
  border-radius: 30px;
  > svg {
    margin-left: 4px;
  }
`;

export const CounterEmpty = styled.span`
  background: none !important;
  margin-left: 8px;
  margin-bottom: 2px;
  padding: 5.5px 0px;
  display: block;
`;
