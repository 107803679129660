import styled, { css } from 'styled-components';
import orderIcon from '../../../static/icons/order-icon.svg';
import orderArrows from '../../../static/icons/order-arrows.svg';
import arrowUp from '../../../static/icons/arrow-up.svg';
import arrowDown from '../../../static/icons/arrow-down.svg';

export const Content = styled.div`
  display: block;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin-bottom: 40px;
  position: relative;
`;

export const Header = styled.div`
  padding: 16px 32px;
  background-color: #ffffff;
  border: 1px solid #e5e5e5;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
`;

export const TableWrapper = styled.div`
  position: relative;
  border-right: 1px solid #e5e5e5;
`;
export const TableOverflow = styled.div<{ forceResponsive?: boolean }>`
  position: relative;
  width: 100%;
  ${({ forceResponsive }) =>
    forceResponsive
      ? css`
          overflow-x: scroll;
        `
      : css`
          @media only screen and (max-width: 1365px) {
            overflow-x: scroll;
          }
        `}
`;

const ResponsiveOverlay = css`
  display: block;
  min-width: 487px;
  max-width: 100%;
  position: sticky;
  left: 0;
  top: 0;
  bottom: 0;
  background: #fff;
  z-index: 9;
  border-right: 1px solid #e5e5e5;
  border-left: 1px solid #e5e5e5;
  tbody,
  thead {
    tr {
      th,
      td {
        &:last-child {
          width: 2%;
          padding-right: 6px;
          padding-left: 0px;
        }
      }
      th {
        &:first-child {
          padding-left: 20px;
        }
        &:nth-child(2) {
          width: 12%;
          padding-left: 8px;
        }
        &:nth-child(3) {
          width: 6%;
        }
        &:last-child {
          padding-right: 32px;
        }
      }
    }
  }
`;

export const TableOverlay = styled.table<{ forceResponsive?: boolean }>`
  display: none;

  ${({ forceResponsive }) =>
    forceResponsive
      ? ResponsiveOverlay
      : css`
          @media only screen and (max-width: 1365px) {
            ${ResponsiveOverlay}
          }
        `}
`;
const ResponsiveWrapper = css`
  display: table;
  /* width: 890px; */
  thead tr {
    height: 41px;
  }
  thead,
  tbody {
    tr {
      th:before {
        display: none;
      }
      th,
      td {
        &.Name {
          padding-left: 20px;
          > div {
            display: flex;
            min-height: 32px;
            align-items: center;
          }
        }
        &.DocumentNumber {
          padding-left: 20px;
        }
        &.Actions {
          display: none;
        }
        &.actions-quit {
          display: none;
        }
      }
    }
  }
`;

export const Wrapper = styled.table<{ forceResponsive?: boolean }>`
  display: table;
  width: 100%;
  position: relative;
  border: 1px solid #e5e5e5;
  border-top: none;
  border-bottom: none;
  font-size: 11px;
  color: #8e8e8e;
  background: #fff;
  flex: 1;
  border-spacing: 0;
  font-family: 'Clan Web Pro Medium';

  ${({ forceResponsive }) =>
    forceResponsive
      ? ResponsiveWrapper
      : css`
          @media only screen and (max-width: 1365px) {
            ${ResponsiveWrapper}
          }
        `}
`;

export const Actions = styled.div`
  align-items: center;
  justify-content: flex-end;
`;

export const Button = styled.button`
  outline: 0;
  padding: 12px 16px;
  background: #ffffff;
  border: 1px solid #c2c2c2;
  box-sizing: border-box;
  cursor: pointer;
  &:disabled {
    cursor: not-allowed;
    border-color: #e5e5e5;
    > svg > path {
      fill: #e5e5e5;
    }
  }
  &.Left {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    border-right: none;
  }
  &.Right {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
`;

export const Divider = styled.div`
  margin: 0 24px;
  width: 1px;
  height: 24px;
  background-color: #e5e5e5;
`;

export const Thead = styled.thead`
  display: table-header-group;
`;

export const Tbody = styled.tbody``;

export const Tr = styled.tr`
  cursor: pointer;
  &:hover {
    background: #fafafa;
  }
  &.Header {
    background: #fafafa;
    border-bottom: 1px solid #e5e5e5;
  }
  &:not(.Header) {
    position: relative;
  }
`;

export const Th = styled.th`
  width: 20%;
  position: relative;
  display: table-cell;
  border-bottom: 1px solid #e5e5e5;
  font-family: 'Clan Web Pro Medium';
  font-weight: normal;
  font-size: 11px;
  text-align: left;
  color: #646464;
  padding-bottom: 16px;
  padding-top: 16px;
  > label {
    cursor: pointer;
    position: relative;
    &:after {
      display: block;
      position: absolute;
      right: -16px;
      top: 0;
    }
  }
  &.Filter {
    label {
      cursor: pointer;
    }
    > div {
      display: flex;
      align-items: center;
      position: relative;
    }
  }
  &.SignatureStatus,
  &.ProductType {
    > label {
      &:after {
        content: url(${orderIcon});
      }
    }
  }
  &.Name,
  &.ProposalNumber,
  &.CreationDate {
    &:before {
      content: '';
      position: absolute;
      right: 0;
      top: 0;
      left: 0;
      bottom: 0;
      width: 100%;
      transition: background 0.65s;
    }
    > label {
      &:after {
        content: url('${orderArrows}');
      }
    }
    &.Desc {
      label:after {
        top: -2px;
        content: url(${arrowUp});
      }
    }
    &.Asc {
      label:after {
        top: -2px;
        content: url(${arrowDown});
      }
    }
    &.Asc,
    &.Desc {
      &:before {
        background: #f6f6f6;
      }
      label {
        color: #0090ff;
      }
    }
  }

  @media only screen and (min-width: 1365px) {
    &:first-child {
      width: 16%;
      padding-left: 24px;
      padding-bottom: 16px;
      padding-top: 16px;
    }
    &:nth-child(2) {
      width: 12%;
    }
    &:nth-child(3) {
      width: 15%;
    }
    &:last-child {
      padding-right: 32px;
    }
  }
`;

export const Td = styled.td`
  padding: 13px 0;
  border-bottom: 1px solid #e5e5e5;
  font-family: 'Clan Web Pro News';
  font-size: 13px;
  line-height: 16px;
  font-weight: 500;
  color: #555555;
  text-transform: capitalize;
  height: 53px;
  &:first-child {
    padding-left: 20px;
    white-space: nowrap;
  }
  &:nth-child(2) {
    padding-left: 8px;
  }
  &:last-child {
    padding-left: 32px;
    padding-right: 32px;
    text-align: left;
  }
  .Name {
    max-width: 16ch;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
  }
`;
export const ContentKebab = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
`;

// ---- Kebab
export const ItemKebab = styled.ul`
  padding: 18px 26px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 13px;
  line-height: 24px;
  color: #555555;
  font-family: 'Clan Web Pro News';
  text-transform: none;

  &:hover {
    background: rgba(246, 246, 246, 0.6);
    cursor: pointer;
    color: #101010;
  }
  &.copy {
    color: #101010;
    border-top: 1px solid #f6f6f6;
    &:hover {
      color: #101010;
    }
  }
  &.disabled {
    cursor: not-allowed;
    color: #e5e5e5 !important;
    svg {
      g {
        &:first-child {
          stroke: #e5e5e5;
        }
        &:last-child {
          fill: #e5e5e5;
        }
      }
    }
  }
`;
export const SelectWrapper = styled.label`
  padding: 0 16px;
  display: flex;
  align-items: center;
  width: 136px;
  height: 40px;
  max-width: 100%;
  position: relative;
  border: 1px solid #e5e5e5;
  border-radius: 8px;
  #pageSize {
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
  }
  > span {
    font-size: 14px;
    line-height: 24px;
    color: #8e8e8e;
  }
  .react-select__control {
    padding: 0;
    height: 40px;
    border: none;
    background: none;
    box-sizing: border-box;
    padding-left: 80px;
    padding-right: 16px;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    &:focus,
    &:hover,
    &.react-select__control--is-focused {
      border: none;
      box-shadow: none;
    }
  }
  .react-select__single-value {
    color: #101010;
    font-size: 14px;
    line-height: 24px;
  }
  .react-select__indicator {
    padding: 0;
    color: #101010 !important;
  }
  .react-select__menu {
    position: absolute;
    top: 40px;
    left: 0;
    right: 0;
  }
  .react-select__value-container {
    padding: 0px !important;
  }
`;
export const Url = styled.a`
  max-width: 21ch;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
`;
export const GenerateLink = styled.button`
  background: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border: none;
`;

export const SolvePencence = styled.button`
  background: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border: none;

  &.disabled {
    cursor: not-allowed;
    color: #e5e5e5 !important;
    svg {
      g {
        &:first-child {
          stroke: #e5e5e5;
        }
        &:last-child {
          fill: #e5e5e5;
        }
      }
    }
  }
`;
