import styled from 'styled-components';

interface SideMenuProps {
  active?: boolean;
}

export const LogoSection = styled.div`
  padding: 0 16px 24px;
  margin-bottom: 48px;
  .small {
    display: none;
  }
  @media (max-width: 1360px) {
    padding-bottom: 0;
    .big {
      display: none;
    }
    .small {
      display: block;
      margin-top: 38px;
    }
  }
`;

export const SideMenuContainer = styled.div`
  flex-direction: column;
  position: relative;
  flex: 1;
  width: 315px;
  box-sizing: border-box;
  min-width: 300px;
  max-width: 300px;
  background: #101010;
  padding: 40px 20px 16px 24px;
  justify-content: space-between;
  max-height: 100vh;
  @media (max-width: 1360px) {
    width: 100px;
    min-width: 100px;
    max-width: 100px;
    padding: 0;
    align-items: center;
    > div {
      align-items: center;
    }
  }
`;

export const ModalTestes = styled.div`
  position: relative;
  background: #1a1a1a;
  border: 1px solid #282828;
  box-sizing: border-box;
  box-shadow: 0px 20px 20px -10px rgba(0, 0, 0, 0.06);
  border-radius: 16px;
  flex-direction: column;
  padding: 32px;
  line-height: 24px;
  margin-top: 32px;
  @media (max-width: 1360px) {
    display: none;
  }
  .closeModal {
    box-sizing: border-box;
    position: absolute;
    right: -13px;
    top: -22px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-weight: 700;
    font-family: 'Clan Web Pro Medium';
    svg {
      position: relative;
      top: 8px;
    }
  }
`;

export const ModalTitle = styled.h2`
  font-size: 12.6px;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: #ffffff;
  margin: 0;
  font-family: 'Clan Web Pro Medium';
  margin-top: 8px;
`;

export const ModalDescription = styled.p`
  font-size: 12px;
  margin: 8px 0px 0px;
  color: #c2c2c2;
  font-family: 'Clan Web Pro News';

  a {
    color: #0090ff;
    outline: none;
    text-decoration: none;
    font-family: 'Clan Web Pro Medium';
  }
`;

export const SideMenuItemContainer = styled.a<SideMenuProps>`
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  font-size: 12.6px;
  line-height: 48px;
  border-radius: 8px;
  margin-bottom: 8px;
  color: #646464;
  text-decoration: none;
  font-family: 'Clan Web Pro Medium';
  letter-spacing: 0.02em;
  cursor: pointer;
  :hover {
    background: rgb(255, 255, 255, 0.1);
    color: #ffffff;
    svg .fill {
      fill: #ffffff !important;
    }
    svg .stroke {
      stroke: #ffffff !important;
      stroke-width: 2;
    }
  }
  @media (max-width: 1360px) {
    justify-content: center;
    width: 60px;
    height: 60px;
    margin-top: 20px;
    & .checked {
      display: none !important;
    }
  }

  ${({ active }) =>
    active &&
    `
    background: #FFF;
    color: #101010; 
    font-family: 'Clan Web Pro Bold';

    :hover {
      background: #FFF;
      color: #101010;
      svg .fill {
        fill: #FFF!important;
      }
      svg .stroke {
        stroke: #101010!important;
      }
      & .dashboard .fill {
        fill: #101010!important;
      }
         
    }

    & svg {
      fill: #101010!important;
    }

    & .dashboard .fill {
      fill: #101010!important;
    }
    & svg .fill {
        fill: #FFF!important;
      }
    & svg .stroke {
        stroke: #101010!important;
        stroke-width: 2;
      }
    & .checked {
      display: initial;
    }
  `};
`;

export const Title = styled.div`
  font-size: 12.6px;
  flex: 1;
  @media (max-width: 1360px) {
    display: none;
  }
`;

export const Icon = styled.div`
  width: 24px;
  height: 24px;
  align-items: center;
  justify-content: center;
  margin-right: 27px;

  @media (max-width: 1360px) {
    margin-right: 0;
  }

  svg .fill {
    fill: #646464;
  }
  & svg .stroke {
    stroke: #646464;
    stroke-width: 2;
  }
`;

export const Dot = styled.div`
  position: absolute;
  width: 6px;
  height: 6px;
  background: #101010;
  border-radius: 50%;
  display: none;
  right: 16px;
  @media (max-width: 1360px) {
    display: none;
  }
`;

export const Logout = styled.div`
  flex-direction: column;
  color: #646464;
  cursor: pointer;
  align-items: center;
  margin-top: 61px;
  padding: 8px;
  border-radius: 8px;
  span {
    line-height: 48px;
    font-size: 12.6px;
    font-family: 'Clan Web Pro Medium';
  }
  :hover {
    color: #fff;
    svg .fill {
      fill: #fff !important;
    }
  }
  @media (max-width: 1360px) {
    padding: 15px 25px;
    margin-top: 0;
  }
`;
