import React, { useEffect, useState, ReactElement } from 'react';

// Service
import { useSignature } from '../../../../hooks/SignatureContext';

import { ContainerSucess, CotentSucess, TitleSucess } from '../styles';

export const IconSucess = (): ReactElement => {
  return (
    <svg width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M35 65C51.5685 65 65 51.5685 65 35C65 18.4315 51.5685 5 35 5C18.4315 5 5 18.4315 5 35C5 51.5685 18.4315 65 35 65Z"
        fill="white"
      />
      <path
        d="M51.6977 26.3341C51.4662 26.1006 51.1908 25.9154 50.8873 25.7889C50.5839 25.6625 50.2584 25.5974 49.9297 25.5974C49.6009 25.5974 49.2755 25.6625 48.972 25.7889C48.6686 25.9154 48.3931 26.1006 48.1617 26.3341L29.6077 44.9121L21.8077 37.0921C21.5704 36.8452 21.2859 36.6488 20.971 36.5145C20.6561 36.3803 20.3173 36.3109 19.975 36.3106C19.6326 36.3103 19.2938 36.3791 18.9786 36.5128C18.6635 36.6465 18.3786 36.8424 18.1409 37.0888C17.9033 37.3352 17.7178 37.627 17.5955 37.9468C17.4733 38.2666 17.4168 38.6077 17.4295 38.9498C17.4422 39.2919 17.5237 39.6279 17.6693 39.9378C17.8148 40.2476 18.0214 40.5249 18.2767 40.753L27.8397 50.316C28.0711 50.5495 28.3466 50.7347 28.65 50.8612C28.9534 50.9876 29.2789 51.0527 29.6077 51.0527C29.9364 51.0527 30.2619 50.9876 30.5653 50.8612C30.8688 50.7347 31.1442 50.5495 31.3757 50.316L51.6977 29.9951C51.9505 29.7619 52.1523 29.4788 52.2904 29.1638C52.4284 28.8487 52.4997 28.5085 52.4997 28.1646C52.4997 27.8206 52.4284 27.4804 52.2904 27.1653C52.1523 26.8503 51.9505 26.5672 51.6977 26.3341Z"
        fill="black"
      />
    </svg>
  );
};

export const SucessSelfie: React.FC = () => {
  const { formOpened, setStep } = useSignature();

  useEffect(() => {
    setTimeout(
      function () {
        setStep((previousStep: any) => previousStep + 1);
      }.bind(this),
      3000,
    );
  }, []);

  return (
    <ContainerSucess>
      <CotentSucess>
        <IconSucess />
        <TitleSucess>Selfie Enviada</TitleSucess>
      </CotentSucess>
    </ContainerSucess>
  );
};
