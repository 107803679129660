// @ts-nocheck
import React, { useRef, useState, useCallback } from 'react';
import Webcam from 'react-webcam';

// Components
import { Button } from '../../../components/UISignatue/Button';
import {
  ContainerButton,
  HeaderCam,
  Image,
  TitleCam,
  TitleButton,
  ActionCaptureSelfie,
  Mask,
  FooterCam,
} from './styles';

// Context
import { useSignature } from '../../../hooks/SignatureContext';
// Svgs
import { ArrowRight, ArrowLeft, ActionSelfie } from './svgs';

// Service
import SignatureService from '../../../services/SignatureService';

const videoConstraints = {
  height: { max: 360 },
  ConnectingMode: 'user',
};

const Camera: React.FC = ({ formValues }: any) => {
  const { setStep, formOpened, setFormOpened, setShowModal, setErrorCam } = useSignature();
  const webcamRef = useRef(null);
  const [imgBase64, setImgBase64] = useState(null || '');
  const [loading, setLoading] = useState(false);

  const capturePhoto = useCallback(() => {
    // navigator.mediaDevices
    //   .getUserMedia({ video: true })
    //   .then(function (stream) {
    //     console.log(stream, 'stream')
    //     logger.error("Error capturing photo")
    //     if (stream.active) {
    const imageSrc = webcamRef.current.getScreenshot();
    setImgBase64(imageSrc);
    //   } else {
    //     setErrorCam(true)
    //   }
    // })
    // .catch(function (err) {
    //   console.log(err.name + ': ' + err.message)
    //   logger.error("Error setting image to base64")
    //   setErrorCam(true)
    // })
  }, [webcamRef]);

  const handlePrevstep = () => {
    setStep((previousStep: any) => previousStep - 1);
    setShowModal(false);
  };

  const sendPhoto = async () => {
    setLoading(true);
    let sliptBase64 = imgBase64.split('base64,');
    try {
      const sendPhoto = await SignatureService.sendSelfie({ token: formOpened.token, base64: sliptBase64[1] });

      const { status, data } = sendPhoto;

      formValues.selfieId = data.Id;
      setFormOpened(formValues);
      setLoading(false);
      setStep((previousStep: any) => previousStep + 1);
    } catch (err) {
      setLoading(false);
      logger.error('Error sending photo');
    }
  };

  return (
    <>
      {imgBase64 ? (
        <>
          <HeaderCam>
            <button onClick={handlePrevstep}>
              <ArrowLeft />
            </button>

            <TitleCam>Selfie</TitleCam>
          </HeaderCam>
          <Image>
            <img src={imgBase64 || ''} alt="Screenshot" />
          </Image>
          <ContainerButton>
            <TitleButton>A foto ficou boa?</TitleButton>
            <Button black big spaced onClick={sendPhoto} iconRight={ArrowRight} {...{ disabled: '' || loading }}>
              {loading ? 'Enviando...' : 'Enviar foto'}
            </Button>
            <Button transparent big spaced onClick={() => setImgBase64(null)}>
              Tirar uma nova foto
            </Button>
          </ContainerButton>
        </>
      ) : (
        <>
          <Mask>
            <Webcam
              ref={webcamRef}
              audio={false}
              screenshotFormat="image/jpeg"
              videoConstraints={videoConstraints}
              screenshotQuality={1}
              onUserMediaError={() => {
                setErrorCam(true);
              }}
            />
            <ActionCaptureSelfie>
              <button className="action-selfie" onClick={capturePhoto}>
                <ActionSelfie />
              </button>
            </ActionCaptureSelfie>
          </Mask>
          <FooterCam>
            <p>Enquadre seu rosto na moldura e fotografe</p>
          </FooterCam>
        </>
      )}
    </>
  );
};

export default Camera;
