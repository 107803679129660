// @ts-nocheck
import axios from 'axios';
import api from '../axios';
import { PREFIX, VERSION } from '../enums/request';
import { Type } from '../enums/loan-type.enum';

const CancelToken = axios.CancelToken;

export default class ProposalResource {
  static fetchAll(params?: any) {
    return api.get(`/${PREFIX.PARTNERS}/${VERSION.V2}/payrollloanproposals${params}`);
  }

  static createProposal(data: any) {
    return api
      .put(`/${PREFIX.PARTNERS}/${VERSION.V2}/payrollloanproposals`, data)
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        return err;
      });
  }

  static getProposal(proposal: any) {
    return api
      .get(`/${PREFIX.PARTNERS}/${VERSION.V2}/payrollloanproposals/${proposal}`)
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        return err;
      });
  }

  static postDocuments(id: string, documentType: string, fileType: string, name: string) {
    return api.post(`/${PREFIX.PARTNERS}/${VERSION.V2}/payrollloanproposals/${id}/documents`, {
      documentType: documentType,
      contentType: fileType,
      name: name,
    });
  }

  static uploadAWSService(url: string, file: any, cancelFileUpload?: any) {
    const cancelToken = new CancelToken((cancel: any) => {
      if (cancelFileUpload) {
        cancelFileUpload.current = {
          ...cancelFileUpload.current,
          current: cancel,
        };
        cancelFileUpload.current.setReference(cancelFileUpload);
      }
    });

    const options = {
      headers: {
        'Content-Type': file.type,
      },
      onUploadProgress: (progressEvent: any) => {
        const { loaded, total } = progressEvent;
        const percent = Math.floor((loaded * 100) / total);

        if (percent < 100 && cancelFileUpload) {
          cancelFileUpload.current.setLoading(percent);
        }
      },
      cancelToken,
    };

    return api.put(url, file, options);
  }

  static deleteDocuments(id: string, documentId: any) {
    return api
      .delete(`/${PREFIX.PARTNERS}/${VERSION.V2}/payrollloanproposals/${id}/documents/${documentId}`)
      .then((resp) => resp)
      .catch((err) => err);
  }

  static viewDocuments(id: string, Id: string) {
    return api
      .get(`/${PREFIX.PARTNERS}/${VERSION.V2}/payrollloanproposals/${id}/documents/${Id}`)
      .then((res) => res)
      .catch((err) => err);
  }

  static fetchLinkSignature(documentNumber: string, type: string) {
    return api.put(`/${PREFIX.PARTNERS}/${VERSION.V2}/payrollloanproposals/links`, {
      documentNumber: documentNumber,
      type: type,
    });
  }

  // All agreements (except FGTS)
  static fetCnpjconsignees() {
    return api.get(`/${PREFIX.PARTNERS}/${VERSION.V2}/payrollloanproposals/cnpjconsignees`);
  }

  // FGTS Agreement

  static fetCnpjconsigneesFgts() {
    return api.get(`/${PREFIX.PARTNERS}/${VERSION.V2}/payrollloanproposals/cnpjconsignees?category=${Type.FGTS}`);
  }
}
