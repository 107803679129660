// Interfaces
import { IFile } from '../interfaces/file.interface';
import RegistrationForm from '../interfaces/RegistrationForm';

// Utils
import { FileStatus } from '../enums/file-status.enum';

// Enums
import { FileType } from '../enums/file.enum';

export const getExactTypeFile = (item: any, fileType: string, id?: string) => {
  if (!item) return null;
  const fileObj = item.documents.filter(
    (val: any) =>
      (fileType !== FileType.OTHER_ONE &&
        fileType !== FileType.OTHER_TWO &&
        fileType !== FileType.OTHER_THREE &&
        fileType !== FileType.OTHER_FOUR &&
        val.documentType === fileType &&
        !id) ||
      val.Id === id,
  );
  return fileObj[0];
};

export const getExactOtherFile = (files: IFile[], file?: FileType) => {
  if (!files) return null;
  const othersFiles = files.filter((fileProposal: IFile) => {
    return (
      fileProposal.documentType === file &&
      ((fileProposal.status == FileStatus.UPLOAD_REQUESTED && fileProposal.size != null && fileProposal.size > 0) ||
        fileProposal.status != FileStatus.UPLOAD_REQUESTED)
    );
  });
  return othersFiles[0];
};
