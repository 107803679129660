import React from 'react';

export const Avatar: any = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="3.15625" y="3" width="26" height="26" rx="13" stroke="#101010" strokeWidth="2" />
      <rect x="13.1562" y="8" width="6" height="7" rx="3" stroke="#101010" strokeWidth="2" />
      <path
        d="M23.1562 22V22C23.1562 19.7909 21.3654 18 19.1562 18H13.1563C10.9471 18 9.15625 19.7909 9.15625 22V22"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};
