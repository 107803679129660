import React from 'react';

export const IconKebab = () => (
  <svg width="4" height="14" viewBox="0 0 4 14" fill="#8E8E8E" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.5 1.75C3.5 2.57843 2.82843 3.25 2 3.25C1.17157 3.25 0.5 2.57843 0.5 1.75C0.5 0.921573 1.17157 0.25 2 0.25C2.82843 0.25 3.5 0.921573 3.5 1.75Z"
      fill="#8E8E8E"
    />
    <path
      d="M3.5 7C3.5 7.82843 2.82843 8.5 2 8.5C1.17157 8.5 0.5 7.82843 0.5 7C0.5 6.17157 1.17157 5.5 2 5.5C2.82843 5.5 3.5 6.17157 3.5 7Z"
      fill="#8E8E8E"
    />
    <path
      d="M2 13.75C2.82843 13.75 3.5 13.0784 3.5 12.25C3.5 11.4216 2.82843 10.75 2 10.75C1.17157 10.75 0.5 11.4216 0.5 12.25C0.5 13.0784 1.17157 13.75 2 13.75Z"
      fill="#8E8E8E"
    />
  </svg>
);
