import React from 'react';

export const ArrowRight = () => (
  <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.95723 0.793015C1.5667 0.402492 0.933539 0.402492 0.543015 0.793015C0.152491 1.18354 0.152491 1.81671 0.543015 2.20723L3.33585 5.00006L0.543015 7.79289C0.152491 8.18342 0.152491 8.81658 0.543015 9.20711C0.93354 9.59763 1.5667 9.59763 1.95723 9.20711L5.45717 5.70717C5.84769 5.31664 5.84769 4.68348 5.45717 4.29295L1.95723 0.793015Z"
      fill="#8E8E8E"
    />
  </svg>
);

export const ArrowLeft = () => (
  <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.04289 9.20698C4.43342 9.59751 5.06658 9.59751 5.45711 9.20698C5.84763 8.81646 5.84763 8.18329 5.45711 7.79277L2.66427 4.99994L5.45711 2.20711C5.84763 1.81658 5.84763 1.18342 5.45711 0.792893C5.06658 0.402369 4.43342 0.402369 4.04289 0.792893L0.542954 4.29283C0.15243 4.68336 0.15243 5.31652 0.542954 5.70704L4.04289 9.20698Z"
      fill="#8E8E8E"
    />
  </svg>
);
