// @ts-nocheck
import * as dayjs from 'dayjs';
import 'dayjs/locale/pt-br';

export const dateFormat = (date: string | any) => {
  if (date !== null) {
    const dateFormatted = new Date(date);
    return new Intl.DateTimeFormat('pt-BR').format(dateFormatted);
  }
  return '';
};

export const getFullDateText = (date: any) => {
  if (date !== null) {
    const dateFormatted = new Date(date);
    return dayjs(dateFormatted).format('DD MMM. YYYY');
  }
  return '';
};

export const fullDateFormat = (date: any) => {
  if (date !== null) {
    const dateFormatted = new Date(date);
    return dayjs(dateFormatted).hour() > 0
      ? dayjs(dateFormatted).format('DD/MM/YYYY • HH:mm:ss')
      : dayjs(dateFormatted).format('DD/MM/YYYY');
  }
  return '';
};

export const formatManipulateDate = (date: any, number: number, type: string) => {
  const dateFormatted = new Date(date);
  return dayjs(dateFormatted).add(number, type).format('DD MMM YYYY, HH:mm') + 'h';
};

export const formatDays = (date: any, fullDate = false) => {
  const today = dayjs();

  const dateFormatted = new Date(date);

  const dateByDayjs = dayjs(dateFormatted).add(3, 'hour');

  const diff = today.diff(dateByDayjs, 'day');

  if (diff === 0) {
    return `Hoje, ${dateByDayjs.format('HH:mm')}h`;
  }

  if (diff === 1) {
    return `Ontem, ${dateByDayjs.format('HH:mm')}h`;
  }

  if (fullDate) {
    return `${dateByDayjs.format('DD MMM, HH:mm')}h`;
  }

  return `${diff} dias atrás`;
};
