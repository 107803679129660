import React, { useCallback, useEffect } from 'react';
import { createPortal } from 'react-dom';

// CONTEXT
import { usePortals } from '../hooks/PortalsContext';

// CONSTANTS
import { ModalList } from '../constants/portals';

// STYLES
import { Overlay } from '../components/UI/Modal/styles';

const modalElement = document.getElementById('portal-root');

const PortalContainer = () => {
  const { portalOpened, handleClosePortal } = usePortals();

  useEffect(() => {
    const close = (e: any) => {
      if (e.keyCode === 27 && portalOpened) {
        handleClose();
      }
    };

    window.addEventListener('keydown', close);
    return () => window.removeEventListener('keydown', close);
  }, [portalOpened]);

  const handleClose = useCallback(() => {
    handleClosePortal(portalOpened.type);
  }, [portalOpened]);

  if (modalElement) {
    const ModalComponent = ModalList[portalOpened.type];

    return createPortal(
      portalOpened.isOpened ? (
        <>
          <Overlay onClick={handleClose} />
          <ModalComponent onClose={handleClose} {...portalOpened.props} />
        </>
      ) : null,
      modalElement,
    );
  }
  return <></>;
};

export default PortalContainer;
