import React from 'react';

export const IconAdd = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path d="M14.0609 9.01017C14.6132 9.01017 15.0609 8.56246 15.0609 8.01018L15.0609 7.98987C15.0609 7.43758 14.6132 6.98987 14.0609 6.98987L10.0102 6.98987C9.45787 6.98987 9.01015 6.54215 9.01015 5.98987L9.01015 1.93911C9.01015 1.38682 8.56243 0.939109 8.01015 0.939109L7.98985 0.939109C7.43756 0.939109 6.98985 1.38682 6.98985 1.93911L6.98985 5.98987C6.98985 6.54216 6.54213 6.98987 5.98985 6.98987L1.93909 6.98987C1.3868 6.98987 0.939088 7.43758 0.939088 7.98987L0.939088 8.01017C0.939088 8.56246 1.3868 9.01017 1.93909 9.01017L5.98985 9.01017C6.54213 9.01017 6.98985 9.45789 6.98985 10.0102V14.0609C6.98985 14.6132 7.43756 15.0609 7.98985 15.0609H8.01015C8.56244 15.0609 9.01015 14.6132 9.01015 14.0609L9.01015 10.0102C9.01015 9.45789 9.45787 9.01017 10.0102 9.01017L14.0609 9.01017Z" />
    </svg>
  );
};

export const Delete = () => {
  return (
    <svg width="20" height="18" viewBox="0 0 14 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="3.5" width="13" height="1" rx="0.5" stroke="#626568" />
      <path
        d="M4.5 2C4.5 1.44772 4.94772 1 5.5 1H8.5C9.05228 1 9.5 1.44772 9.5 2V4H4.5V2Z"
        stroke="#626568"
        strokeWidth="2"
      />
      <path
        d="M2 4H12V14C12 15.1046 11.1046 16 10 16H4C2.89543 16 2 15.1046 2 14V4Z"
        stroke="#626568"
        strokeWidth="2"
      />
      <rect x="4.5" y="7.5" width="2" height="5" rx="1" fill="#626568" />
      <rect x="7.5" y="7.5" width="2" height="5" rx="1" fill="#626568" />
    </svg>
  );
};
