import { LoanTypeLabel } from '../constants/loan-types';
import { LoanType } from '../enums/loan-type.enum';

const DefaultLoanType = Object.keys(LoanTypeLabel) as LoanType[];

export const createLoanTypeOptions = (types: LoanType[] = DefaultLoanType) => {
  return types.map((type: LoanType) => ({
    value: LoanType[type],
    label: LoanTypeLabel[type],
    name: 'loanType',
  }));
};
