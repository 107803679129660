// @ts-nocheck
import React from 'react';
import { Skeleton } from '../Skeleton';
import { Tr, Td } from './styles';
import { KebabDisabled } from './svgs';

interface ComponentProps {
  totalItems: 10;
  columns: any;
  forceResponsive: boolean;
}

export const TableSkeleton = ({ totalItems, columns, forceResponsive }: ComponentProps) => {
  const Columns = () => {
    return columns?.map((column: any, idx: number) => {
      if (column.className === 'actions-quit') {
        return (
          <Td className={column.className} key={idx}>
            <KebabDisabled />
          </Td>
        );
      }

      return (
        <Td key={idx}>
          <Skeleton className={column.className} />
        </Td>
      );
    });
  };

  let style = {};

  if (forceResponsive) {
    style = {
      height: 53,
    };
  }

  return (
    <>
      {Array(totalItems)
        .fill()
        .map((item, key) => (
          <Tr key={key} style={style}>
            <Columns />
          </Tr>
        ))}
    </>
  );
};
