import styled, { css } from 'styled-components';

interface SelectStyleProps {
  containerType?: boolean;
  isFilled?: boolean;
}

export const Container: any = styled.div<SelectStyleProps>`
  display: flex;
  flex-direction: column;
  width: 100%;
  font-family: 'Clan Web Pro News';
  margin-bottom: 16px;
  .react-select__control {
    height: 56px;
  }

  .react-select__menu {
    z-index: 99;
  }

  ${({ containerType }) =>
    !containerType &&
    css`
      padding: 8px 0;
    `}
  .react-select__control--is-focused {
    svg {
      color: #0090ff;
    }
  }
  .react-select__option {
    position: relative;
    line-height: 24px;
  }
  .react-select__option--is-selected {
    &::after {
      content: '';
      position: absolute;
      width: 8px;
      height: 8px;
      background: #0090ff;
      border-radius: 4px;
      margin-top: 8px;
      right: 29px;
    }
  }
  .react-select__value-container {
    padding: 8px;
  }
  .react-select__value-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const Label = styled.label<SelectStyleProps>`
  display: inline-block;
  font-family: 'Clan Web Pro Medium';
  color: ${({ isFilled }) => (!isFilled ? '#555555' : '#000')};
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 12px;
`;

export const InputContainer: any = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  position: relative;
`;

export const Error = styled.span`
  display: flex;
  color: #ff6b6b;
  font-family: 'Clan Web Pro Medium';
  padding: 4px 0;
  font-size: 12px;
`;
