// @ts-nocheck
// ENUMS
import { Status } from '../enums/proposal-status.enum';

// INTERFACES
import { IFilter } from '../interfaces/Filter';

export class Filter {
  public pageSize: number;
  public pageNumber: number;
  public orderField: string;
  public orderType: string;
  public searchTerm: string;
  public status: Array<Status>;

  constructor(data?: IFilter) {
    this.orderField = data?.orderField ?? 'creationDate';
    this.orderType = data?.orderType ?? 'Desc';
    this.pageNumber = data?.pageNumber ?? 0;
    this.pageSize = data?.pageSize ?? 10;
    this.status = data?.status ?? [];
    this.searchTerm = data?.searchTerm ?? '';

    return this;
  }

  set(field: any, value: any) {
    this[field] = value;
    return this;
  }

  setSteps(status: any) {
    const allStatus: any = [];

    const filtredStatus: Array<Status> = [];

    allStatus.forEach((proposalStatus: any) => {
      if (status.length === 0) {
        filtredStatus.push(proposalStatus.name);
      }

      if (status.some((someStatus: any) => someStatus.name === status.name)) {
        switch (status.label) {
          case 'Aguardando Envio':
            {
              filtredStatus.push(Status.AWAITING_SIGNATURE);
              filtredStatus.push(Status.AWAITING_CHECKING_ACCOUNT);
              filtredStatus.push(Status.AWAITING_CONDITIONS);
            }
            break;
          case 'Pendência':
            {
              filtredStatus.push(Status.FORMALIZATION);
            }
            break;
          default:
            filtredStatus.push(proposalStatus.name);
            break;
        }
      }
    });

    this.set('status', filtredStatus);
    return this;
  }

  getFilters() {
    const filter = [];

    if (this.searchTerm != '' && this.searchTerm != null) {
      filter.push(`search=${this.searchTerm}`);
    }

    if ((this.status || []).length > 0) {
      this.status.forEach((item: any) => {
        if (Array.isArray(item) && item.length > 1) {
          item.forEach((it: any) => {
            filter.push(`status=${it}`);
          });
        } else {
          filter.push(`status=${item}`);
        }
      });
    }

    const params = `&${filter.join('&')}`;

    return `?pageNumber=${this.pageNumber}&pageSize=${this.pageSize}&orderField=${this.orderField}&orderType=${this.orderType}${params}`;
  }
}
