import React from 'react';

// COMPONENT
import { ScreenDefault } from '../../ScreenDefault';

// SVG
import { IconSuccess } from '../svgs';

export const Finish: React.FC = () => {
  return (
    <ScreenDefault
      icon={IconSuccess}
      titleBox="Assinatura enviada! Agora é só aguardar nossa análise."
      textBox="Se tudo ocorrer bem, em breve, faremos o pagamento de seu empréstimo. Em caso de dúvidas, fale com seu agente de vendas. "
      fullHeightContent
      paddingBoxBottom
      marginBottomTitleBox
      alignCenter
      textScreenFinish
    />
  );
};
