import React, { useState } from 'react';

// COMPONENTS
import { Button } from '../../Button';

// STYLES
import {
  ActionButtons,
  Close,
  ModalContainer,
  ModalContent,
  ModalTitle,
  ModalSubtitle,
  LinkContent,
  Link,
} from '../styles';

// SVGS
import { Close as CloseIcon, Phone, Copy, CheckCopy } from '../svgs';

// CONTEXT
import { useProposal } from '../../../../hooks/AppContext';

interface IModalLinkProposal {
  onClose: any;
  onTransform: any;
}

const ModalLinkProposal = ({ onClose }: IModalLinkProposal) => {
  const { formOpened } = useProposal();

  const [isDisabled, setDisabled] = useState(false);
  const [copySuccess, setCopySuccess] = useState(false);

  const copyToClipBoard = async (copyMe: any) => {
    try {
      await navigator.clipboard.writeText(copyMe);
      setCopySuccess(true);
    } catch (err) {
      setCopySuccess(false);
    }
  };

  return (
    <ModalContent>
      <Close onClick={onClose}>
        <CloseIcon />
      </Close>

      <ModalContainer>
        <Phone />
        <ModalTitle>Copiar link de assinatura</ModalTitle>
      </ModalContainer>
      <ModalSubtitle>Copie e envie o link abaixo ao cliente para que ele possa assinar a proposta.</ModalSubtitle>
      <br />
      <LinkContent>
        <Link>{formOpened.url}</Link>
      </LinkContent>
      <ActionButtons modalconfirm>
        <Button
          onClick={() => copyToClipBoard(formOpened.url)}
          disabled={isDisabled}
          iconLeft={copySuccess ? CheckCopy : Copy}
          big
          className={` ${copySuccess ? 'blue' : 'black'}`}
        >
          {copySuccess ? 'Link copiado' : 'Copiar link de assinatura'}
        </Button>
      </ActionButtons>
    </ModalContent>
  );
};

export default ModalLinkProposal;
