import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Form } from '@unform/web';

// ICON
import { Logo, ArrowRight } from './svg';

// STYLE
import { LoginContent, Content, Title } from './styles';

// CONTEXT
import { useAuth } from '../../hooks/auth';

// COMPONENTS
import { Alert } from '../../components/UI/Alert';
import { Loader } from '../../components/UI/Loader';
import { Button } from '../../components/UI/Button';
import { Card } from '../../components/UI/Card';
import { Input } from '../../components/UI/Input';

const Login: React.FC = () => {
  const { signIn } = useAuth();
  const [userEmail, setUserEmail] = useState('');
  const [userPassword, setPassword] = useState('');
  const [alert, setAlert] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoaging] = useState(false);

  const handleEmailChange = useCallback((e) => {
    setUserEmail(e.value);
  }, []);

  const handlePasswordChange = useCallback((e) => {
    setPassword(e.value);
  }, []);

  const handleSubmit = async (e: any) => {
    try {
      setLoaging(true);
      e.preventDefault();
      await signIn({
        email: userEmail,
        password: userPassword,
        originType: 'PayrollLoan',
      });
      setAlert(false);
      navigate('/dashboard');
    } catch (err) {
      setAlert(true);
      setLoaging(false);
    }
  };

  return (
    <LoginContent>
      <Card>
        <Content>
          <Logo />
          <Form onSubmit={handleSubmit}>
            <Title>Seja bem-vindo ao Bari Consig!</Title>
            {alert ? <Alert className="Alert" title="Usuário ou senha inválido." red /> : ''}
            <Input
              id="email"
              name="email"
              label="E-mail"
              type="email"
              onChange={handleEmailChange}
              disabled={false}
              placeholder="Ex. email@email.com.br"
            />
            <Input
              id="password"
              name="password"
              label="Senha"
              type="password"
              onChange={handlePasswordChange}
              disabled={false}
              placeholder="Senha"
              norMarginLeft
            />
            <Button disabled={loading} black onClick={(e: any) => handleSubmit(e)}>
              {loading ? 'Carregando...' : 'Acessar'}
              {loading ? <Loader /> : <ArrowRight />}
            </Button>
          </Form>
        </Content>
      </Card>
    </LoginContent>
  );
};

export default Login;
