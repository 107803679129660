import React from 'react';
import { Routes, Route } from 'react-router-dom';

import { Dashboard } from '../screens/Dashboard';
import { Login } from '../screens/Login';
import { Proposal } from '../screens/Proposal';
import { Manage } from '../screens/Manage';
import { Form } from '../screens/Signature/Form';
import { Header } from '../components/UI/Header';
import { SideMenu } from '../components/UI/SideMenu';
import { useAuth } from '../hooks/auth';

import { ContainerRouters, ContentRouters } from './styles';
import PrivateRoute from './PrivateRoute';

const AvailableRoutes: React.FC = () => {
  const { user } = useAuth();

  return (
    <ContainerRouters>
      {user ? <SideMenu /> : null}
      <ContentRouters>
        {user ? <Header /> : null}
        <Routes>
          <Route path="/" index element={<Login />} />
          <Route path="/dashboard" element={<PrivateRoute component={Dashboard} />} />
          <Route path="/proposal" element={<PrivateRoute component={Proposal} />} />
          <Route path="/manage-proposal" element={<PrivateRoute component={Manage} />} />
          <Route path="/signature/:token" element={<Form />} />
        </Routes>
      </ContentRouters>
    </ContainerRouters>
  );
};

export default AvailableRoutes;
