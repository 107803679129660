import React from 'react';
import { ReactNode } from 'react';

import { StyledCard, StyledTitleCard, StyledDescription } from './styles';

interface CardSelectProps {
  title?: string;
  description?: string;
  simulationResume?: any;
  hasMarginRight?: any;
  children?: ReactNode;
}

const Card: React.FC<CardSelectProps> = ({ children, ...props }) => {
  return (
    <StyledCard {...props}>
      <StyledTitleCard>{props.title}</StyledTitleCard>
      <StyledDescription>{props.description}</StyledDescription>
      {children}
    </StyledCard>
  );
};

export default Card;
