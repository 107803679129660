import React, { ReactElement } from 'react';
import { Button } from '../../../../components/UISignatue/Button';
import { Container, ContainerButton } from './styles';
import { ArrowRight, Refresh } from './svgs';

// CONTEXT
import { useSignature } from '../../../../hooks/SignatureContext';

const Footer = (props: any): ReactElement => {
  const {
    currentStep,
    prevStep,
    nextStep,
    nextStepDisabled = false,
    prevStepDisabled = false,
    loading = false,
  }: any = props;

  const { errorSignature, terms, indexAssinedTerm } = useSignature();

  const nextDescription = () => {
    switch (currentStep) {
      case 1:
        return 'Começar assinatura';
      case 2:
        return 'Aceitar os termos';
      case 4:
        return terms?.length > 1
          ? `Aceitar os termos (${indexAssinedTerm + 1} de ${terms.length})`
          : 'Aceitar os termos';
      case 5:
        return 'Continuar';
      case 8:
        return errorSignature ? 'Tente novamente' : 'Finalizar';

      default:
        return null;
    }
  };

  return (
    <>
      {currentStep == 1 || currentStep == 2 || currentStep == 4 || currentStep == 5 || currentStep == 8 ? (
        <Container className={currentStep > 1 ? ' fixed ' : 'background'}>
          <ContainerButton>
            <Button
              black
              big
              spaced
              onClick={currentStep === 3 ? null : nextStep}
              iconRight={errorSignature ? Refresh : ArrowRight}
              {...{ disabled: nextStepDisabled || loading }}
            >
              {loading ? 'Carregando...' : nextDescription()}
            </Button>
          </ContainerButton>
        </Container>
      ) : (
        ''
      )}
    </>
  );
};

export default Footer;
