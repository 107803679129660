import styled from 'styled-components';

interface propsContent {
  red?: any;
  iconLeft?: any;
}

export const Container = styled.div<propsContent>`
  display: flex;
  padding: 20px;
  border: 1px solid;
  align-items: center;
  border-radius: 8px;
  ${(props: any) =>
    props.red &&
    `
      border-color: var(--color-red-500);
      background: var(--color-red-600);
      color: #FFFFFF;
  `}
`;

export const Icon = styled.div`
  margin-right: 20px;
`;

export const Content = styled.div``;

export const Title = styled.div`
  font-size: 12.6px;
  line-height: 24px;
  letter-spacing: 0.02em;
  font-family: 'Clan Web Pro Medium';
  color: var(--color-black-primary);
`;

export const Paragraph = styled.div`
  font-size: 12.6px;
  line-height: 24px;
  color: var(--color-gray-100);
  font-family: 'Clan Web Pro News';
  margin-top: 4px;
`;
