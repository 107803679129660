import styled from 'styled-components';

export const ContainerRouters = styled.div`
  flex-direction: row;
  flex: 1;
`;

export const ContentRouters = styled.div`
  flex-direction: column;
  flex: 1;
  max-width: 100%;
`;
