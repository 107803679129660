import React, { useState, useEffect, createRef } from 'react';

import { ContainerKebab, Icon, Options } from './styles';

import { IconKebab } from './svgs';

interface IKebab {
  children: React.ReactNode;
  gray?: boolean;
  big?: boolean;
}

const Kebab: React.FC<IKebab> = ({ children, ...rest }: IKebab) => {
  const container = createRef<HTMLDivElement>();
  const [open, setState] = useState(false);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
  });

  const handleClickOutside = (event: Event) => {
    // TODO
    // @ts-ignore
    if (container.current && !container.current.contains(event.target)) {
      setState(false);
    }
  };

  const handleKebabOpening = () => {
    setState(!open);
  };

  const handleButtonClickClose = () => {
    setState(false);
  };

  return (
    <ContainerKebab className="KebabContainer" ref={container}>
      <Icon isOpen={open} className="kebab" onClick={handleKebabOpening} {...rest}>
        <IconKebab />
      </Icon>
      {open && (
        <Options onClick={handleButtonClickClose} {...rest}>
          {children}
        </Options>
      )}
    </ContainerKebab>
  );
};

export default Kebab;
