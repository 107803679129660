import { SignInCredentials } from '../interfaces';
import AuthResource from '../resource/AuthResource';

export default class AuthServices {
  static login(data: SignInCredentials) {
    return AuthResource.login(data)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  }
}
