import React from 'react';

import { ContentScreensError, TitleError, TextError } from './styles';
import { AlertErrorSignature } from './svg';

interface ComponentProps {
  heightCalc?: boolean;
}

export const ErrorSignature: React.FC<ComponentProps> = (...props) => {
  return (
    <ContentScreensError {...props}>
      <AlertErrorSignature />
      <TitleError>Algo errado aconteceu.</TitleError>
      <TextError className="errorSignatureText">
        Tivemos problemas de conexão. Por favor, tente novamente assinar a sua proposta.
      </TextError>
    </ContentScreensError>
  );
};
