import styled from 'styled-components';

interface ContentDataPersonProps {
  halfmargin?: boolean;
  nomargin?: boolean;
  paddingTop?: boolean;
  documentBlock?: boolean;
  completeData?: boolean;
  signatureField?: boolean;
}

interface IContent {
  noSidePadding?: boolean;
  noPaddingBottom?: boolean;
}

export const Container = styled.div`
  max-width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding-left: 20px;
`;

export const ContentWrapper = styled.div`
  display: block;
  background: #ffffff;
  border-radius: 8px;
`;

export const Content = styled.div<IContent>`
  display: flex;
  padding: 32px;
  flex-wrap: wrap;
  ${({ noSidePadding }) =>
    noSidePadding &&
    `
    padding-left: 0;
    padding-right: 0;
  `}
  ${({ noPaddingBottom }) =>
    noPaddingBottom &&
    `
    padding-bottom: 0;
  `}
`;

export const Title = styled.h2`
  color: #8a8d8f;
  font-family: 'Clan Web Pro Medium';
  font-size: 11px;
  line-height: 16px;
  letter-spacing: 0.03em;
  text-transform: uppercase;
`;

export const LeftContent = styled.div`
  display: block;
  @media only screen and (max-width: 1365px) {
    width: calc(100% - 305px);
  }
  @media only screen and (min-width: 1366px) {
    width: calc(100% - 347px);
  }
`;

export const RightContent = styled.div`
  display: block;
  @media only screen and (max-width: 1365px) {
    min-width: 234px;
  }
  @media only screen and (min-width: 1366px) {
    min-width: 327px;
  }
  margin-left: 20px;
  min-height: 100vh;
`;

export const StepCards = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Divider = styled.div`
  display: block;
  height: 2px;
  width: 100%;
  background: #f5f6f7;
`;

export const ButtonMore = styled.div`
  margin-bottom: 24px;
  width: 100%;
  button {
    padding: 16px 32px;
  }
`;

export const IconPaddingLeft = styled.div`
  margin-right: 8px;
`;

export const StepsWrapper = styled.div`
  margin-top: 32px;
  display: flex;
  width: 100%;
  > div {
    width: 20%;
    text-align: center;
    &:not(:last-child) {
      width: calc(20% - 6px);
      margin-right: 6px;
    }
  }
`;

// ------ Tab Signature Item` ------
export const SignatureContainer = styled.div`
  display: block;
  margin-bottom: 40px;
`;

export const SignatureHeader = styled.div`
  display: flex;
  position: relative;
  align-items: center;
`;

export const CounterWrapper = styled.div`
  margin-right: 16px;
`;

export const CounterPreponent = styled.p`
  font-size: 32px;
  line-height: 41px;
  color: #8e8e8e;
  font-family: 'Clan Web Pro Narrow News';
`;

export const NameProponent = styled.h2`
  font-size: 15px;
  line-height: 32px;
  letter-spacing: 0.01em;
  color: #101010;
  font-family: 'Clan Web Pro Medium';
  margin-right: 16px;
  text-transform: capitalize;
  &.disabled {
    color: #8e8e8e;
  }
`;

export const ActionsWrapper = styled.div`
  position: absolute;
  right: 0;
`;

export const KebabAction = styled.div`
  margin-left: 20px;
`;

export const SignatureAction = styled.div<{ hasButton?: boolean }>`
  margin-top: 40px;
  margin-left: 56px;
  ${({ hasButton }) =>
    hasButton &&
    `
    margin-bottom: 32px;
  `}
`;

// ------ Content Collapse Header ------
export const ContentHeader = styled.div<{ documentBlock?: boolean }>`
  display: flex;
  position: relative;
  justify-content: space-between;
  width: 100%;
  z-index: 2;
  ${({ documentBlock }) =>
    documentBlock &&
    `
    margin-top: 32px;
  `}
`;

export const ContentTextPreponent = styled.div`
  display: flex;
  /* align-items: center; */
  width: 100%;
  label {
    position: absolute;
    right: 36px;
    top: 42px;
  }
  .info-text {
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  .text {
    display: flex;
    align-items: center;

    @media (max-width: 1600px) {
      max-width: 270px;
    }
  }
  .badge {
    label {
      height: 22px;
      width: max-content;
    }
  }
`;

export const NameProponentSmall = styled.h4`
  font-size: 16px;
  line-height: 32px;
  letter-spacing: 0.01em;
  color: #555555;
  font-family: 'Clan Web Pro Medium';
  margin-right: 16px;
`;

// --------- DataPerson ---------
export const ContentDataPerson = styled.div<ContentDataPersonProps>`
  display: flex;
  flex-direction: column;
  margin-left: 56px;
  ${({ halfmargin }) =>
    halfmargin &&
    `
    margin-left: 32px! important;
  `}
  ${({ nomargin }) =>
    nomargin &&
    `
    margin-left: 0;
  `}
  ${({ paddingTop }) =>
    paddingTop &&
    `
    padding-top: 32px;
  `}
  ${({ documentBlock, completeData }) =>
    (documentBlock || completeData) &&
    `
    margin-top: 24px;
  `}
`;

export const ContentDataPersonInside = styled.div`
  display: flex;
  flex-direction: column;
  border: solid 2px #ededed;
  border-radius: 8px;
  padding: 24px 32px;
  margin-bottom: 64px;

  .items {
    display: flex;
    /* flex-wrap: wrap; */
    align-items: flex-end;
    /* justify-content: space-between; */
  }
  .add-owner {
    display: flex;
    justify-content: center;
    margin-top: 2vh;
  }
  .icon-add {
    right: 27px;
    top: 27px;
    padding-right: 1vw;
  }
  .p-add {
    padding-left: 1vw;
    line-height: 24px;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
  .p-owner-title {
    color: #8e8e8e;
    font-size: 12px;
    line-height: 16px;
  }
  .p-owner {
    font-family: 'Clan Web Pro News';
    padding-left: 5px;
    font-size: 14px;
    line-height: 20px;
    color: #101010;
    width: 90%;
  }
`;
export const TitleDataPersonInside = styled.h4`
  font-size: 18px;
  line-height: 24px;
  color: #101010;
  font-family: 'Clan Web Pro Medium';
`;
export const SubtitlePersonInside = styled.h6`
  font-size: 14px;
  line-height: 32px;
  color: #8a8d8f;
  font-family: 'Clan Web Pro News';
`;

export const TitleDataPerson = styled.h2`
  font-size: 14px;
  line-height: 24px;
  color: #101010;
  font-family: 'Clan Web Pro Medium';
`;

export const InfoPerson = styled.div`
  display: flex;
  flex-direction: column;
`;
export const DataPerson = styled.div<ContentDataPersonProps>`
  display: flex;
  justify-content: space-between;
  margin-right: 32px;

  ${({ signatureField }) =>
    signatureField &&
    `
      width: 100%;
      justify-content: flex-start;
    `}
`;

export const InfoDataPerson = styled.div<ContentDataPersonProps>`
  display: flex;
  /* justify-content: space-between; */
  /* border-bottom: 1px solid #F6F6F6; */
  flex-direction: column;
  /* padding-bottom: 32px; */
  padding-right: 10px;
  flex: 1;
  justify-content: space-between;

  ${({ signatureField }) =>
    signatureField &&
    `
      max-width: 32.4%;
      justify-content: flex-start;
      padding-right: 0;
    `}

  @media (max-width: 1600px) {
    max-width: 12vw;
  }
  &.btn {
    display: flex;
    align-items: flex-start;
    margin-top: 40px;
  }
  .disabledInfo {
    color: #8e8e8e;
  }
`;

export const ItemDataPerson = styled.div`
  display: flex;
  flex-direction: column;
  width: 33.3%;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-top: 24px;

  @media (max-width: 1600px) {
    width: 25vw;
  }
`;

export const TitleItemDataPerson = styled.h2`
  font-size: 10px;
  line-height: 16px;
  color: #8a8d8f;
  font-family: 'Clan Web Pro Medium';
  margin-bottom: 4px;
`;

export const SubtitleItemDataPerson = styled.p<{ isDisabled?: boolean }>`
  max-width: 12vw;
  font-size: 12px;
  line-height: 24px;
  color: #424548;
  font-family: 'Clan Web Pro Medium';
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ${({ isDisabled }) =>
    isDisabled &&
    `
    color: #8e8e8e;
  `}
`;

export const TitleCollapse = styled.h2`
  font-size: 11px;
  line-height: 16px;
  letter-spacing: 0.01em;
  color: #8e8e8e;
  margin-bottom: 40px;
  font-family: 'Clan Web Pro Medium';
`;

// ------ Modal ------

export const ModalContent = styled.div`
  background: #ffffff;
  border: 1px solid #f6f6f6;
  box-sizing: border-box;
  box-shadow: 20px 0px 30px -10px rgba(0, 0, 0, 0.07), -20px 0px 20px -10px rgba(0, 0, 0, 0.07),
    0px 20px 30px -10px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  justify-content: center;
  padding-top: 33px;
  flex-direction: column;
  position: fixed;
  width: 480px;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  &.feedback-content {
    background: #101010;
    border: 1px solid #000000;
    justify-content: center;
    padding-top: 0;
  }
`;

export const ModalContainer = styled.div`
  flex-direction: column;
  width: 100%;
  position: relative;
  align-items: flex-start;
  padding-left: 48px;
  padding-right: 48px;
  &.feedback {
    padding-left: 96px;
    padding-right: 96px;
    padding-top: 55px;
    padding-bottom: 0;
    button {
      width: 100%;
    }
    h2 {
      color: #ffffff;
      margin-bottom: 16px;
      justify-content: center;
    }
    p {
      margin-bottom: 52px;
    }
    .icon-close {
      position: absolute;
      right: 27px;
      top: 27px;
      cursor: pointer;
    }
    img {
      margin-bottom: 42px;
    }
  }
`;

export const ModalTitle = styled.h2`
  font-size: 17px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #000000;
  font-family: 'Clan Web Pro Medium';
  margin-bottom: 64px;
  justify-content: space-between;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  .icon-close {
    /* position: absolute;
    right: 19px;
    top: -35px; */
    cursor: pointer;
  }
`;

export const ModalSubtitle = styled.p`
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
  opacity: 0.7;
  font-family: 'Clan Web Pro News';
  margin-bottom: 76px;
  width: 100%;

  &.black {
    color: #646464;
  }
  &.left {
    text-align: left;
  }
`;

export const ActionButtons = styled.div`
  display: flex;
  background-color: #f6f6f6;
  width: 100%;
  justify-content: center;
  padding: 24px 48px;
  margin-top: 39px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;

  button {
    width: 100%;
  }
  &.btn-feedback {
    background-color: #1a1a1a;
    margin-top: 0;
  }
`;

export const CotentKebab = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
`;

export const WrapperProponents = styled.div`
  padding: 0;
`;

export const ContainerComplete = styled.div`
  width: 100%;
  display: flex;
  margin-top: 56px !important;
  padding-bottom: 24px;
  align-items: center;
  position: relative;
  justify-content: space-between;
  .text {
    svg {
      min-width: 24px;
    }
  }
`;

export const CompleteMessage = styled.p`
  color: #555555;
  font-size: 12.6px;
  line-height: 24px;
  margin-left: 20px;
  margin-right: 20px;
`;

export const ButtonWrapper = styled.div`
  width: 240px;
`;

export const MarginBottom16 = styled.div`
  margin-bottom: 16px;
  margin-top: 0px !important;
`;

export const DisclaimerWrapper = styled.div`
  display: grid;
  gap: 10px;

  font-family: 'Clan Web Pro News';
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  letter-spacing: 0.01em;
  color: #101010;
`;

export const Disclaimer = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  gap: 5px;

  svg {
    align-self: center;
  }
`;
