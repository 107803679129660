import React, { useCallback, useEffect } from 'react';

// COMPONENTS
import { Col, Row } from '../../../components/GRID';
import { CardSelect } from '../../../components/UI/CardSelect';
// ICONS
import { Consigned, Fgts } from '../svgs';
// STYLES
import { Paginator, Paragraph, TitleStep } from '../styles';
// ENUMS
import { Type } from '../../../enums/loan-type.enum';
// CONTEXT
import { useProposal } from '../../../hooks/AppContext';
// CONTEXT
import { useAuth } from '../../../hooks/auth';
// INTERFACE
import Proposal from '../../../interfaces/Proposal';

interface IProduct {
  formValues: Proposal;
  onChange: (e: any) => void;
}

export const Product = ({ formValues, onChange }: IProduct) => {
  const { setNextStepDisabled } = useProposal();
  const { flag } = useAuth();
  const flags = Object.values(flag);

  const selectFlagFgts = flags.find((objetct: any) => objetct.code === 'PAYROLL_LOAN_FGTS');

  const handleChange = useCallback(
    (e: any) => {
      onChange(e);
    },
    [onChange],
  );

  useEffect(() => {
    formValues.loanDetails = [];
    formValues.cnpjConsigneeId = null;
    setNextStepDisabled(!formValues.type);
  }, [formValues]);

  return (
    <Row>
      <Col sm={18} md={18} lg={18}>
        <Paginator>1/5</Paginator>
        <TitleStep>A proposta é para qual produto?</TitleStep>
        <div>
          <CardSelect
            id="Consigned"
            type="radio"
            name="type"
            value={Type.CONSIGNED}
            title="Consignado"
            description="Empréstimo consignado para conveniados."
            icon={Consigned}
            checked={formValues.type === Type.CONSIGNED}
            onChange={handleChange}
          />

          <CardSelect
            id="Fgts"
            type="radio"
            name="type"
            value={Type.FGTS}
            title="Saque FGTS"
            description="Adiantamento do saque aniversário FGTS."
            icon={Fgts}
            checked={formValues.type === Type.FGTS}
            onChange={handleChange}
            disabled={selectFlagFgts?.isActive ? false : true}
          />
        </div>
        <Paragraph>Selecione um dos produtos para começar o preenchimento da proposta.</Paragraph>
      </Col>
    </Row>
  );
};
