import styled from 'styled-components';

export const Container = styled.div`
  flex: 1;
`;

export const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: start;
  align-items: flex-start;
  background: #ffff;
  flex: 1;
  height: 100vh;
  overflow-y: auto;
`;

export const ContentStep = styled.div`
  padding: 72px 30px 0px 60px;
  width: 100%;
  margin-bottom: 188px;

  /* step 1 */
  .loanList {
    flex-direction: column;
    margin-bottom: 16px;
  }
  /* STEP CONFIRMATION */
  &.confirmation {
    padding-top: 128px;
    margin-bottom: 67px;
    height: calc(100% - 80px);
    padding-bottom: 67px;
  }
`;

export const TitleStep = styled.h3`
  font-family: 'Clan Web Pro News';
  font-size: 28px;
  line-height: 40px;
  margin: 0 0 60px 0;

  &.RemoveMarginBotton {
    margin-bottom: 0px;
  }
`;

export const SubTitleStep = styled.h4`
  font-family: 'Clan Web Pro News';
  font-size: 16px;
  line-height: 32px;
  color: #626568;
  margin-top: 12px;
  margin-bottom: 60px;
`;

export const ContentLabel = styled.div`
  margin-bottom: 16px;
  & > div {
    width: 100%;
  }
`;

export const Paginator = styled.p`
  color: #8e8e8e;
  font-size: 12px;
  margin: 0 0 8px;
  font-family: 'Clan Web Pro Medium';
`;
export const Paragraph = styled.p`
  color: #626568;
  font-size: 13px;
  line-height: 24px;
  font-family: 'Clan Web Pro News';
  margin-top: 24px;
`;

//STEP RESUME
export const TitleCardResume = styled.h3`
  font-size: 11px;
  line-height: 16px;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  color: #8a8d8f;
  font-family: 'Clan Web Pro Medium';
  margin-bottom: 40px;
  position: relative;
  margin-left: 16px;

  &::before {
    content: '';
    position: absolute;
    width: 8px;
    height: 8px;
    background: #0090ff;
    border-radius: 4px;
    left: -16px;
    top: 3px;
    bottom: 0;
  }
`;

export const BoxDataResume = styled.div`
  flex-direction: column;
  min-width: 30%;
`;

export const ContentBoxData = styled.div`
  flex-direction: column;
  gap: 25px;
  > div {
    flex-wrap: wrap;
    gap: 25px;
  }
`;
export const TitleResumeData = styled.h4`
  font-size: 12px;
  line-height: 16px;
  color: #8a8d8f;
  font-family: 'Clan Web Pro Medium';
`;
export const ResumeData = styled.p`
  font-size: 14px;
  line-height: 24px;
  color: var(--color-black-primary);
  margin-top: 4px;
  font-family: 'Clan Web Pro News';
  &.name {
    margin-bottom: 36px;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.01em;
  }
`;

export const ContainerListProposal = styled.div`
  flex-direction: column;
  > div {
    margin-bottom: 16px;
  }
  > div:last-child {
    margin-bottom: 0px;
  }
`;

export const ListProposalResume = styled.div`
  width: 100%;
  border: 1px solid #f5f6f7;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 24px;
  flex-wrap: wrap;
  gap: 12px;
`;

export const DataList = styled.div<{ hasMargin?: boolean }>`
  flex-direction: column;
  min-width: ${({ hasMargin }) => (hasMargin ? '30%' : '40%')};
`;
export const ListName = styled.h4`
  font-size: 12px;
  line-height: 16px;
  color: #8a8d8f;
  font-family: 'Clan Web Pro Medium';
`;
export const ListValue = styled.p`
  font-size: 14px;
  line-height: 24px;
  font-family: 'Clan Web Pro News';
  margin-top: 4px;
  color: var(--color-black-primary);
`;

export const ChangeData = styled.button`
  margin-top: 16px;
  margin-bottom: 32px;
  border: navajowhite;
  background: inherit;
  display: flex;
`;

export const TextChange = styled.div`
  color: #0090ff;
  font-size: 16px;
  line-height: 24px;
  font-family: 'Clan Web Pro Medium';
  margin-left: 10px;
`;

// TYPE LOAN FGTS

export const ContentTypeFgts = styled.div`
  flex-direction: column;
  p {
    font-size: 16px;
    line-height: 32px;
    color: var(--color-black-primary);
    font-family: 'Clan Web Pro News';
  }
  > div {
    width: 100%;
    margin-top: 18px;
    align-items: end;
  }
`;

export const SelectLoanFgts = styled.div`
  margin-right: 24px;
  width: 100%;
`;
export const NumberProposalfgts = styled.div`
  width: 100%;
  > div {
    margin-right: 0px;
  }
`;
// STEP CONFIRMATION
export const ContentConfirmation = styled.div`
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;
export const TitleConfirmation = styled.h2`
  font-size: 28px;
  line-height: 40px;
  color: var(--color-black-primary);
  margin-top: 40px;
  font-family: 'Clan Web Pro News';
`;
export const SubtileConfirmation = styled.h4`
  font-size: 20px;
  line-height: 40px;
  color: #626568;
  margin-top: 12px;
  font-family: 'Clan Web Pro News';
  text-align: center;
`;
export const ContentAction = styled.div`
  margin-top: 48px;
  button {
    svg {
      margin-right: 18px;
    }
  }
`;
