import React, { ReactElement, useState, useEffect, useCallback } from 'react';
import StepDisplay from '../Form/Steps/Steps';
import { Container, Content, Error, ErrorTitle } from './styles';
// Component
import { Footer } from './Footer';

import { Signature } from '../../../models/Signature';

// Context
import { useSignature } from '../../../hooks/SignatureContext';

// Utils
import useDeviceDetect from '../../../utils/Signature/useDeviceDetect';

// Hooks
import { useAuth } from '../../../hooks/auth';

// Service
import SignatureService from '../../../services/SignatureService';

// Router
import { useLocation } from 'react-router-dom';

// Screen Error
import { LinkExpired } from '../ScreensError/index';
import logger from '../../../utils/logger.utils';

const Form = (): ReactElement => {
  const {
    formOpened,
    setFormOpened,
    prevStepDisabled,
    nextStepDisabled,
    step,
    prevStep,
    nextStep,
    stepLoading,
    errorGelocation,
  } = useSignature();
  const data = new Signature(formOpened);

  const [isMobileSize, setIsMobileSize] = useState(false);
  const { isMobile } = useDeviceDetect();
  const [invalidLink, setInvalidLink] = useState(false);
  const [expiredLink, setExpiredLink] = useState(false);
  const [commercialConditionNotFound, setCommercialConditionNotFound] = useState(false);

  const [Token, setToken] = useState(false);
  const location = useLocation();
  const { signOut } = useAuth();

  const handleResize = () => {
    if (window.innerWidth <= 485) {
      setIsMobileSize(true);
    } else {
      setIsMobileSize(false);
    }
  };

  // viewport unit on mobile rotate
  window.addEventListener('resize', () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  });

  useEffect(() => {
    signOut();
    handleResize();
    const handleValidateToken = async () => {
      const token = location.pathname.split('/signature/');
      localStorage.setItem('token', token[1]);
      const fetchSignatureLink = await SignatureService.fetchSignature(token[1]);

      const { response } = fetchSignatureLink;

      if (response != null) {
        if (response.status === 400 && response.data.validationErrors === undefined) {
          logger.error('Error on response');

          return 0;
        }
        if (response.data.validationErrors.invalidLink) {
          setInvalidLink(true);
        }
        if (response.data.validationErrors.linkExpired) {
          setExpiredLink(true);
        }
        if (response.data.validationErrors.commercialConditionNotFound) {
          setCommercialConditionNotFound(true);
        }

        return 0;
      }
      setToken(true);
      setFormOpened({
        ...fetchSignatureLink.data,
      });
    };
    handleValidateToken();

    // viewport unit on mobile
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }, []);

  return (
    <Container>
      <Content>
        {isMobile && isMobileSize && Token && (
          <>
            <StepDisplay currentStep={step} formValues={data} />
            {!errorGelocation && (
              <Footer
                prevStep={prevStep}
                nextStep={nextStep}
                currentStep={step}
                nextStepDisabled={nextStepDisabled}
                prevStepDisabled={prevStepDisabled}
                loading={stepLoading}
              />
            )}
          </>
        )}
        {!isMobile || !isMobileSize ? (
          <Error>
            <ErrorTitle>Acessar no celular</ErrorTitle>
          </Error>
        ) : (
          <>
            {invalidLink && (
              <Error>
                <ErrorTitle>Link inválido.</ErrorTitle>
              </Error>
            )}

            {commercialConditionNotFound && (
              <Error>
                <ErrorTitle>Condição comercial não encontrada!</ErrorTitle>
              </Error>
            )}

            {expiredLink && (
              <Error>
                <LinkExpired />
              </Error>
            )}
          </>
        )}
      </Content>
    </Container>
  );
};

export default Form;
