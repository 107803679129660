export const tableColumnStatus = [
  {
    id: 1,
    status: true,
    label: 'Número da Proposta',
    disabled: true,
    className: 'ProposalNumber',
    panelId: 'proposalNumber',
    hasOptions: false,
  },

  {
    id: 2,
    status: true,
    label: 'Tipo de empréstimo',
    disabled: true,
    className: 'LoanType',
    panelId: 'loanType',
    hasOptions: false,
  },
  {
    id: 3,
    status: true,
    label: 'Nome',
    disabled: true,
    className: 'Name',
    panelId: 'name',
    hasOptions: false,
  },
  {
    id: 4,
    status: true,
    label: 'CPF',
    disabled: false,
    className: 'DocumentNumber',
    panelId: 'documentNumber',
    hasOptions: false,
  },
  {
    id: 5,
    status: true,
    label: 'Convênio',
    disabled: false,
    className: 'ConvenantId',
    panelId: 'convenant',
    hasOptions: false,
  },
  {
    id: 6,
    status: true,
    label: 'Data',
    disabled: false,
    className: 'CreationDate',
    panelId: 'creationDate',
    hasOptions: false,
  },

  {
    id: 7,
    status: true,
    label: 'Status',
    disabled: false,
    className: 'Status',
    panelId: 'status',
    hasOptions: false,
  },
];

export const removeDuplicated = (items: any): any => {
  if (items != null) {
    return Object.values(
      items.reduce((acc: any, item: any) => (item && item.label && (acc[item.label] = item), acc), {}),
    );
  }
  return [];
};
