import styled from 'styled-components';

interface IIcon {
  isOpen?: boolean;
  big?: boolean;
  gray?: boolean;
}

export const ContainerKebab = styled.div`
  position: relative;
  display: inline-block;
  min-width: 10px;
`;

export const Icon = styled.div<IIcon>`
  /* background-color: #ffffff; */
  cursor: pointer;
  border: 1px solid #ffffff;
  box-sizing: border-box;
  border-radius: 4px;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ big }) =>
    big &&
    `
    width: 42px;
    height: 42px;
    border-radius: 8px;
  `}

  ${({ gray }) =>
    gray &&
    `
    border: 1px solid #B5B8BA;
  `}

  ${({ isOpen, gray }) =>
    !isOpen &&
    !gray &&
    `
      &:hover {
        background-color: #f6f6f6;
        border: 1px solid #f6f6f6;
        svg {
          fill: #555555;
          path {
            fill: #555555;
          }
        }
      }
  `}

  ${({ isOpen, gray }) =>
    !isOpen &&
    gray &&
    `
    &:hover {
      background-color: #E6F5FE;
      border: 1px solid #A0D8FD;
      svg {
        fill: #0090FF;
        path {
          fill: #0090FF;
        }
      }
    }
  `}

  ${({ isOpen }) =>
    isOpen &&
    `
      border: 1px solid #0090ff;
      svg {
        fill: #0090ff;
        path {
          fill: #0090ff;
        }
      }
      `}
`;

export const Options = styled.div<IIcon>`
  position: absolute;
  background-color: white;
  width: 267px;
  border: 1px solid #f6f6f6;
  box-sizing: border-box;
  box-shadow: 20px 0px 30px -10px rgba(0, 0, 0, 0.07), -20px 0px 20px -10px rgba(0, 0, 0, 0.07),
    0px 20px 30px -10px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  right: 0px;
  top: 38px;
  z-index: 999;
  ${({ big }) =>
    big &&
    `
    top: 52px;
  `}
`;
