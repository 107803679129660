import styled, { css } from 'styled-components';

interface propsHeader {
  proposal?: any;
}

export const Container = styled.div<propsHeader>`
  width: 100%;
  box-sizing: border-box;
  flex-direction: row;
  justify-content: space-between;
  background: #ffffff;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.03);
  padding-right: 60px;
  font-family: 'Clan Web Pro Medium';
  font-size: 12.6px;
  color: #101010;
  z-index: 5;
  height: 80px;
  ${({ proposal }) =>
    proposal &&
    css`
      box-shadow: 0px 1px 1px rgba(246, 246, 246, 1);
      .searchProposal {
        background-color: #f6f6f6;
      }
    `}
  .row {
    width: auto;
  }
  @media (max-width: 1400px) {
  }
  @media (max-width: 1360px) {
  }
`;
export const Content = styled.div`
  padding-bottom: 16px;
  padding-top: 16px;
`;
export const Search = styled.div`
  width: 354px;
`;
