import React, { useState, useRef, useEffect } from 'react';

// SERVICE
import { useSignature } from '../../../hooks/SignatureContext';

// Hooks
import useOnClickOutside from '../../../hooks/useOnClickOutside';

// UTIL
import { numberFormat } from '../../../utils/Signature/formatCurrency';
import { getFullDateText } from '../../../utils/Signature/formatDate';

//STYLES
import {
  ContainerCardProposal,
  HeaderCardProposal,
  TitleFgts,
  SubTitleFgts,
  AccordionContent,
  AccordionCard,
  IconAccordion,
  AccordionContentText,
  AccordionText,
  Paragraph,
  SubParagraph,
  SplitChart,
  ActionCollapse,
} from './styles';

// SVG
import { Spreadsheet, Money, ActionFgts, Calendar, Chart } from '../Form/svgs';

export const ProposalFgts: React.FC = ({ formValues }: any) => {
  const { setDataLoanFgts } = useSignature();
  const content = useRef<HTMLInputElement>(null);
  const sensitive = useRef<HTMLInputElement>(null);
  const [rotation, setRotation] = useState('accordion__icon');
  const [active, setActive] = useState('');
  const [height, setHeight] = useState('0px');

  const toggleAccordion = () => {
    setActive(active === '' ? 'active' : '');
    setHeight(active === 'active' ? '0px' : `${content.current?.scrollHeight}px`);
    setRotation(active === 'active' ? 'accordion__icon' : 'accordion__icon rotate');
  };

  useOnClickOutside(sensitive, () => {
    setActive('');
    setHeight('0px');
    setRotation('accordion__icon');
  });

  useEffect(() => {
    setDataLoanFgts({
      Loan: formValues.commercialConditions.totalFinancedAmount,
      Installments: formValues.commercialConditions.installments.length,
    });
  }, []);

  return (
    <ContainerCardProposal className={'accordion__section'} ref={sensitive}>
      <HeaderCardProposal className="remove-margin" style={{ flexDirection: 'column', alignItems: 'start' }}>
        <TitleFgts>{formValues.proposalNumber}</TitleFgts>
        <SubTitleFgts>Adiantamento FGTS</SubTitleFgts>
      </HeaderCardProposal>
      <AccordionCard className="remove-border-top">
        <IconAccordion>
          <Money />
        </IconAccordion>
        <AccordionContentText>
          <AccordionText>
            <Paragraph>Valor da antecipação</Paragraph>
            <SubParagraph className="color-text">
              {numberFormat(formValues.commercialConditions.totalFinancedAmount / 100)}
            </SubParagraph>
          </AccordionText>
        </AccordionContentText>
      </AccordionCard>
      <AccordionCard>
        <IconAccordion>
          <Spreadsheet />
        </IconAccordion>
        <AccordionContentText>
          <AccordionText>
            <Paragraph>FGTS a ser bloqueado</Paragraph>
            <SubParagraph>{numberFormat(formValues.commercialConditions.totalAmountToPay / 100)}</SubParagraph>
          </AccordionText>
          <AccordionText>
            <Paragraph>Seguro</Paragraph>
            <SubParagraph>{numberFormat(formValues.commercialConditions.insuranceValue / 100)}</SubParagraph>
          </AccordionText>
          <AccordionText>
            <Paragraph>Taxa de juros mensal</Paragraph>
            <SubParagraph>{formValues.commercialConditions.monthlyFee / 100} ao mês</SubParagraph>
          </AccordionText>
          <AccordionText>
            <Paragraph>Taxa de juros anual</Paragraph>
            <SubParagraph>{formValues.commercialConditions.annualFee / 100} ao ano</SubParagraph>
          </AccordionText>
        </AccordionContentText>
      </AccordionCard>
      <AccordionCard>
        <IconAccordion>
          <Calendar />
        </IconAccordion>
        <AccordionContentText>
          <AccordionText>
            <Paragraph>Primeiro vencimento</Paragraph>
            <SubParagraph>{getFullDateText(formValues.commercialConditions.dueDate)}</SubParagraph>
          </AccordionText>
        </AccordionContentText>
      </AccordionCard>
      <AccordionCard>
        <IconAccordion>
          <Chart />
        </IconAccordion>
        <AccordionContentText className="action">
          <AccordionText>
            <Paragraph>Distribuição das parcelas</Paragraph>
            <SubParagraph>{formValues.commercialConditions.installments.length} parcelas anuais</SubParagraph>
          </AccordionText>
          <ActionCollapse onClick={toggleAccordion} className={active ? 'rotation' : ''}>
            <ActionFgts />
          </ActionCollapse>
        </AccordionContentText>
      </AccordionCard>
      {/* AccordionContent */}
      <AccordionContent ref={content} style={{ maxHeight: `${height}` }} className="accordion__content">
        {formValues.commercialConditions.installments.map((item: any, idx) => {
          return (
            <AccordionCard className="accordion-card-fgts" key={idx}>
              <div className="row-accordion"></div>
              <SplitChart>{idx + 1}</SplitChart>
              <AccordionContentText>
                <AccordionText>
                  <Paragraph>{getFullDateText(item.dueDate)}</Paragraph>
                  <SubParagraph>{numberFormat(item.pmtValue / 100)}</SubParagraph>
                </AccordionText>
              </AccordionContentText>
            </AccordionCard>
          );
        })}
      </AccordionContent>
    </ContainerCardProposal>
  );
};
