import React from 'react';

// COMPONENTS
import { Button } from '../../../UI/Button';

// STYLES
import { ActionButtons, ModalContainer, ModalContent, ModalTitle, ModalSubtitle } from '../styles';

// SVGS
import { ErrorProposal } from '../svgs';

import { useNavigate } from 'react-router-dom';

interface IModalErrorProposalInconsistent {
  onClose: any;
}

const ModalErrorProposalInconsistent = ({ onClose }: IModalErrorProposalInconsistent) => {
  const navigate = useNavigate();

  return (
    <ModalContent>
      <ModalContainer>
        <ErrorProposal />
        <ModalTitle>CPF ou número de celular inválidos</ModalTitle>
      </ModalContainer>
      <ModalSubtitle>
        Os dados informados estão diferentes dos já cadastrados na proposta. Confira e tente novamente.
      </ModalSubtitle>
      <ActionButtons className="left">
        <Button onClick={onClose} big black>
          Conferir dados
        </Button>
        <Button onClick={() => navigate('/manage-proposal')} big transparent>
          Gerenciar propostas
        </Button>
      </ActionButtons>
    </ModalContent>
  );
};

export default ModalErrorProposalInconsistent;
