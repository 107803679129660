import React, { useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import AvailableRoutes from './routes/AvailableRoutes';
import { GlobalStyle } from './components/UI/GlobalStyle';

// CONTEXT
import { AppProvider } from './hooks';
import PortalsProvider from './hooks/PortalsContext';
import { ToastProvider } from './hooks/ToastContext';

// CONTAINERS
import PortalContainer from './containers/PortalContainer';

import { ContainerGlobal } from './styles';
import './interceptors/axios.interceptor';

const GlobalStyleProxy: any = GlobalStyle;

const App: React.FC = () => {
  const [domLoaded, setDomLoaded] = useState(false);

  useEffect(() => {
    setDomLoaded(true);
  }, []);

  return (
    <>
      {domLoaded && (
        <BrowserRouter>
          <ToastProvider>
            <PortalsProvider>
              <AppProvider>
                <ContainerGlobal>
                  <AvailableRoutes />
                  <PortalContainer />
                </ContainerGlobal>
              </AppProvider>
            </PortalsProvider>
          </ToastProvider>
          <GlobalStyleProxy />
        </BrowserRouter>
      )}
    </>
  );
};

export default App;
