import styled from 'styled-components';

export const Container = styled.div`
  flex: row;
  flex: 1;
`;
export const Content = styled.div`
  width: 100%;
  justify-content: space-between;
`;
export const Overview = styled.div`
  flex: 1;
  padding: 40px;
  > div {
    justify-content: center;
    align-items: center;

    svg {
      margin-bottom: 32px;
      width: 100%;
    }
  }
`;

export const Title = styled.p`
  font-weight: 300;
  font-size: 42px;
  line-height: 56px;
  color: var(--color-black-primary);
  flex-direction: column;
  text-align: center;
  display: flex;
  align-items: center;

  & span {
    color: var(--color-blue-primary);
  }
`;
