import React from 'react';

import {
  ContentScreenDefault,
  TitleScreenDefault,
  TextBoxScreenDefault,
  ContentTextScreenDefault,
  BoxTextScreenDefault,
  TitleBoxScreenDefault,
} from './styles';
import { Logo } from './svg';

interface ComponentProps {
  title?: string;
  titleBox?: string;
  textBox?: any;
  marginBottomTitleBox?: any;
  textScreenFinish?: any;
  fullHeightContent?: any;
  paddingBoxBottom?: any;
  alignCenter?: any;
  icon?: React.ComponentType;
}

const ScreenDefault: React.FC<ComponentProps> = ({ icon: Icon, ...props }) => {
  return (
    <ContentScreenDefault {...props}>
      <Logo />
      <ContentTextScreenDefault {...props}>
        <TitleScreenDefault>{props.title}</TitleScreenDefault>
        <BoxTextScreenDefault {...props}>
          {/* @ts-ignore */}
          {Icon && <Icon />}
          <TitleBoxScreenDefault {...props}>{props.titleBox}</TitleBoxScreenDefault>
          <TextBoxScreenDefault {...props}> {props.textBox}</TextBoxScreenDefault>
        </BoxTextScreenDefault>
      </ContentTextScreenDefault>
    </ContentScreenDefault>
  );
};

export default ScreenDefault;
