import styled from 'styled-components';

/**
 * @author Matheus Vieira <matheus.vieira@deal.com.br>
 * @since 2020-11-19
 */
interface ContainerProps {
  active: boolean;
  leftSide?: boolean;
  rightSide?: boolean;
}

export const Container = styled.div<ContainerProps>`
  z-index: 1;
  display: none;
  position: absolute;
  top: 52px;
  min-width: 267px;
  max-width: 100%;
  background: #ffffff;
  border: 1px solid #f6f6f6;
  border-radius: 8px;
  box-sizing: border-box;
  box-shadow: 20px 0px 30px -10px rgba(0, 0, 0, 0.07), -20px 0px 20px -10px rgba(0, 0, 0, 0.07),
    0px 20px 30px -10px rgba(0, 0, 0, 0.08);
  ${({ leftSide }) =>
    leftSide &&
    `
      left: 0; 
    `}
  ${({ rightSide }) =>
    rightSide &&
    `
      right: 30%; 
    `}
  ${({ active }) =>
    active &&
    `
      display: block;
    `}
`;

export const Options = styled.div`
  flex-wrap: wrap;
  padding: 18px 26px 0 20px;
  &.HasScroll {
    max-height: 370px;
    overflow-y: scroll;
  }
`;

export const Actions = styled.div`
  padding: 13px 26px 13px 20px;
  border-top: 1px solid #f6f6f6;
  justify-content: space-between;
  &:hover {
    background: #f6f6f6;
  }
`;

export const Button = styled.button`
  padding: 0;
  outline: 0;
  border: none;
  cursor: pointer;
  background: none;
  font-family: 'Clan Web Pro Medium';
  font-size: 13px;
  line-height: 24px;
  color: #0090ff;

  &:disabled {
    cursor: not-allowed;
    color: #c2c2c2;
  }
`;

export const Icon = styled.div`
  &.Disabled svg {
    cursor: not-allowed;
    path {
      fill: #c2c2c2;
    }
  }
`;
