import React from 'react';

// Service
import { useSignature } from '../../../../hooks/SignatureContext';
// Component
import { ScreenDefault } from '../../ScreenDefault';
import { Type } from '../../../../enums/loan-type.enum';

export const Home: React.FC = () => {
  const { formOpened } = useSignature();
  return (
    <ScreenDefault
      title={formOpened.type === Type.FGTS ? 'Bem-vindo ao FGTS Bari' : 'Bem-vindo ao Consignado Bari'}
      textBox={
        <>
          Olá, <strong>{formOpened.customerName}</strong>
          {formOpened.type === Type.FGTS
            ? '! Para contratar o emprétimo FGTS você precisa concluir a assinatura digital.'
            : '. Você possui propostas aguardando sua assinatura.'}
        </>
      }
    />
  );
};
