import styled, { css } from 'styled-components';

interface propsContentScreensError {
  heightCalc?: any;
  removePadding?: any;
}

export const ContentScreensError = styled.div<propsContentScreensError>`
  padding: 0 58px;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;

  svg {
    width: 100%;
    max-width: 240px;
  }
  &.paddingSides {
    padding-left: 40px;
    padding-right: 40px;
  }

  ${(heightCalc) =>
    heightCalc &&
    css`
      height: calc(100% - 106px);
    `}

  ${(removePadding) =>
    removePadding &&
    css`
      padding: 0;
    `}
`;

export const TitleError = styled.h2`
  font-size: 18px;
  line-height: 27px;
  color: var(--color-black-primary);
  font-family: 'Clan Web Pro Bold';
  margin-top: 8px;
  margin-bottom: 53px;
`;
export const TextError = styled.p`
  font-size: 16px;
  line-height: 24px;
  color: var(--color-gray-100);
  &.errorSignatureText {
    padding: 0 47px;
  }
`;

// ErrorSignature
export const ContentScreensErrorPermissions = styled.div`
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
`;
export const TitlePermissions = styled.h2`
  font-size: 16px;
  line-height: 24px;
  color: var(--color-black-primary);
`;
export const CardPermissions = styled.div`
  font-size: 18px;
  line-height: 25px;
  color: var(--color-black-primary);
  flex-direction: column;
  background-color: var(--color-white-primary);
  padding: 32px 24px;
  border-radius: 12px;
  margin-top: 40px;
  align-items: center;
`;
export const CardTitle = styled.h4`
  font-size: 20px;
  line-height: 148%;
  color: var(--color-black-primary);
  font-family: 'Clan Web Pro Medium';
  margin-top: 12px;
`;
export const CardText = styled.p`
  font-size: 18px;
  line-height: 25px;
  color: var(--color-black-primary);
  margin-top: 16px;
  margin-bottom: 24px;
`;

export const CardFooter = styled.div`
  align-items: center;
  svg {
    max-width: inherit;
    width: inherit;
  }
`;

export const CardFooterText = styled.a`
  font-size: 16px;
  line-height: 148%;
  color: var(--color-blue-primary);
  margin-left: 8px;
  text-decoration: none;
`;
