import React from 'react';

export const IconAdd = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.0609 9.01017C14.6132 9.01017 15.0609 8.56246 15.0609 8.01018L15.0609 7.98987C15.0609 7.43758 14.6132 6.98987 14.0609 6.98987L11.0101 6.98987C9.90558 6.98987 9.01015 6.09444 9.01015 4.98987L9.01015 1.93911C9.01015 1.38682 8.56243 0.939109 8.01015 0.939109L7.98985 0.939109C7.43756 0.939109 6.98985 1.38682 6.98985 1.93911L6.98985 4.98987C6.98985 6.09444 6.09441 6.98987 4.98985 6.98987L1.93909 6.98987C1.3868 6.98987 0.939088 7.43758 0.939088 7.98987L0.939088 8.01017C0.939088 8.56246 1.3868 9.01017 1.93909 9.01017L4.98985 9.01017C6.09442 9.01017 6.98985 9.9056 6.98985 11.0102L6.98985 14.0609C6.98985 14.6132 7.43756 15.0609 7.98985 15.0609H8.01015C8.56244 15.0609 9.01015 14.6132 9.01015 14.0609L9.01015 11.0102C9.01015 9.9056 9.90558 9.01017 11.0102 9.01017L14.0609 9.01017Z"
        fill="white"
      />
    </svg>
  );
};

export const IconAlert = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect opacity="0.6" x="5" y="5" width="22" height="22" rx="11" stroke="#8E8E8E" strokeWidth="2" />
      <rect x="15" y="10" width="2" height="8" rx="1" fill="#8E8E8E" />
      <rect x="15" y="20" width="2" height="2" rx="1" fill="#8E8E8E" />
    </svg>
  );
};

export const IconWarning = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="2" y="2" width="28" height="28" rx="14" fill="#FF7C03" />
      <path
        d="M16 10C15.4477 10 15 10.4477 15 11V17C15 17.5523 15.4477 18 16 18C16.5523 18 17 17.5523 17 17V11C17 10.4477 16.5523 10 16 10Z"
        fill="white"
      />
      <path
        d="M16 20C15.4477 20 15 20.4477 15 21C15 21.5523 15.4477 22 16 22C16.5523 22 17 21.5523 17 21C17 20.4477 16.5523 20 16 20Z"
        fill="white"
      />
    </svg>
  );
};

export const IconWarningSmall = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="16" height="16" rx="8" fill="#FF7C03" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 3.5C7.44772 3.5 7 3.94772 7 4.5V8.5C7 9.05228 7.44772 9.5 8 9.5C8.55228 9.5 9 9.05228 9 8.5V4.5C9 3.94772 8.55228 3.5 8 3.5ZM8 10.5C7.44772 10.5 7 10.9477 7 11.5C7 12.0523 7.44772 12.5 8 12.5C8.55228 12.5 9 12.0523 9 11.5C9 10.9477 8.55228 10.5 8 10.5Z"
        fill="white"
      />
    </svg>
  );
};

export const IconSuccess = () => {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 14C0 6.26801 6.26801 0 14 0C21.732 0 28 6.26801 28 14C28 21.732 21.732 28 14 28C6.26801 28 0 21.732 0 14Z"
        fill="#0090FF"
      />
      <path
        d="M18.8494 11.9571C19.2399 11.5666 19.2399 10.9334 18.8494 10.5429C18.4589 10.1524 17.8257 10.1524 17.4352 10.5429L12.6424 15.3357L10.5567 13.3358C10.1662 12.9453 9.53303 12.9453 9.1425 13.3358C8.75198 13.7263 8.75198 14.3595 9.1425 14.75L11.9353 17.457C12.3258 17.8476 12.9589 17.8476 13.3495 17.457L18.8494 11.9571Z"
        fill="white"
      />
    </svg>
  );
};

export const IconSuccessSmall = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="16" height="16" rx="8" fill="#68B76C" />
      <path
        d="M11.3495 6.95711C11.74 6.56658 11.74 5.93342 11.3495 5.54289C10.959 5.15237 10.3258 5.15237 9.93527 5.54289L7.14244 8.33573L6.05679 7.33579C5.66627 6.94526 5.0331 6.94526 4.64258 7.33579C4.25205 7.72631 4.25205 8.35948 4.64258 8.75L6.43533 10.457C6.82586 10.8476 7.45902 10.8476 7.84955 10.457L11.3495 6.95711Z"
        fill="white"
      />
    </svg>
  );
};

export const ArrowRight = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        opacity="0.7"
        d="M21.8263 21.5634L25.2354 16.5634C25.4671 16.2236 25.4671 15.7766 25.2354 15.4368L21.8263 10.4368C21.5152 9.98045 20.8931 9.86275 20.4368 10.1739C19.9805 10.485 19.8628 11.1071 20.1739 11.5634L23.15 16L20.1739 20.4368C19.8628 20.8931 19.9805 21.5152 20.4368 21.8263C20.8931 22.1374 21.5152 22.0197 21.8263 21.5634Z"
        fill="#F6F6F6"
      />
      <path
        opacity="0.4"
        d="M15.8263 21.5634L19.2354 16.5634C19.4671 16.2236 19.4671 15.7766 19.2354 15.4368L15.8263 10.4368C15.5152 9.98045 14.8931 9.86275 14.4368 10.1739C13.9805 10.485 13.8628 11.1071 14.1739 11.5634L17.15 16L14.1739 20.4368C13.8628 20.8931 13.9805 21.5152 14.4368 21.8263C14.8931 22.1374 15.5152 22.0197 15.8263 21.5634Z"
        fill="#F6F6F6"
      />
      <path
        opacity="0.2"
        d="M9.82634 21.5634L13.2354 16.5634C13.4671 16.2236 13.4671 15.7766 13.2354 15.4368L9.82634 10.4368C9.51522 9.98045 8.89309 9.86275 8.43678 10.1739C7.98047 10.485 7.86277 11.1071 8.17389 11.5634L11.15 16L8.17389 20.4368C7.86277 20.8931 7.98047 21.5152 8.43678 21.8263C8.89309 22.1374 9.51522 22.0197 9.82634 21.5634Z"
        fill="#F6F6F6"
      />
      <path
        opacity="0.1"
        d="M3.82634 21.5634L7.23544 16.5634C7.46713 16.2236 7.46713 15.7766 7.23544 15.4368L3.82634 10.4368C3.51522 9.98045 2.89309 9.86275 2.43678 10.1739C1.98047 10.485 1.86277 11.1071 2.17389 11.5634L5.15 16L2.17389 20.4368C1.86277 20.8931 1.98047 21.5152 2.43678 21.8263C2.89309 22.1374 3.51522 22.0197 3.82634 21.5634Z"
        fill="#F6F6F6"
      />
      <path
        d="M27.8263 21.5634L31.2354 16.5634C31.4671 16.2236 31.4671 15.7766 31.2354 15.4368L27.8263 10.4368C27.5152 9.98045 26.8931 9.86275 26.4368 10.1739C25.9805 10.485 25.8628 11.1071 26.1739 11.5634L29.15 16L26.1739 20.4368C25.8628 20.8931 25.9805 21.5152 26.4368 21.8263C26.8931 22.1374 27.5152 22.0197 27.8263 21.5634Z"
        fill="#F6F6F6"
      />
    </svg>
  );
};

export const IdentificationSvg = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5 4C5 2.89543 5.89543 2 7 2H19.7574C20.2878 2 20.7965 2.21071 21.1716 2.58579L26.4142 7.82843C26.7893 8.2035 27 8.71221 27 9.24264V28C27 29.1046 26.1046 30 25 30H7C5.89543 30 5 29.1046 5 28V4Z"
        stroke="#0090FF"
        strokeWidth="2"
      />
      <g opacity="0.5">
        <path
          d="M18.4993 12.5C18.4993 14.7477 16.847 15.7 15.9993 15.7C15.1516 15.7 13.4993 14.7477 13.4993 12.5C13.4993 11.1193 14.6186 10 15.9993 10C17.38 10 18.4993 11.1193 18.4993 12.5Z"
          stroke="#0090FF"
          strokeWidth="2"
        />
        <path
          d="M10 22.3498C10 21.0827 10.7781 20.0097 11.9079 19.6994C13.2591 19.3284 14.8088 19 16 19C17.1912 19 18.7409 19.3284 20.0921 19.6994C21.2219 20.0097 22 21.0827 22 22.3498V23.3L10 23.3L10 22.3498Z"
          stroke="#0090FF"
          strokeWidth="2"
        />
      </g>
    </svg>
  );
};

export const MaritalStatusSvg = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5 4C5 2.89543 5.89543 2 7 2H19.7574C20.2878 2 20.7965 2.21071 21.1716 2.58579L26.4142 7.82843C26.7893 8.2035 27 8.71221 27 9.24264V28C27 29.1046 26.1046 30 25 30H7C5.89543 30 5 29.1046 5 28V4Z"
        stroke="#0090FF"
        strokeWidth="2"
      />
      <rect opacity="0.5" x="10" y="19" width="9" height="2" rx="1" fill="#0090FF" />
      <rect opacity="0.5" x="10" y="23" width="12" height="2" rx="1" fill="#0090FF" />
      <path
        opacity="0.5"
        d="M13.4603 13.4603L11.5 11.5C11.2239 11.2239 11.2239 10.7761 11.5 10.5C11.7761 10.2239 12.2239 10.2239 12.5 10.5L13.4308 11.4308C13.469 11.469 13.531 11.469 13.5692 11.4308L14.5 10.5C14.7761 10.2239 15.2239 10.2239 15.5 10.5C15.7761 10.7761 15.7761 11.2239 15.5 11.5L13.5397 13.4603C13.5178 13.4822 13.4822 13.4822 13.4603 13.4603Z"
        stroke="#0090FF"
        strokeWidth="2"
      />
    </svg>
  );
};

export const BankAddressSvg = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5 4C5 2.89543 5.89543 2 7 2H19.7574C20.2878 2 20.7965 2.21071 21.1716 2.58579L26.4142 7.82843C26.7893 8.2035 27 8.71221 27 9.24264V28C27 29.1046 26.1046 30 25 30H7C5.89543 30 5 29.1046 5 28V4Z"
        stroke="#0090FF"
        strokeWidth="2"
      />
      <rect opacity="0.5" x="10" y="19" width="9" height="2" rx="1" fill="#0090FF" />
      <rect opacity="0.5" x="10" y="23" width="12" height="2" rx="1" fill="#0090FF" />
      <g opacity="0.5">
        <path
          d="M11.5 14C10.9477 14 10.5 14.4477 10.5 15C10.5 15.5523 10.9477 16 11.5 16L11.5 14ZM14.2348 10C14.787 10 15.2348 9.55228 15.2348 9C15.2348 8.44771 14.787 8 14.2348 8L14.2348 10ZM13.5076 15L13.5076 16L13.5076 15ZM14.2348 8L12.5076 8L12.5076 10L14.2348 10L14.2348 8ZM12.5076 13.0152L13.5076 13.0152L13.5076 11.0152L12.5076 11.0152L12.5076 13.0152ZM13.5076 14L11.5 14L11.5 16L13.5076 16L13.5076 14ZM14 13.5076C14 13.7795 13.7795 14 13.5076 14L13.5076 16C14.8841 16 16 14.8841 16 13.5076L14 13.5076ZM13.5076 13.0152C13.7796 13.0152 14 13.2357 14 13.5076L16 13.5076C16 12.1311 14.8841 11.0152 13.5076 11.0152L13.5076 13.0152ZM10 10.5076C10 11.8925 11.1227 13.0152 12.5076 13.0152L12.5076 11.0152C12.2273 11.0152 12 10.7879 12 10.5076L10 10.5076ZM12.5076 8C11.1227 8 10 9.12269 10 10.5076L12 10.5076C12 10.2273 12.2273 10 12.5076 10L12.5076 8Z"
          fill="#0090FF"
        />
        <path d="M12 8C12 7.44772 12.4477 7 13 7C13.5523 7 14 7.44772 14 8V9H12V8Z" fill="#0090FF" />
        <path d="M12 15H14V16C14 16.5523 13.5523 17 13 17C12.4477 17 12 16.5523 12 16V15Z" fill="#0090FF" />
      </g>
    </svg>
  );
};

export const ResidenceSvg = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5 4C5 2.89543 5.89543 2 7 2H19.7574C20.2878 2 20.7965 2.21071 21.1716 2.58579L26.4142 7.82843C26.7893 8.2035 27 8.71221 27 9.24264V28C27 29.1046 26.1046 30 25 30H7C5.89543 30 5 29.1046 5 28V4Z"
        stroke="#0090FF"
        strokeWidth="2"
      />
      <rect opacity="0.5" x="10" y="19" width="9" height="2" rx="1" fill="#0090FF" />
      <rect opacity="0.5" x="10" y="23" width="12" height="2" rx="1" fill="#0090FF" />
      <path
        opacity="0.5"
        d="M13.5 14H11V11L13.5 9L16 11V14H13.5Z"
        stroke="#0090FF"
        strokeWidth="2"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const OthersSvg = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5 4C5 2.89543 5.89543 2 7 2H19.7574C20.2878 2 20.7965 2.21071 21.1716 2.58579L26.4142 7.82843C26.7893 8.2035 27 8.71221 27 9.24264V28C27 29.1046 26.1046 30 25 30H7C5.89543 30 5 29.1046 5 28V4Z"
        stroke="#0090FF"
        strokeWidth="2"
      />
      <path d="M20 2V6C20 7.65685 21.3431 9 23 9H27" stroke="#0090FF" strokeWidth="2" />
      <rect opacity="0.5" x="10" y="19" width="9" height="2" rx="1" fill="#0090FF" />
      <rect opacity="0.5" x="10" y="23" width="12" height="2" rx="1" fill="#0090FF" />
    </svg>
  );
};

export const DisclaimerSvg = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="16" height="16" rx="8" fill="#0090FF" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 3.5C7.44772 3.5 7 3.94772 7 4.5V8.5C7 9.05228 7.44772 9.5 8 9.5C8.55228 9.5 9 9.05228 9 8.5V4.5C9 3.94772 8.55228 3.5 8 3.5ZM8 10.5C7.44772 10.5 7 10.9477 7 11.5C7 12.0523 7.44772 12.5 8 12.5C8.55228 12.5 9 12.0523 9 11.5C9 10.9477 8.55228 10.5 8 10.5Z"
        fill="white"
      />
    </svg>
  );
};

export const IconError = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect opacity="0.6" x="5" y="5" width="22" height="22" rx="11" stroke="#FF7C03" strokeWidth="2" />
      <rect x="15" y="10" width="2" height="8" rx="1" fill="#FF7C03" />
      <rect x="15" y="20" width="2" height="2" rx="1" fill="#FF7C03" />
    </svg>
  );
};

export const IconPerson = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16 6C16 9.44771 13.4477 11 12 11C10.5523 11 8 9.44771 8 6C8 3.79086 9.79086 2 12 2C14.2091 2 16 3.79086 16 6Z"
        stroke="#0090FF"
        strokeWidth="2"
      />
      <path
        d="M3 18.0248C3 16.7639 3.77608 15.681 4.92023 15.337C7.01957 14.7058 9.86554 14 12 14C14.1345 14 16.9804 14.7058 19.0798 15.337C20.2239 15.681 21 16.7639 21 18.0248V21H3V18.0248Z"
        stroke="#0090FF"
        strokeWidth="2"
      />
    </svg>
  );
};

export const IconDelete = () => {
  return (
    <svg width="15" height="16" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.44444 12C5.65072 12 5.84855 11.921 5.99442 11.7803C6.14028 11.6397 6.22222 11.4489 6.22222 11.25V6.75C6.22222 6.55109 6.14028 6.36032 5.99442 6.21967C5.84855 6.07902 5.65072 6 5.44444 6C5.23816 6 5.04033 6.07902 4.89447 6.21967C4.74861 6.36032 4.66667 6.55109 4.66667 6.75V11.25C4.66667 11.4489 4.74861 11.6397 4.89447 11.7803C5.04033 11.921 5.23816 12 5.44444 12ZM13.2222 3H10.1111V2.25C10.1111 1.65326 9.86528 1.08097 9.42769 0.65901C8.99011 0.237053 8.39662 0 7.77778 0H6.22222C5.60338 0 5.00989 0.237053 4.57231 0.65901C4.13472 1.08097 3.88889 1.65326 3.88889 2.25V3H0.777778C0.571498 3 0.373667 3.07902 0.227806 3.21967C0.0819442 3.36032 0 3.55109 0 3.75C0 3.94891 0.0819442 4.13968 0.227806 4.28033C0.373667 4.42098 0.571498 4.5 0.777778 4.5H1.55556V12.75C1.55556 13.3467 1.80139 13.919 2.23897 14.341C2.67656 14.7629 3.27005 15 3.88889 15H10.1111C10.7299 15 11.3234 14.7629 11.761 14.341C12.1986 13.919 12.4444 13.3467 12.4444 12.75V4.5H13.2222C13.4285 4.5 13.6263 4.42098 13.7722 4.28033C13.9181 4.13968 14 3.94891 14 3.75C14 3.55109 13.9181 3.36032 13.7722 3.21967C13.6263 3.07902 13.4285 3 13.2222 3ZM5.44444 2.25C5.44444 2.05109 5.52639 1.86032 5.67225 1.71967C5.81811 1.57902 6.01594 1.5 6.22222 1.5H7.77778C7.98406 1.5 8.18189 1.57902 8.32775 1.71967C8.47361 1.86032 8.55556 2.05109 8.55556 2.25V3H5.44444V2.25ZM10.8889 12.75C10.8889 12.9489 10.8069 13.1397 10.6611 13.2803C10.5152 13.421 10.3174 13.5 10.1111 13.5H3.88889C3.68261 13.5 3.48478 13.421 3.33892 13.2803C3.19306 13.1397 3.11111 12.9489 3.11111 12.75V4.5H10.8889V12.75ZM8.55556 12C8.76184 12 8.95967 11.921 9.10553 11.7803C9.25139 11.6397 9.33333 11.4489 9.33333 11.25V6.75C9.33333 6.55109 9.25139 6.36032 9.10553 6.21967C8.95967 6.07902 8.76184 6 8.55556 6C8.34928 6 8.15145 6.07902 8.00558 6.21967C7.85972 6.36032 7.77778 6.55109 7.77778 6.75V11.25C7.77778 11.4489 7.85972 11.6397 8.00558 11.7803C8.15145 11.921 8.34928 12 8.55556 12Z"
        fill="#D02931"
      />
    </svg>
  );
};

export const GrayTrash = () => {
  return (
    <svg width="14" height="17" viewBox="0 0 14 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="3.5" width="13" height="1" rx="0.5" stroke="#C2C2C2" />
      <path
        d="M4.5 2C4.5 1.44772 4.94772 1 5.5 1H8.5C9.05228 1 9.5 1.44772 9.5 2V4H4.5V2Z"
        stroke="#C2C2C2"
        strokeWidth="2"
      />
      <path
        d="M2 4H12V14C12 15.1046 11.1046 16 10 16H4C2.89543 16 2 15.1046 2 14V4Z"
        stroke="#C2C2C2"
        strokeWidth="2"
      />
      <rect x="4.5" y="7.5" width="2" height="5" rx="1" fill="#C2C2C2" />
      <rect x="7.5" y="7.5" width="2" height="5" rx="1" fill="#C2C2C2" />
    </svg>
  );
};

export const AddBlack = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="12" cy="12" r="12" fill="#101010" />
      <path
        d="M13.0109 7.93908C13.0109 7.3868 12.5632 6.93908 12.0109 6.93908L11.9906 6.93908C11.4383 6.93908 10.9906 7.3868 10.9906 7.93908L10.9906 9.98985C10.9906 10.5421 10.5429 10.9898 9.9906 10.9898L7.93984 10.9898C7.38756 10.9898 6.93984 11.4376 6.93984 11.9898V12.0102C6.93984 12.5624 7.38756 13.0102 7.93984 13.0102H9.9906C10.5429 13.0102 10.9906 13.4579 10.9906 14.0102L10.9906 16.0609C10.9906 16.6132 11.4383 17.0609 11.9906 17.0609L12.0109 17.0609C12.5632 17.0609 13.0109 16.6132 13.0109 16.0609L13.0109 14.0102C13.0109 13.4579 13.4586 13.0102 14.0109 13.0102L16.0617 13.0102C16.614 13.0102 17.0617 12.5624 17.0617 12.0102V11.9898C17.0617 11.4376 16.614 10.9898 16.0617 10.9898L14.0109 10.9898C13.4586 10.9898 13.0109 10.5421 13.0109 9.98985L13.0109 7.93908Z"
        fill="white"
      />
    </svg>
  );
};

export const IconAlertO = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="2" y="2" width="28" height="28" rx="14" fill="#FF7C03" />
    <path
      d="M16 10C15.4477 10 15 10.4477 15 11V17C15 17.5523 15.4477 18 16 18C16.5523 18 17 17.5523 17 17V11C17 10.4477 16.5523 10 16 10Z"
      fill="white"
    />
    <path
      d="M16 20C15.4477 20 15 20.4477 15 21C15 21.5523 15.4477 22 16 22C16.5523 22 17 21.5523 17 21C17 20.4477 16.5523 20 16 20Z"
      fill="white"
    />
  </svg>
);

export const IconClose = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2 16C2 8.26801 8.26801 2 16 2C23.732 2 30 8.26801 30 16C30 23.732 23.732 30 16 30C8.26801 30 2 23.732 2 16Z"
      fill="#B339C5"
    />
    <path
      d="M21.2929 12.1357C21.6834 11.7452 21.6834 11.112 21.2929 10.7215L21.2785 10.7071C20.888 10.3166 20.2548 10.3166 19.8643 10.7071L17.4142 13.1572C16.6332 13.9383 15.3668 13.9383 14.5858 13.1572L12.1357 10.7071C11.7452 10.3166 11.112 10.3166 10.7215 10.7071L10.7071 10.7215C10.3166 11.112 10.3166 11.7452 10.7071 12.1357L13.1572 14.5858C13.9383 15.3668 13.9383 16.6332 13.1572 17.4142L10.7071 19.8643C10.3166 20.2548 10.3166 20.888 10.7071 21.2785L10.7215 21.2929C11.112 21.6834 11.7452 21.6834 12.1357 21.2929L14.5858 18.8428C15.3668 18.0617 16.6332 18.0617 17.4142 18.8428L19.8643 21.2929C20.2548 21.6834 20.888 21.6834 21.2785 21.2929L21.2929 21.2785C21.6834 20.888 21.6834 20.2548 21.2929 19.8643L18.8428 17.4142C18.0617 16.6332 18.0617 15.3668 18.8428 14.5858L21.2929 12.1357Z"
      fill="white"
    />
  </svg>
);

export const IconPlus = () => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="12.5" cy="12" r="12" fill="#101010" />
      <path
        d="M13.5109 7.93908C13.5109 7.3868 13.0632 6.93908 12.5109 6.93908L12.4906 6.93908C11.9383 6.93908 11.4906 7.3868 11.4906 7.93908L11.4906 9.98985C11.4906 10.5421 11.0429 10.9898 10.4906 10.9898L8.43984 10.9898C7.88756 10.9898 7.43984 11.4376 7.43984 11.9898V12.0102C7.43984 12.5624 7.88756 13.0102 8.43984 13.0102H10.4906C11.0429 13.0102 11.4906 13.4579 11.4906 14.0102L11.4906 16.0609C11.4906 16.6132 11.9383 17.0609 12.4906 17.0609L12.5109 17.0609C13.0632 17.0609 13.5109 16.6132 13.5109 16.0609L13.5109 14.0102C13.5109 13.4579 13.9586 13.0102 14.5109 13.0102L16.5617 13.0102C17.114 13.0102 17.5617 12.5624 17.5617 12.0102V11.9898C17.5617 11.4376 17.114 10.9898 16.5617 10.9898L14.5109 10.9898C13.9586 10.9898 13.5109 10.5421 13.5109 9.98985L13.5109 7.93908Z"
        fill="white"
      />
    </svg>
  );
};
