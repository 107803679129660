import React from 'react';

// COMPONENTS
import { Button } from '../../../UI/Button';

// STYLES
import { ActionButtons, ModalContainer, ModalContent, ModalTitle, ModalSubtitle } from '../styles';

// SVGS
import { PendingDocument } from '../svgs';

import { useNavigate } from 'react-router-dom';

interface IModalErrorProposalInconsistent {
  onClose: any;
}

const ModalPendingDocument = ({ onClose }: IModalErrorProposalInconsistent) => {
  const navigate = useNavigate();

  return (
    <ModalContent>
      <ModalContainer>
        <PendingDocument />
        <ModalTitle>Documentação pendente</ModalTitle>
      </ModalContainer>
      <ModalSubtitle>
        A proposta já foi enviada, mas está com pendência de documentação. Acesse a área de envio de documentos para
        corrigir.
      </ModalSubtitle>
      <ActionButtons className="left">
        <Button onClick={onClose} big transparent>
          Cancelar
        </Button>
        <Button onClick={() => navigate('/manage-proposal')} big black>
          Revisar documentação
        </Button>
      </ActionButtons>
    </ModalContent>
  );
};

export default ModalPendingDocument;
