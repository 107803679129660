import React, { ReactNode } from 'react';

import { AuthProvider } from './auth';
import { ProposalProvider } from './AppContext';
import { SignatureProvider } from './SignatureContext';

type AppProviderProps = {
  children: ReactNode;
};

const AppProvider: React.FC<AppProviderProps> = ({ children }) => (
  <AuthProvider>
    <ProposalProvider>
      <SignatureProvider>{children}</SignatureProvider>
    </ProposalProvider>
  </AuthProvider>
);

export { AppProvider };
