import React, { ReactElement } from 'react';

export const ArrowRight = (): ReactElement => {
  return (
    <svg width="23" height="12" viewBox="0 0 23 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.8263 11.5634L22.2354 6.56343C22.4671 6.22361 22.4671 5.77658 22.2354 5.43676L18.8263 0.43676C18.5152 -0.0195528 17.8931 -0.137253 17.4368 0.17387C16.9804 0.484992 16.8627 1.10712 17.1739 1.56343L19.517 5H1C0.447715 5 0 5.44772 0 6C0 6.55228 0.447715 7 1 7H19.5171L17.1739 10.4368C16.8627 10.8931 16.9804 11.5152 17.4368 11.8263C17.8931 12.1374 18.5152 12.0197 18.8263 11.5634Z"
        fill="white"
      />
    </svg>
  );
};

export const ArrowLeft = (): ReactElement => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="#000000" xmlns="http://www.w3.org/2000/svg">
      <path
        opacity="0.7"
        d="M11.5828 10.4368L8.17374 15.4368C7.94205 15.7766 7.94205 16.2237 8.17374 16.5635L11.5828 21.5635C11.894 22.0198 12.5161 22.1375 12.9724 21.8264C13.4287 21.5153 13.5464 20.8931 13.2353 20.4368L10.2592 16.0002L13.2353 11.5635C13.5464 11.1072 13.4287 10.485 12.9724 10.1739C12.5161 9.8628 11.894 9.9805 11.5828 10.4368Z"
      />
      <path
        opacity="0.4"
        d="M17.5828 10.4368L14.1737 15.4368C13.942 15.7766 13.942 16.2237 14.1737 16.5635L17.5828 21.5635C17.894 22.0198 18.5161 22.1375 18.9724 21.8264C19.4287 21.5153 19.5464 20.8931 19.2353 20.4368L16.2592 16.0002L19.2353 11.5635C19.5464 11.1072 19.4287 10.485 18.9724 10.1739C18.5161 9.8628 17.894 9.9805 17.5828 10.4368Z"
      />
      <path
        opacity="0.2"
        d="M23.5828 10.4368L20.1737 15.4368C19.942 15.7766 19.942 16.2237 20.1737 16.5635L23.5828 21.5635C23.894 22.0198 24.5161 22.1375 24.9724 21.8264C25.4287 21.5153 25.5464 20.8931 25.2353 20.4368L22.2592 16.0002L25.2353 11.5635C25.5464 11.1072 25.4287 10.485 24.9724 10.1739C24.5161 9.8628 23.894 9.9805 23.5828 10.4368Z"
      />
      <path
        opacity="0.1"
        d="M29.5828 10.4368L26.1737 15.4368C25.942 15.7766 25.942 16.2237 26.1737 16.5635L29.5828 21.5635C29.894 22.0198 30.5161 22.1375 30.9724 21.8264C31.4287 21.5153 31.5464 20.8931 31.2353 20.4368L28.2592 16.0002L31.2353 11.5635C31.5464 11.1072 31.4287 10.485 30.9724 10.1739C30.5161 9.8628 29.894 9.9805 29.5828 10.4368Z"
      />
      <path d="M5.58284 10.4368L2.17374 15.4368C1.94205 15.7766 1.94205 16.2237 2.17374 16.5635L5.58284 21.5635C5.89396 22.0198 6.51609 22.1375 6.9724 21.8264C7.42871 21.5153 7.54641 20.8931 7.23529 20.4368L4.25918 16.0002L7.23529 11.5635C7.54641 11.1072 7.42871 10.485 6.9724 10.1739C6.51609 9.8628 5.89396 9.9805 5.58284 10.4368Z" />
    </svg>
  );
};

export const Refresh = (): ReactElement => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19 9.00146C18.7348 9.00146 18.4804 9.10682 18.2929 9.29436C18.1054 9.48189 18 9.73625 18 10.0015C18.0119 11.8745 17.3703 13.693 16.1857 15.1439C15.001 16.5948 13.3475 17.5871 11.51 17.9501C9.67239 18.313 7.76578 18.0238 6.11851 17.1322C4.47123 16.2407 3.18641 14.8026 2.48533 13.0657C1.78425 11.3288 1.71079 9.40178 2.27762 7.61654C2.84445 5.8313 4.01607 4.2996 5.5907 3.28524C7.16533 2.27089 9.0444 1.83738 10.9043 2.05938C12.7641 2.28137 14.4884 3.14498 15.78 4.50146H13.38C13.1148 4.50146 12.8604 4.60682 12.6729 4.79436C12.4854 4.98189 12.38 5.23625 12.38 5.50146C12.38 5.76668 12.4854 6.02103 12.6729 6.20857C12.8604 6.39611 13.1148 6.50146 13.38 6.50146H17.91C18.1752 6.50146 18.4296 6.39611 18.6171 6.20857C18.8046 6.02103 18.91 5.76668 18.91 5.50146V1.00146C18.91 0.736248 18.8046 0.481894 18.6171 0.294358C18.4296 0.106821 18.1752 0.00146455 17.91 0.00146455C17.6448 0.00146455 17.3904 0.106821 17.2029 0.294358C17.0154 0.481894 16.91 0.736248 16.91 1.00146V2.77146C15.2447 1.17952 13.0808 0.211417 10.784 0.0307936C8.48729 -0.14983 6.19863 0.468109 4.30492 1.78015C2.41121 3.0922 1.02861 5.01787 0.390831 7.23165C-0.246949 9.44544 -0.100787 11.8115 0.804611 13.93C1.71001 16.0484 3.31911 17.7893 5.3599 18.8583C7.40069 19.9273 9.748 20.2589 12.005 19.7969C14.2621 19.335 16.2904 18.1079 17.7471 16.3231C19.2039 14.5383 19.9997 12.3053 20 10.0015C20 9.73625 19.8946 9.48189 19.7071 9.29436C19.5196 9.10682 19.2652 9.00146 19 9.00146Z"
        fill="white"
      />
    </svg>
  );
};
