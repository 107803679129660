import React from 'react';

// COMPONENTS
import { Button } from '../../Button';

// STYLES
import { ActionButtons, ModalContainer, ModalContent, ModalTitle, ModalSubtitle } from '../styles';

interface IModalPurchaseMargin {
  onClose: any;
  onConfirm: any;
}

const ModalPurchaseMargin = ({ onClose, onConfirm }: IModalPurchaseMargin) => {
  return (
    <ModalContent>
      <ModalContainer>
        <ModalTitle>Confirmar dados</ModalTitle>
      </ModalContainer>
      <ModalSubtitle>Você não cadastrou limite para compras em nenhuma proposta. Deseja prosseguir?</ModalSubtitle>
      <ActionButtons className="left">
        <Button onClick={onClose} big>
          Cancelar
        </Button>
        <Button onClick={onConfirm} big black>
          Sim
        </Button>
      </ActionButtons>
    </ModalContent>
  );
};

export default ModalPurchaseMargin;
