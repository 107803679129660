import styled from 'styled-components';

export const Container = styled.div`
  height: calc(100vh - 80px);
  overflow: auto;
`;

export const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: start;
  align-items: flex-start;
  flex: 1;
  padding-left: 40px;
  padding-top: 88px;
`;
export const Title = styled.h1`
  font-family: 'Clan Web Pro Book';
  font-size: 42px;
  line-height: 56px;
  color: #101010;
`;
export const Wrapper = styled.div`
  padding-right: 40px;
  max-width: 100%;
  margin-top: 73px;

  tr {
    cursor: unset;
  }
`;
