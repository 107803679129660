const verifyImgResolution = (file: any, limitResolution: number) => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = async (e) => {
      const response = new Promise((resolve) => {
        const image = new Image();
        // @ts-ignore
        image.src = e.target.result;
        image.onload = () => {
          const { height, width } = image;
          if (height < limitResolution || width < limitResolution) {
            resolve(false);
          }
          resolve(true);
        };
      });
      resolve(await response);
    };
  });
};

export default verifyImgResolution;
