import React, { useState } from 'react';
import { LogoSection, SideMenuContainer, ModalTestes, ModalTitle, ModalDescription, Logout } from './style';
import SideMenuItem from './SideMenuItem';
import { Logo, Rocket, IconDashboard, IconRegister, IconProposal, LogoutIcon } from './svgs';
import { useAuth } from '../../../hooks/auth';
import { useNavigate, useLocation } from 'react-router-dom';
import { useProposal } from '../../../hooks/AppContext';

const SideMenu = () => {
  // const [closed, setClosed] = useState(false)
  const { signOut } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const { clearForm } = useProposal();

  const items = [
    {
      title: 'Visão geral',
      active: location.pathname === '/dashboard' ? true : false,
      icon: IconDashboard,
      onClick: () => {
        clearForm();
        navigate('/dashboard');
      },
    },
    {
      title: 'Cadastro de proposta',
      active: location.pathname === '/proposal' ? true : false,
      icon: IconRegister,
      onClick: () => {
        clearForm();
        navigate('/proposal');
      },
    },
    {
      title: 'Gerenciar proposta',
      active: location.pathname === '/manage-proposal' ? true : false,
      icon: IconProposal,
      onClick: () => {
        clearForm();
        navigate('/manage-proposal');
      },
    },
  ];

  return (
    <SideMenuContainer>
      <div style={{ flexDirection: 'column' }}>
        <LogoSection>
          <Logo />
        </LogoSection>
        {items.map((it: any, idx) => {
          return <SideMenuItem key={idx} item={it} />;
        })}
      </div>

      <div style={{ flexDirection: 'column', paddingLeft: '16px', paddingRight: '20px' }}>
        {/* {!closed && (
          <ModalTestes>
            <Rocket />
            <ModalTitle>Estamos no período de testes.</ModalTitle>
            <ModalDescription>
              Se você encontrar algum problema ou tiver alguma sugestão,
              <a href="mailto:bariconsig@bancobari.com.br"> entre em contato</a>.
            </ModalDescription>
            <div className="closeModal" onClick={() => setClosed(true)}>
              <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="4" y="4" width="24" height="24" rx="12" fill="white" />
                <path
                  d="M21.2929 12.1357C21.6834 11.7452 21.6834 11.112 21.2929 10.7215L21.2785 10.7071C20.888 10.3166 20.2548 10.3166 19.8643 10.7071L17.4142 13.1572C16.6332 13.9383 15.3668 13.9383 14.5858 13.1572L12.1357 10.7071C11.7452 10.3166 11.112 10.3166 10.7215 10.7071L10.7071 10.7215C10.3166 11.112 10.3166 11.7452 10.7071 12.1357L13.1572 14.5858C13.9383 15.3668 13.9383 16.6332 13.1572 17.4142L10.7071 19.8643C10.3166 20.2548 10.3166 20.888 10.7071 21.2785L10.7215 21.2929C11.112 21.6834 11.7452 21.6834 12.1357 21.2929L14.5858 18.8428C15.3668 18.0617 16.6332 18.0617 17.4142 18.8428L19.8643 21.2929C20.2548 21.6834 20.888 21.6834 21.2785 21.2929L21.2929 21.2785C21.6834 20.888 21.6834 20.2548 21.2929 19.8643L18.8428 17.4142C18.0617 16.6332 18.0617 15.3668 18.8428 14.5858L21.2929 12.1357Z"
                  fill="#171A1C"
                />
              </svg>
            </div>
          </ModalTestes>
        )} */}
        <Logout onClick={signOut}>
          <LogoutIcon /> <span>Sair</span>
        </Logout>
      </div>
    </SideMenuContainer>
  );
};

export default SideMenu;
