import FeatureFlagResource from '../resource/FeatureFlagResource';

export default class FeatureFlagService {
  static fetchFlag = async () => {
    return FeatureFlagResource.fetchFlag()
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  };
}
