import styled, { css } from 'styled-components';

interface propsButton {
  disabled?: any;
}

export const CardSelectContent = styled.div`
  display: block;
`;

export const StyledCardSelect = styled.label<propsButton>`
  position: relative;
  flex-direction: column;
  justify-content: flex-start;
  border-radius: 12px;
  background-color: white;
  color: black;
  border: 1px solid #e5e5e5;
  padding: 40px 32px;
  width: 100%;
  max-width: 208px;
  min-height: 272px;
  cursor: pointer;

  & + label {
    margin-left: 40px;
  }

  ${(props: any) =>
    props.disabled &&
    css`
      pointer-events: none;
      opacity: 0.5;
    `}

  ${(props: any) =>
    props.selected &&
    css`
      background: #f7fcff;
      border: 2px solid #0090ff;
    `}
`;

export const IconContainer = styled.div`
  background: #e6f5fe;
  border-radius: 8px;
  width: max-content;
`;

export const StyledCardHeader = styled.div`
  position: absolute;
  top: 12px;
  right: 12px;

  input[type='radio'] {
    position: relative;
    cursor: pointer;
    appearance: none;
  }

  input[type='radio']:before {
    content: '';
    display: block;
    position: absolute;
    width: 22px;
    height: 22px;
    top: 0;
    right: 0;
    border-radius: 50%;

    background: white;
  }
  input[type='radio']:hover:before {
    border: 3px solid #e5e5e5;
  }
  input[type='radio']:checked:before {
    border: 3px solid #0090ff;
  }

  input[type='radio']:checked:after {
    content: '';
    display: block;
    position: absolute;
    width: 14px;
    height: 14px;
    top: 7px;
    right: 7px;
    background: #0090ff;
    border-radius: 50%;
  }
`;

export const StyledTitleCard = styled.h2`
  font-size: 13px;
  line-height: 24px;
  font-family: 'Clan Web Pro Medium';
  color: var(--color-black10);
  margin-top: 24px;
  margin-bottom: 8px;
`;

export const StyledDescription = styled.p`
  color: #555555;
  margin-top: 0;
  font-family: 'Clan Web Pro News';
  line-height: 24px;
  font-size: 13px;
`;

export const StyledCheckbox = styled.input`
  /* position: absolute;
  right: 20px;
  top: 20px;
  transform: scale(2); */
  /* :hover {
    background-color: #0090ff;
  } */
`;
