import React from 'react';

/**
 * @author Matheus Vieira <matheus.vieira@deal.com.br>
 * @since 2020-11-23
 */
interface ComponentProps {
  condition: boolean;
  children: any;
}

const If = ({ condition, children }: ComponentProps) => {
  if (condition) {
    return <>{children}</>;
  }

  return <></>;
};

export default If;
