export enum Type {
  CONSIGNED = 'PAYROLL_LOAN',
  FGTS = 'FGTS_ANTICIPATION',
}

export enum LoanType {
  NEW_LOAN = 'NEW_LOAN',
  NEW_LOAN_PORTABILITY = 'NEW_LOAN_PORTABILITY',
  LOAN_REFINANCE_PORTABILITY = 'LOAN_REFINANCE_PORTABILITY',
  LOAN_REFINANCE = 'LOAN_REFINANCE',
}
