// @ts-nocheck
import React from 'react';
//STEPS
import { Product, Form, Document, Resume, Confirmation } from './index';

// INTERFACE
import Proposal from '../../../interfaces/Proposal';

interface IStepDisplay {
  currentStep: number;
  formValues: Proposal;
  onChange: (e: any) => void;
}

const StepDisplay = ({ currentStep, formValues, onChange }: IStepDisplay) => {
  switch (currentStep) {
    case 1:
      return <Product onChange={onChange} formValues={formValues} />;
    case 2:
      return <Form onChange={onChange} formValues={formValues} />;
    case 3:
      return <Document />;
    case 4:
      return <Resume formValues={formValues} />;
    case 5:
      return <Confirmation />;
    default:
      return null;
  }
};

export default StepDisplay;
