export enum FileType {
  PERSONAL_IDENTIFICATION = 'PERSONAL_IDENTIFICATION',
  PERSONAL_IDENTIFICATION_BACK = 'PERSONAL_IDENTIFICATION_BACK',
  RESIDENCE = 'RESIDENCE',
  PAYROLL = 'PAYROLL',
  OTHER_ONE = 'OTHER_ONE',
  OTHER_TWO = 'OTHER_TWO',
  OTHER_THREE = 'OTHER_THREE',
  OTHER_FOUR = 'OTHER_FOUR',
}
