import styled from 'styled-components';

import Img from '../../../assets/img-signature/background.jpg';

interface propsContent {
  fullHeight?: any;
}
interface propsBoxText {
  paddingBoxBottom?: any;
  alignCenter?: any;
}
interface propsTitleBox {
  marginBottomTitleBox?: any;
}
interface propsTextBox {
  textScreenFinish?: any;
}

export const ContentScreenDefault = styled.div<propsContent>`
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0) 90.86%), url(${Img});
  background-repeat: no-repeat;
  background-size: cover;
  flex-direction: column;
  padding: 64px 0 0 0;
  justify-content: space-between;
  align-items: center;
  height: calc(100% - 98px);
  width: 100%;
  ${(props: any) =>
    props.fullHeightContent &&
    `
    height: 100%;
     
  `}
`;
export const TitleScreenDefault = styled.h2`
  font-size: 18px;
  line-height: 23px;
  color: var(--color-white-primary);
  font-family: 'Clan Web Pro Bold';
  margin-bottom: 34px;
  text-align: center;
`;

export const ContentTextScreenDefault = styled.div`
  flex-direction: column;
`;

export const BoxTextScreenDefault = styled.div<propsBoxText>`
  padding: 34px 32px 0 32px;
  border-radius: 16px 16px 0px 0px;
  margin-top: -12px;
  background-color: var(--color-white-primary);
  width: 100%;
  flex-direction: column;
  svg {
    margin: 26px 0 32px 0;
  }
  ${(props: any) =>
    props.paddingBoxBottom &&
    `
    padding-bottom: 32px;
  `}
  ${(props: any) =>
    props.alignCenter &&
    `
    text-align: center;
    align-items: center;
  `}
`;
export const TitleBoxScreenDefault = styled.h2<propsTitleBox>`
  font-size: 18px;
  line-height: 170%;
  color: var(--color-black-primary);
  font-family: 'Clan Web Pro Bold';

  ${(props: any) =>
    props.marginBottomTitleBox &&
    `
    margin-bottom: 24px;
     
  `}
`;

export const TextBoxScreenDefault = styled.h4<propsTextBox>`
  font-size: 16px;
  line-height: 30px;
  color: var(--color-black-primary);
  font-family: 'Clan Web Pro Book';
  ${(props: any) =>
    props.textScreenFinish &&
    `
    font-size: 14px;
    line-height: 170%;
     
  `}
`;
