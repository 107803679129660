import React from 'react';

export const CheckMark = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <rect x="1" y="1" width="22" height="22" rx="11" stroke="#A1DDCA" />
      <path
        d="M16.2662 8.5C16.4558 8.5 16.6376 8.57292 16.7711 8.70257C16.917 8.83222 17 9.01471 17 9.20592C17 9.39712 16.917 9.57961 16.7711 9.70926L10.9686 15.2974C10.8351 15.4271 10.6534 15.5 10.4638 15.5C10.2742 15.5 10.0924 15.4271 9.95889 15.2974L7.22833 12.6677C7.03782 12.4948 6.95859 12.2366 7.02091 11.9917C7.08324 11.7469 7.27748 11.5532 7.52942 11.4848C7.78135 11.4163 8.05205 11.4836 8.23807 11.661L10.4638 13.8114L15.7613 8.70257C15.8949 8.57292 16.0766 8.5 16.2662 8.5Z"
        fill="#489D83"
        stroke="#489D83"
      />
    </svg>
  );
};

export default CheckMark;
