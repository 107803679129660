import styled from 'styled-components';

/**
 * @author Matheus Vieira <matheus.vieira@deal.com.br>
 * @since 2020-11-18
 */
export const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 14px 26px 14px 18px;
  width: 274px;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 8px;
  &.SearchTable {
    max-width: 200px;
    padding: 7px 26px 7px 18px;
  }
  &:not(.SearchTable) {
    width: 274px;
  }
`;

export const Input = styled.input`
  width: 100%;
  margin-left: 16px;
  border: 0;
  outline: 0;
  box-shadow: none;
  font-size: 14px;
  &:disabled {
    cursor: not-allowed;
    border-bottom: 1px solid #e5e5e5 !important;
    background-color: #f6f6f6;
    color: #8e8e8e;
  }
  &::placeholder {
    color: #c2c2c2;
  }
`;
