import React from 'react';

import {
  CardSelectContent,
  IconContainer,
  StyledCardHeader,
  StyledCardSelect,
  StyledCheckbox,
  StyledDescription,
  StyledTitleCard,
} from './styles';

interface CardSelectProps {
  title: string;
  description: string;
  value?: string;
  checked?: boolean;
  disabled?: any;
  type?: string;
  name?: string;
  onChange?: any;
  onClick?: any;
  id?: string;
  icon: React.ComponentType<{ size?: string | number; color?: string; title?: string; id?: string }>;
}

const CardSelect: React.FC<CardSelectProps> = ({
  icon: Icon,
  type = 'radio',
  value,
  name,
  checked,
  onChange,
  disabled,
  id,
  ...props
}) => {
  return (
    <StyledCardSelect {...props} id={id} {...{ selected: checked, disabled: disabled }}>
      <StyledCardHeader>
        <StyledCheckbox
          onChange={onChange}
          type={type}
          name={name}
          value={value}
          checked={checked}
          readOnly
        ></StyledCheckbox>
      </StyledCardHeader>
      <IconContainer id={id}>
        {/* @ts-ignore */}
        <Icon id={id} />
      </IconContainer>
      <CardSelectContent>
        <StyledTitleCard id={id}>{props.title}</StyledTitleCard>
        <StyledDescription id={id}>{props.description}</StyledDescription>
      </CardSelectContent>
    </StyledCardSelect>
  );
};

export default CardSelect;
