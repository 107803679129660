import Proposal from '../interfaces/Proposal';

export const hasError = (currentStep: number): any => {
  if (currentStep === 1) {
    return {};
  } else if (currentStep === 2) {
    return {
      name: false,
      documentNumber: false,
      phoneNumber: false,
      cnpjConsigneeId: false,
      loanDetails: false,
    };
  } else if (currentStep === 3) {
    return {};
  } else if (currentStep === 4) {
    return {};
  }
};

export const hasFilled = (currentStep: number, data: Proposal): any => {
  if (currentStep === 1) {
    return {};
  } else if (currentStep === 2) {
    return (
      data.name == null ||
      data.documentNumber == null ||
      !data.phoneNumber ||
      data.cnpjConsigneeId == null ||
      data.cnpjConsigneeId == '' ||
      data.loanDetails.length == 0
    );
  } else if (currentStep === 3) {
  } else if (currentStep === 4) {
    return {};
  }
};
