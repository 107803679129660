import React from 'react';

// COMPONENTS
import { Button } from '../../Button';

// STYLES
import { ActionButtons, ModalContainer, ModalContent, ModalTitle, ModalSubtitle } from '../styles';

// SVGS
import { ErrorProposal } from '../svgs';

interface IModalErrorProposal {
  onClose: any;
}

const ModalErrorProposalGeneric = ({ onClose }: IModalErrorProposal) => {
  return (
    <ModalContent>
      <ModalContainer>
        <ErrorProposal />
        <ModalTitle>Erro ao Cadastrar Proposta</ModalTitle>
      </ModalContainer>
      <ModalSubtitle>Aguarde alguns minutos e tente cadastrar novamente</ModalSubtitle>
      <ActionButtons className="left">
        <Button onClick={onClose} big black textLeft>
          Ok, Entendi
        </Button>
      </ActionButtons>
    </ModalContent>
  );
};

export default ModalErrorProposalGeneric;
