// Enums
import { FileType } from '../enums/file.enum';

// Svgs
import { BankAddressSvg, IdentificationSvg } from '../screens/Document/svgs';

export const ProponentDocuments = [
  {
    name: FileType.PERSONAL_IDENTIFICATION,
    label: 'Identificação Frente',
    subtitle: 'RG, CNH, RNE...',
    icon: IdentificationSvg,
    requerid: true,
  },
  {
    name: FileType.PERSONAL_IDENTIFICATION_BACK,
    label: 'Identificação Verso',
    subtitle: 'RG, CNH, RNE...',
    icon: IdentificationSvg,
  },
  {
    name: FileType.PAYROLL,
    label: 'Holerite',
    subtitle: 'Comprovante de renda',
    icon: BankAddressSvg,
    requerid: true,
  },
];

export const ProponentDocumentsInss = [
  {
    name: FileType.PERSONAL_IDENTIFICATION,
    label: 'Identificação Frente',
    subtitle: 'RG, CNH, RNE...',
    icon: IdentificationSvg,
    requerid: true,
    acceptedFiles: ['image/jpeg'],
    limitResolution: 250,
  },
  {
    name: FileType.PERSONAL_IDENTIFICATION_BACK,
    label: 'Identificação Verso',
    subtitle: 'RG, CNH, RNE...',
    icon: IdentificationSvg,
    requerid: true,
    acceptedFiles: ['image/jpeg'],
    limitResolution: 250,
  },
  {
    name: FileType.PAYROLL,
    label: 'Holerite',
    subtitle: 'Comprovante de renda',
    icon: BankAddressSvg,
  },
];
