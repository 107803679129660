import React, { createContext, useCallback, useContext, useState } from 'react';
import { Modals } from '../enums/portals.enum';

interface IPortalCtx {
  children: React.ReactNode;
}

export const PortalsContext = createContext({});
export const usePortals = (): any => useContext(PortalsContext);

const PortalsProvider = ({ children }: IPortalCtx) => {
  const [portalOpened, setPortalOpened] = useState({
    isOpened: false,
    type: 'undefined',
    props: {},
  });

  const handleClosePortal = useCallback((type: Modals) => {
    setPortalOpened({
      type,
      isOpened: false,
      props: {},
    });
  }, []);

  const handleOpenPortal = useCallback((type, props) => {
    setPortalOpened({
      type,
      isOpened: true,
      props: props ? { ...props } : {},
    });
  }, []);

  const values = {
    portalOpened,
    setPortalOpened,
    handleClosePortal,
    handleOpenPortal,
  };

  return <PortalsContext.Provider value={{ ...values }}>{children}</PortalsContext.Provider>;
};

export default PortalsProvider;
