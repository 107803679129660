import React, { useState, useCallback, useEffect } from 'react';
import { Container, Content, Title, Wrapper } from './styles';
import { Row, Col } from '../../components/GRID';
import { Table } from '../../components/UI/Table';

// MODELS
import { Filter } from '../../models/Filter';
import { Proposal } from '../../models/Proposal';

// CONTEXT
import { useProposal } from '../../hooks/AppContext';

// SERVICES
import ProposalService from '../../services/ProposalService';

// UTILS
import { isRegularTerm, changeColumns } from '../../utils/filter';
import logger from '../../utils/logger.utils';

const Manage: React.FC = () => {
  const {
    changeTableConfiguration,
    getPageSize,
    pageSize,
    setFormOpened,
    setFormOwnerOpened,
    setIsDash,
    setPageSize,
    setTableFilters,
    setTableSearch,
    tableConfiguration,
    tableFilters,
    tableSearch,
  } = useProposal();

  const [data, setData] = useState({});
  const [meta, setMeta] = useState({
    hasNextPage: true,
    hasPreviousPage: false,
    pageNumber: 0,
    pageSize: 10,
    totalPages: 1,
    totalRecords: 0,
  });

  const [loading, setLoading] = useState(true);

  const handleLoadData = useCallback(async () => {
    const newData: Proposal[] = [];

    await ProposalService.fetchAll(tableFilters.getFilters())
      .then(({ data: items }) => {
        if (!items) return;
        items.data.map((v: any) => {
          newData.push(new Proposal(v));
        });
        setData(newData);
        setMeta(items.meta);

        if (isRegularTerm(tableFilters?.searchTerm)) {
          const items = [...tableConfiguration];
          changeTableConfiguration(changeColumns(items));
        }

        setLoading(false);
      })
      .catch((error: any) => {
        logger.error('Error loading data');
      });
  }, [meta, tableFilters]);

  const handleReload = useCallback(() => {
    handleLoadData();
  }, [meta]);

  const handleSearch = useCallback(
    (value) => {
      setTableFilters(new Filter(tableFilters).set('searchTerm', value).set('pageNumber', 0));
    },
    [tableFilters],
  );

  const handleChangePage = useCallback(
    (pageNumber: number) => {
      setTableFilters(new Filter(tableFilters).set('pageNumber', pageNumber));
    },
    [tableFilters, meta],
  );
  const handlePageSize = useCallback(
    (pageSizeParam: number) => {
      setPageSize(getPageSize(pageSizeParam));
      setTableFilters(new Filter(tableFilters).set('pageSize', pageSizeParam).set('pageNumber', 0));
    },
    [tableFilters],
  );

  const handleOrdering = useCallback(
    (column: string, direction: string) => {
      setTableFilters(new Filter(tableFilters).set('orderField', column).set('orderType', direction));
    },
    [tableFilters],
  );

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      handleLoadData();
    };

    if (Object.keys(tableFilters).length === 0) {
      setTableFilters(new Filter());
    } else {
      fetchData();
    }
  }, [tableFilters]);

  return (
    <Container>
      <Content>
        <Row centralized>
          <Col sm={18} md={18} lg={18}>
            <Title>Gerenciamento de propostas</Title>
          </Col>
        </Row>
        <Row>
          <Col sm={18} md={18} lg={18}>
            <Wrapper>
              <Table
                loading={loading}
                data={data}
                meta={meta}
                onReload={handleReload}
                onChangePage={handleChangePage}
                onChangePageSize={handlePageSize}
                onOrdering={handleOrdering}
                onSearch={handleSearch}
                // onApplyFilter={handleApplyFilter}
                tableSearch={tableSearch}
                setTableSearch={setTableSearch}
                pageSize={pageSize}
              />
            </Wrapper>
          </Col>
        </Row>
      </Content>
    </Container>
  );
};
export default Manage;
