// @ts-nocheck
import React, { useState, ReactElement, useEffect, useCallback, forwardRef, useRef, useImperativeHandle } from 'react';
import verifyImgResolution from '../../../utils/verifyImgResolution';
import { Button } from '../Button';
import {
  Container,
  Label,
  Content,
  ImageSvg,
  Title,
  SubTitle,
  Size,
  Circle,
  Upload,
  SimpleLinkButton,
  SpanButton,
  LoadingStyle,
  LoadingBar,
  LoadingBarComplete,
} from '../InputUpload/styles';
import { SuccessSvg, DeleteSvg, LockSvg, ErrorSvg, ReturnSvg, ChangeFileSvg, ViewDocumentSvg, CancelSvg } from './svgs';

interface InputUploadProps {
  id?: string;
  data?: any;
  deleteCallback?: any;
  disabled?: any;
  enableXls?: boolean;
  file?: any;
  handleCancel: any;
  handleFile: any;
  hasError?: string;
  hasErrorCallback?: any;
  icon?: any;
  isSignatureModal?: boolean;
  label?: ReactElement;
  subtitle?: string;
  viewDocumentCallback?: any;
  wasFile?: boolean;
  wasIntegrated?: boolean;
  wasTasks?: boolean;
  acceptedFiles?: string[];
  limitResolution?: number | null;
  maxFileSize?: number;
}

const InputUpload: React.FC<InputUploadProps> = forwardRef(
  (
    {
      data = {},
      deleteCallback,
      enableXls = false,
      file = {},
      handleCancel,
      handleFile,
      hasError,
      hasErrorCallback,
      icon,
      isSignatureModal,
      label,
      subtitle,
      viewDocumentCallback,
      wasFile = false,
      wasIntegrated,
      wasTasks,
      acceptedFiles = ['application/pdf', 'image/jpeg', 'image/png'],
      limitResolution = null,
      maxFileSize = 10240000,
      id,
      ...rest
    },
    ref,
  ): ReactElement => {
    const inputRef = useRef(null);
    const [currentFile, setCurrentFile] = useState({});

    const [sizeFile, setSizeFile] = useState('');
    const [progress, setProgress] = useState(0);
    const [message, setMessage] = useState({ code: 0, text: '' });
    const [remountKey, setRemountKey] = useState(1);
    const fileField = React.useRef<HTMLInputElement>(null);

    if (enableXls) {
      acceptedFiles.push('application/vnd.ms-excel');
      acceptedFiles.push('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
    }

    useEffect(() => {
      // @ts-ignore
      if (!!file?.name) {
        setCurrentFile(file);
        if (wasFile) {
          let size = file.sizeKb || file.size;

          if (file.size > 1000) {
            size = Math.round(size / 1000);
          }

          if (size > 0) {
            if (size > 1000) {
              setSizeFile(Math.round(size / 1000) + 'Mb');
            } else {
              setSizeFile(Math.round(size) + 'Kb');
            }
          }
        }
      }
    }, [file]);

    const onSelectFile = useCallback(
      async (event: any) => {
        setProgress(0);

        const fileTmp = event.target.files[0];
        setMessage({ code: 0, text: '' });

        if (!acceptedFiles.includes(fileTmp.type)) {
          setMessage({
            code: 1,
            text: 'Extensão inválida',
          });
          setRemountKey(remountKey + 1); // Reload input to clean FileList
          return;
        }

        if (limitResolution && (fileTmp.type == 'image/jpeg' || fileTmp.type == 'image/png')) {
          const hasResolution = await verifyImgResolution(fileTmp, limitResolution);
          if (!hasResolution) {
            setMessage({
              code: 1,
              text: 'Resolução mínima do arquivo deve ser 250x250 pixels',
            });
            setRemountKey(remountKey + 1); // Reload input to clean FileList
            return;
          }
        }

        if (fileTmp.size > maxFileSize) {
          setMessage({
            code: 1,
            text: `O arquivo precisa ser menor que ${Math.round(maxFileSize / 1000000)} MB`,
          });
          setRemountKey(remountKey + 1); // Reload input to clean FileList
          return;
        }
        setProgress(1);
        setCurrentFile(event.target.files[0]);
        if (fileTmp.size > 0) {
          const size = fileTmp.size / 1000;
          if (size > 1000) {
            setSizeFile(Math.round(size / 1000) + 'Mb');
          } else {
            setSizeFile(Math.round(size) + 'Kb');
          }
        }
        handleFile(fileTmp, event);
        setRemountKey(remountKey + 1);
        event.target.value = null;
      },
      [handleFile, remountKey],
    );

    const deleteFile = (e: Event) => {
      e.preventDefault();
      setRemountKey(remountKey + 1); // Reload input to clean FileList
      deleteCallback();
    };

    const cancelUpload = (e: any) => {
      handleCancel();
      setRemountKey(remountKey + 1);
    };
    // @ts-ignore
    const changeFileButton = (e: any) => {
      fileField.current?.click();
      return;
    };
    // @ts-ignore
    const tryAgainButton = (e: any) => {
      if (message.code === 1) {
        fileField.current?.click();
        return;
      }
      hasErrorCallback(e);
    };

    const viewFile = (e: any) => {
      e.preventDefault();
      viewDocumentCallback();
    };

    useImperativeHandle(
      ref,
      () => ({
        setLoading: (prog: number) => {
          setProgress(prog);
        },
        setReference: (reference: any) => {
          inputRef.current = reference;
        },
      }),
      [inputRef],
    );

    return (
      <Container
        {...rest}
        className={progress > 0 && progress < 100 ? 'Uploading' : ''}
        {...{ hasError: !!message.code }}
      >
        <Label>
          {!!message.code ? (
            <ErrorSvg />
          ) : (
            !!icon && <ImageSvg>{!!progress && progress < 100 ? icon : wasFile ? <SuccessSvg /> : icon}</ImageSvg>
          )}
          <Content>
            <Title>{label}</Title>
            <div>
              <SubTitle {...{ hasError: !!message.code }}>
                {!!message.text ? message.text : currentFile ? (wasFile ? currentFile.name : subtitle) : subtitle}
              </SubTitle>
              {wasFile && (
                <Size>
                  <Circle />
                  {sizeFile}
                </Size>
              )}
            </div>
          </Content>
          {rest.disabled ? (
            <LockSvg />
          ) : !!progress && progress < 100 ? (
            <LoadingStyle>
              <span>{progress}%</span>
              <LoadingBar>
                <LoadingBarComplete style={{ width: `${progress}%` }} />
              </LoadingBar>
              <SimpleLinkButton onClick={cancelUpload}>
                <CancelSvg />
              </SimpleLinkButton>
            </LoadingStyle>
          ) : wasFile && !wasTasks && !wasIntegrated ? (
            <>
              {!isSignatureModal && (
                <SimpleLinkButton viewDocument onClick={viewFile}>
                  <ViewDocumentSvg />
                </SimpleLinkButton>
              )}
              <SimpleLinkButton onClick={deleteFile}>
                <DeleteSvg />
              </SimpleLinkButton>
            </>
          ) : wasTasks ? (
            <>
              {wasFile && (
                <SimpleLinkButton viewDocument onClick={viewFile}>
                  <ViewDocumentSvg />
                </SimpleLinkButton>
              )}
              <SimpleLinkButton viewDocument onClick={changeFileButton}>
                <ChangeFileSvg />
              </SimpleLinkButton>
            </>
          ) : wasIntegrated ? (
            <>
              {wasFile && (
                <SimpleLinkButton viewDocument onClick={viewFile}>
                  <ViewDocumentSvg />
                </SimpleLinkButton>
              )}
            </>
          ) : !!message.code ? (
            <SpanButton onClick={tryAgainButton}>
              Tentar novamente <ReturnSvg />
            </SpanButton>
          ) : (
            <Button uploader white onClick={() => fileField.current?.click()}>
              Anexar documento
            </Button>
          )}
        </Label>

        {/* Display None */}
        {!wasFile && (
          <Upload
            key={remountKey}
            type="file"
            ref={fileField}
            onChange={onSelectFile}
            accept={acceptedFiles.join()}
            id={`file-${id}`}
          />
        )}
      </Container>
    );
  },
);

export default InputUpload;
