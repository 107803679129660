// @ts-nocheck
import React, { useEffect, useState, useRef, useCallback } from 'react';
import InputMask, { ReactInputMask } from 'react-input-mask';

import IntlCurrencyInput from 'react-intl-currency-input';

import * as Yup from 'yup';

import {
  InputContainer,
  Container,
  Error,
  Label,
  SubLabelText,
  MessageTooltipText,
  CheckMarkContainer,
  ComponentTooltip,
  RightContent,
  LoaderContent,
} from './styles';
import { CheckMark } from '../CheckMark';

import { cpf } from 'cpf-cnpj-validator';
import { isCellphone } from '../../../utils/validations';

const currencyConfig = {
  locale: 'pt-BR',
  formats: {
    number: {
      BRL: {
        style: 'currency',
        currency: 'BRL',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
    },
  },
};

const schema = Yup.object().shape({
  documentNumber: Yup.string()
    .required('CPF obrigatório')
    .test('CPF', 'CPF inválido', function (value: any) {
      return cpf.isValid(value);
    }),
  phoneNumber: Yup.string()
    .required('Telefone obrigatório')
    .test('userCellphone', 'Insira um celular válido', isCellphone),
  proposalNumber: Yup.string().required('Número obrigatório').min(7, 'Mínimo 7 números'),
});

interface InputProps {
  id?: any;
  name?: any;
  value?: any;
  icon?: any;
  mask?: any;
  isDisabled?: any;
  label?: string;
  subLabel?: any;
  MessageTooltip?: any;
  iconTooltip?: React.ComponentType;
  containerType?: any;
  onBlur?: any;
  onChange?: any;
  maskPlaceholder?: any;
  max?: any;
  min?: any;
  type?: any;
  placeholder?: any;
  hasError?: any;
  externalError?: string | null;
  iconRight?: React.ComponentType;
  loader?: React.ReactNode | null;
  hasValidation?: boolean;
}

const MaskedInput: React.FC<InputProps> = ({
  name,
  icon: Icon,
  mask,
  label,
  subLabel,
  iconTooltip: IconTooltip,
  MessageTooltip,
  isDisabled,
  containerType,
  value,
  onBlur: onBlurProps,
  onChange: onChangeProps,
  type = 'money',
  hasError,
  externalError,
  max,
  min,
  iconRight: IconRight,
  loader: Loader,
  hasValidation = true,
  ...rest
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const [errors, setErrors] = useState({});

  // const { register, errors, trigger } = useForm({
  //   resolver: yupResolver(schema)
  // });

  const handleChangeMoney = (event: any, value: any, maskedValue: any) => {
    event.preventDefault();

    value >= min && value <= max ? setIsFocused(false) : setIsFocused(true);
    if (onChangeProps) onChangeProps({ name, value }, maskedValue);
  };

  const handleChange = (event: any) => {
    event.preventDefault();
    setIsFocused(true);

    if (onChangeProps) onChangeProps(event.target);
  };

  const handleBlur = (event: any) => {
    event.persist();
    if (onBlurProps) onBlurProps(event);
    if (!hasValidation) {
      validateSchema(event.target.value, event.target.name);
    }
    setIsFocused(false);
  };

  const validateSchema = (value: any, name: any) => {
    Yup.reach(schema, name)
      .validate(value)
      .then(() => setErrors({}))
      .catch((err) => {
        setErrors({
          [name]: {
            message: err.message,
          },
        });
      });
  };

  useEffect(() => {
    if (!hasError) return;
    hasError(name, !!errors[name]);
  }, [errors[name]]);

  return (
    <Container {...{ containerType: containerType }}>
      <Label htmlFor={name} {...{ isFocused: isFocused, isDisabled: isDisabled }}>
        {label}
      </Label>

      {!!subLabel && (
        <SubLabelText>
          {subLabel}{' '}
          {IconTooltip && (
            <ComponentTooltip>
              {' '}
              <IconTooltip /> <MessageTooltipText> {MessageTooltip} </MessageTooltipText>{' '}
            </ComponentTooltip>
          )}{' '}
        </SubLabelText>
      )}

      <InputContainer {...{ isErrored: !!errors[name], isFocused: !!isFocused }}>
        {errors[name] && (
          <Error className="error-form" title={errors[name].message}>
            {errors[name].message}
          </Error>
        )}
        {!!externalError && (
          <Error className="error-form" title={externalError}>
            {externalError}
          </Error>
        )}
        {type === 'money' ? (
          <IntlCurrencyInput
            name={name}
            currency="BRL"
            value={value}
            config={currencyConfig}
            onChange={handleChangeMoney}
            onBlur={handleBlur}
            max={max}
            readOnly={isDisabled}
            {...rest}
          />
        ) : (
          <>
            <InputMask
              autoComplete="nope"
              onBlur={handleBlur}
              onChange={handleChange}
              mask={mask}
              value={value}
              name={name}
              maskChar={null}
              readOnly={isDisabled}
              {...rest}
            />
          </>
        )}

        {/* {!error && isFilled && !isFocused && containerType !== 'hidden' && (
          <img src={checkIconBlack} alt="Check icon" />
        )}
        {error && <img src={errorIcon} alt="Error icon" />} */}
      </InputContainer>
      {!!value && !isFocused && JSON.stringify(errors).length == 2 && (
        <CheckMarkContainer>
          <CheckMark />
        </CheckMarkContainer>
      )}
      {IconRight && (
        <RightContent>
          <IconRight />
        </RightContent>
      )}
      {Loader && <LoaderContent>{Loader}</LoaderContent>}
    </Container>
  );
};

export default MaskedInput;
