import styled from 'styled-components';

export const Container = styled.div`
  padding: 11px 0;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
`;

export const UserName = styled.span`
  font-size: 12.6px;
  padding: 0 16px;
  letter-spacing: 0.02em;
`;

// ---- Item
export const ItemDropDown = styled.ul`
  width: 100%;
  padding: 18px 26px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 13px;
  line-height: 24px;
  color: #555555;
  font-family: 'Clan Web Pro News';
  text-transform: none;

  &:hover {
    background: rgba(246, 246, 246, 0.6);
    cursor: pointer;
    color: #101010;
  }
  &.delete {
    color: #d02931;
    border-top: 1px solid #f6f6f6;
    &:hover {
      color: #d02931;
    }
  }
  &.disabled {
    cursor: not-allowed;
    color: #e5e5e5 !important;
    svg {
      rect {
        &:first-child {
          stroke: #e5e5e5;
        }
        &:last-child {
          fill: #e5e5e5;
        }
      }
    }
  }
`;
