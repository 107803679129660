import React, { useState } from 'react';

// COMPONENTS
import { Button } from '../../Button';
import { Loader } from '../../Loader';

// STYLES
import { ActionButtons, Close, ModalContainer, ModalContent, ModalTitle, ModalSubtitle } from '../styles';

// CONTEXT
import { usePortals } from '../../../../hooks/PortalsContext';
import { useProposal } from '../../../../hooks/AppContext';

// ENUMS
import { Modals } from '../../../../enums/portals.enum';

interface IModalConfirmationProposal {
  onClose: any;
  onTransform: any;
}

const ModalConfirmationProposal = ({ onClose, onTransform }: IModalConfirmationProposal) => {
  const [isLoading, setLoading] = useState(false);
  const [isDisabled, setDisabled] = useState(false);

  const { handleOpenPortal } = usePortals();
  const { setStep } = useProposal();

  const handleTransform = async () => {
    setLoading(true);
    setDisabled(true);
    const resp = await onTransform();

    setLoading(false);

    if (resp === undefined) {
      handleOpenPortal(Modals.ERRORPROPOSAL);
    } else {
      setStep((previousStep: any) => previousStep + 1);
      setDisabled(false);
      onClose();
    }
  };

  return (
    <ModalContent>
      <ModalContainer>
        <ModalTitle>Enviar proposta</ModalTitle>
      </ModalContainer>
      <ModalSubtitle>
        Ao enviar a proposta não será possível alterar os dados, apagar ou incluir documentos. Você poderá acompanhar o
        andamento no item <strong>Gerenciar propostas.</strong>
      </ModalSubtitle>
      <br />
      <ModalSubtitle>Deseja enviar esta proposta?</ModalSubtitle>
      <ActionButtons>
        <Button onClick={onClose} big outline disabled={isDisabled}>
          Cancelar
        </Button>
        <Button
          onClick={!isLoading && !isDisabled ? () => handleTransform() : () => {}}
          disabled={isDisabled}
          big
          black
        >
          {isLoading ? 'Salvando' : 'Enviar'}
          {isLoading ? <Loader /> : ''}
        </Button>
      </ActionButtons>
    </ModalContent>
  );
};

export default ModalConfirmationProposal;
