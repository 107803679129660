import React, { useEffect, useState, ReactElement } from 'react';

// SERVICE
import { useSignature } from '../../../../hooks/SignatureContext';
// Screen Error Signature
import { ErrorSignature } from '../../ScreensError/index';
// ULTIL
import { numberFormat } from '../../../../utils/Signature/formatCurrency';

import {
  ContainerSign,
  CotentSign,
  TitleSign,
  TextSign,
  DataLoan,
  DataValueLoan,
  ParagraphDataValueLoan,
  SubParagraphDataValueLoan,
  IconDataValueLoan,
} from '../styles';

import { IconDocument, Spreadsheet, Chart } from '../svgs';
import { Type } from '../../../../enums/loan-type.enum';

export const SignSignature: React.FC = ({ formValues }: any) => {
  const { formOpened, setFormOpened, errorSignature, dataLoanFgts } = useSignature();

  const sendHandleDeviceInfo = async () => {
    formValues.logicalGate = await location.port;
    formValues.operatingSystem = await navigator.appVersion;
    formValues.browser = await navigator.userAgent;
    setFormOpened(formValues);
  };

  useEffect(() => {
    sendHandleDeviceInfo();
  }, []);

  return (
    <>
      {errorSignature ? (
        <ErrorSignature heightCalc />
      ) : (
        <ContainerSign>
          {formOpened.type === Type.FGTS ? (
            <CotentSign>
              <IconDocument />
              <TitleSign>Confira seu empréstimo</TitleSign>
              <TextSign>
                Clique em finalizar para confirmar a assinatura digital e contratar seu Adiantamento de saque
                aniversário FGTS.
              </TextSign>
              <DataLoan>
                <DataValueLoan>
                  <IconDataValueLoan>
                    <Spreadsheet />
                  </IconDataValueLoan>
                  <ParagraphDataValueLoan>Empréstimo de</ParagraphDataValueLoan>
                  <SubParagraphDataValueLoan>{numberFormat(dataLoanFgts.Loan / 100)}</SubParagraphDataValueLoan>
                </DataValueLoan>
                <DataValueLoan>
                  <IconDataValueLoan>
                    <Chart />
                  </IconDataValueLoan>
                  <ParagraphDataValueLoan>A ser pago em</ParagraphDataValueLoan>
                  <SubParagraphDataValueLoan>{dataLoanFgts.Installments} parcelas</SubParagraphDataValueLoan>
                </DataValueLoan>
              </DataLoan>
            </CotentSign>
          ) : (
            <CotentSign>
              <IconDocument />
              <TitleSign>Concluir assinatura</TitleSign>
              <TextSign>
                Clique em finalizar para concluir o processo de assinatura e contratar seu Consignado Bari.
              </TextSign>
            </CotentSign>
          )}
        </ContainerSign>
      )}
    </>
  );
};
