import React from 'react';

import { SideMenuItemContainer, Title, Icon, Dot } from './style';

interface ComponentProps {
  item: any;
}

const SideMenuItem = ({ item, ...props }: ComponentProps) => {
  const { title, icon, active } = item;

  return (
    <SideMenuItemContainer {...{ active: active }} {...item}>
      <Title>{title}</Title>
      <Icon>{icon()}</Icon>
      <Dot className="checked"></Dot>
    </SideMenuItemContainer>
  );
};

export default SideMenuItem;
