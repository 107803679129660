import React, { useCallback, useState, useEffect, createRef } from 'react';
import { Panel } from '../Panel';
import { Checkbox } from '../Checkbox';
import { Counter, ContainerFilter, CounterEmpty } from './styles';
import { ActionIconWhite, ActionIcon } from './svgs';
/**
 * @author Matheus Vieira <matheus.vieira@deal.com.br>
 * @since 2021-01-26
 */
interface FilterProps {
  title: string;
  options: any;
  panelId: string;
  onApply: any;
}

const Filter = (props: FilterProps) => {
  const { title, options, panelId, onApply } = props;
  const [filters, setFilters] = useState(options);
  const container = createRef<HTMLDivElement>();
  const [active, setActive] = useState(false);

  const handleClickOutside = (event: Event) => {
    // @ts-ignore
    if (container.current && !container.current.contains(event.target)) {
      setActive(false);
    }
  };
  document.addEventListener('mousedown', handleClickOutside);

  const handleOpen = useCallback(
    (e) => {
      if (e.target.id != 'FilterContent' && filters.length > 0) {
        setActive(!active);
      }
    },
    [filters, active],
  );

  const handleChange = useCallback(
    (e: any) => {
      const filtered = [...filters];
      filtered[e.target.dataset.id].active = e.target.checked;
      setFilters(filtered);
    },
    [filters],
  );

  const handleApply = useCallback(() => {
    onApply(
      panelId,
      filters.filter((option: any) => option.active),
    );
    setActive(false);
  }, [onApply, filters, panelId]);

  const noneFilterChecked = useCallback(() => {
    return filters.filter((option: any) => option.active).length == 0;
  }, [filters]);

  useEffect(() => {
    setFilters(options);
  }, [options]);

  return (
    <ContainerFilter ref={container}>
      <div onClick={handleOpen} style={{ width: '100%' }}>
        <div
          className={`FilterWrapper ${active ? 'Active' : ''} ${
            filters.filter((option: any) => !option.active).length > 0 ? 'HasFilter' : ''
          }`}
          onClick={handleOpen}
        >
          {title}
          {filters.filter((option: any) => !option.active).length > 0 && (
            <Counter>
              {filters.filter((option: any) => option.active).length < 10
                ? `0${filters.filter((option: any) => option.active).length}`
                : filters.filter((option: any) => option.active).length}
              <ActionIconWhite />
            </Counter>
          )}
          {filters.filter((option: any) => !option.active).length < 1 && (
            <CounterEmpty>
              <ActionIcon />
            </CounterEmpty>
          )}
        </div>
        <Panel
          key={panelId}
          active={active}
          id="FilterContent"
          onApply={handleApply}
          disabled={noneFilterChecked()}
          className={filters.length > 8 ? 'HasScroll' : ''}
          leftSide
        >
          {filters.map((filter: any, idx: number) => {
            return (
              <Checkbox
                key={`${idx}-${filter.name}`}
                data-id={idx}
                data-name={filter.name}
                label={filter.label}
                name={`${title.toLowerCase()}-${filter.name}`}
                checked={filter.active}
                onChange={handleChange}
              />
            );
          })}
        </Panel>
      </div>
    </ContainerFilter>
  );
};

export default Filter;
