import React from 'react';

export const IlustacaoSvg = () => {
  return (
    <svg width="426" height="275" viewBox="0 0 426 275" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M358.915 86.1017L358.463 83.8635C358.179 82.4606 359.087 81.0935 360.49 80.8101C360.557 80.7965 360.625 80.7855 360.693 80.7773C362.279 80.5862 363.755 81.6181 364.121 83.1734L368.669 102.536"
        fill="#101010"
      />
      <path
        d="M358.915 86.1017L358.463 83.8635C358.179 82.4606 359.087 81.0935 360.49 80.8101C360.557 80.7965 360.625 80.7855 360.693 80.7773C362.279 80.5862 363.755 81.6181 364.121 83.1734L368.669 102.536"
        stroke="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M355.816 106.633L363.673 117.857L361.429 139.184L392.857 163.878L403.884 136.172L391.294 128.903C384.254 124.839 378.385 119.023 374.256 112.02L352.093 74.4316C351.168 72.863 349.162 72.3171 347.57 73.2009C345.956 74.097 345.305 76.0852 346.076 77.7626L351.603 89.7866"
        fill="#101010"
      />
      <path
        d="M355.816 106.633L363.673 117.857L361.429 139.184L392.857 163.878L403.884 136.172L391.294 128.903C384.254 124.839 378.385 119.023 374.256 112.02L352.093 74.4316C351.168 72.863 349.162 72.3171 347.57 73.2009C345.956 74.097 345.305 76.0852 346.076 77.7626L351.603 89.7866"
        stroke="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M373.775 111.212L370.516 105.39C369.03 102.736 366.964 100.451 364.472 98.7068L347.023 86.4944L339.247 82.0046L332.034 78.7495C330.292 77.9632 328.237 78.6251 327.281 80.2806C326.351 81.8912 326.903 83.9506 328.514 84.8805L333.374 87.6866L339.549 92.1252C341.538 93.5553 343.48 95.0502 345.372 96.6074L350.44 100.78"
        fill="#101010"
      />
      <path
        d="M373.775 111.212L370.516 105.39C369.03 102.736 366.964 100.451 364.472 98.7068L347.023 86.4944L339.247 82.0046L332.034 78.7495C330.292 77.9632 328.237 78.6251 327.281 80.2806C326.351 81.8912 326.903 83.9506 328.514 84.8805L333.374 87.6866L339.549 92.1252C341.538 93.5553 343.48 95.0502 345.372 96.6074L350.44 100.78"
        stroke="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M399.592 150.408L393.98 165L367.022 148.697C364.373 147.095 361.658 145.603 358.886 144.225L351.046 140.33C348.304 138.967 345.62 137.493 342.999 135.912L333.763 130.338C331.361 128.889 330.562 125.783 331.964 123.354C333.316 121.013 336.309 120.211 338.65 121.562C338.695 121.588 338.739 121.615 338.782 121.642L345.179 125.608L350.174 127.298C353.574 128.448 357.314 127.039 359.108 123.93L359.798 122.736C361.24 120.238 360.981 117.108 359.149 114.88C357.206 112.519 354.368 111.073 351.316 110.89L345.523 110.542C344.281 110.468 343.063 110.912 342.161 111.769L333.916 119.602C332.543 120.906 330.372 120.85 329.067 119.477C329.04 119.449 329.014 119.42 328.988 119.39C327.638 117.869 327.619 115.584 328.943 114.04L336.874 104.79C338.606 102.77 341.065 101.515 343.716 101.297L355.867 100.299C356.777 100.224 357.689 100.429 358.48 100.885L360.181 101.868"
        fill="#101010"
      />
      <path
        d="M399.592 150.408L393.98 165L367.022 148.697C364.373 147.095 361.658 145.603 358.886 144.225L351.046 140.33C348.304 138.967 345.62 137.493 342.999 135.912L333.763 130.338C331.361 128.889 330.562 125.783 331.964 123.354C333.316 121.013 336.309 120.211 338.65 121.562C338.695 121.588 338.739 121.615 338.782 121.642L345.179 125.608L350.174 127.298C353.574 128.448 357.314 127.039 359.108 123.93L359.798 122.736C361.24 120.238 360.981 117.108 359.149 114.88C357.206 112.519 354.368 111.073 351.316 110.89L345.523 110.542C344.281 110.468 343.063 110.912 342.161 111.769L333.916 119.602C332.543 120.906 330.372 120.85 329.067 119.477C329.04 119.449 329.014 119.42 328.988 119.39C327.638 117.869 327.619 115.584 328.943 114.04L336.874 104.79C338.606 102.77 341.065 101.515 343.716 101.297L355.867 100.299C356.777 100.224 357.689 100.429 358.48 100.885L360.181 101.868"
        stroke="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M411.632 116.097C415.655 117.722 417.599 122.302 415.974 126.325L358.626 268.262C357.255 271.656 353.961 273.877 350.301 273.877H196.429C192.709 273.877 189.694 270.862 189.694 267.143V266.02L351.058 266.02L411.632 116.097Z"
        fill="white"
        stroke="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M298.942 244.08L303.436 248.574C305.407 250.545 306.923 252.925 307.876 255.546L309.706 260.578C310.358 262.371 309.433 264.354 307.64 265.006L307.551 265.037C305.599 265.687 303.46 264.855 302.46 263.056L301.378 261.108C299.534 257.789 296.924 254.958 293.764 252.852L292.307 251.88L296.815 261.348C297.636 263.072 296.904 265.135 295.18 265.956C295.007 266.038 294.827 266.106 294.643 266.159C292.295 266.83 289.779 265.93 288.389 263.922L280.613 252.69V262.792C280.613 264.651 279.105 266.159 277.245 266.159C275.345 266.159 273.68 264.918 273.125 263.12L273.061 262.892L271.055 254.867C270.735 253.587 269.585 252.69 268.266 252.69C266.924 252.69 265.634 253.187 264.641 254.08L264.433 254.277L257.812 260.899C254.549 264.162 250.16 266.039 245.558 266.153L245.112 266.159L195.306 266.02L195.478 243.667L195.306 232.347L268.936 232.485C273.422 232.485 277.78 233.958 281.343 236.669L281.735 236.975L290.077 239.061C293.432 239.9 296.496 241.634 298.942 244.08Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M169.49 232.347H230.102V246.861L216.633 265.44L169.49 266.02V232.347Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M163.878 134.694L153.776 232.347H132.449L161.602 146.971L163.878 134.694Z"
        fill="black"
      />
      <path
        d="M2.2449 165.907C24.3701 141.028 44.4239 121.37 62.4065 106.933C80.3891 92.4968 115.982 70.7298 140.022 78.9391C175.102 90.9182 164.24 139.506 158.219 156.949C152.608 173.207 143.942 198.339 132.224 232.347H202.041"
        stroke="black"
      />
      <path
        d="M200.918 266.02H93.015C80.6167 266.02 70.566 255.97 70.566 243.571C70.566 241.128 70.9649 238.701 71.747 236.386L98.6478 156.765L104.674 139.184"
        stroke="black"
      />
      <path d="M93.1633 172.857L96.8446 161.813C98.6595 156.368 96.7111 150.377 92.0408 147.041" stroke="black" />
      <path d="M190.074 67.1326L218.457 84.1866" stroke="black" />
      <path
        d="M190.166 67.3807C189.079 62.9933 184.643 60.3282 180.259 61.428C175.875 62.5279 173.202 66.9761 174.289 71.3635C175.377 75.7509 179.812 78.416 184.196 77.3162"
        stroke="black"
      />
      <path
        d="M177.176 70.6396C177.868 73.4316 180.69 75.1275 183.48 74.4276C186.27 73.7277 187.971 70.897 187.279 68.105C186.588 65.3131 183.765 63.6171 180.975 64.317"
        stroke="black"
      />
      <path
        d="M184.393 68.8292C184.096 67.6326 182.887 66.9058 181.691 67.2057C180.495 67.5057 179.766 68.7188 180.063 69.9154C180.359 71.112 181.569 71.8388 182.765 71.5388"
        stroke="black"
      />
      <path
        d="M218.707 97.3759C221.169 98.8553 225.484 96.1942 228.345 91.4324C231.207 86.6705 231.53 81.611 229.068 80.1317C226.606 78.6524 222.291 81.3134 219.43 86.0753C216.568 90.8371 216.245 95.8966 218.707 97.3759Z"
        stroke="black"
      />
      <path
        d="M229.068 80.1318C228.285 79.6611 227.967 79.4698 227.476 79.1751C225.014 77.6958 220.699 80.3568 217.838 85.1187C214.976 89.8806 214.653 94.9401 217.115 96.4194L218.707 97.376"
        stroke="black"
      />
      <path
        d="M179.499 118.289L172.449 120.934L153.659 72.1996L168.05 48.2492C178.541 30.789 202.695 22.5603 220.105 33.0212L240.653 45.3682C244.839 47.8831 250.207 47.1705 253.591 43.6509C257.557 48.3796 258.291 55.2978 254.926 60.8983L254.596 61.4482C250.527 68.2191 241.781 70.4605 234.989 66.4729C235.924 73.1597 234.658 80.205 230.905 86.4509L230.521 87.0896L229.237 103.406C229.14 104.636 228.066 105.557 226.838 105.463C226.493 105.437 226.159 105.33 225.862 105.152L221.311 102.418L211.334 119.023C206.247 127.489 195.281 130.24 186.839 125.168C177.508 119.561 172.257 109.059 173.357 98.2025L174.544 86.4871"
        stroke="black"
      />
      <path d="M215.939 111.359L210.235 107.932C204.944 104.753 203.232 97.8867 206.411 92.5957" stroke="black" />
      <path d="M222.259 73.4324L230.799 75.0868" stroke="black" />
      <path d="M153.935 71.5852L139.184 78.5712" stroke="black" />
      <path d="M172.449 120.934L164.99 138.208L163.485 134.694" stroke="black" />
      <path d="M135.816 232.347H125.714" stroke="black" />
      <path d="M195.306 228.979H180.714V268.265H195.306V228.979Z" fill="white" stroke="black" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M248.827 237.68L257.169 239.765C260.524 240.604 263.588 242.339 266.034 244.785L270.528 249.278C272.499 251.25 274.015 253.63 274.968 256.25L276.798 261.283C277.45 263.076 276.525 265.058 274.732 265.71C274.703 265.721 274.673 265.731 274.644 265.741C272.691 266.392 270.552 265.56 269.552 263.761L268.47 261.813C266.626 258.493 264.016 255.663 260.856 253.556L258.929 252.272"
        fill="white"
      />
      <path
        d="M248.827 237.68L257.169 239.765C260.524 240.604 263.588 242.339 266.034 244.785L270.528 249.278C272.499 251.25 274.015 253.63 274.968 256.25L276.798 261.283C277.45 263.076 276.525 265.058 274.732 265.71C274.703 265.721 274.673 265.731 274.644 265.741C272.691 266.392 270.552 265.56 269.552 263.761L268.47 261.813C266.626 258.493 264.016 255.663 260.856 253.556L258.929 252.272"
        stroke="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M200.154 232.347H236.559C241.211 232.347 245.725 233.93 249.358 236.837C253.052 239.791 256.027 243.545 258.06 247.816L264.438 261.209C265.259 262.933 264.527 264.997 262.803 265.818C262.63 265.9 262.451 265.968 262.266 266.02C259.918 266.691 257.402 265.792 256.012 263.784L248.236 252.551"
        fill="white"
      />
      <path
        d="M200.154 232.347H236.559C241.211 232.347 245.725 233.93 249.358 236.837C253.052 239.791 256.027 243.545 258.06 247.816L264.438 261.209C265.259 262.933 264.527 264.997 262.803 265.818C262.63 265.9 262.451 265.968 262.266 266.02C259.918 266.691 257.402 265.792 256.012 263.784L248.236 252.551"
        stroke="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M195.306 265.824H213.5C218.263 265.824 222.831 263.932 226.199 260.564L232.821 253.942C233.837 252.926 235.216 252.355 236.653 252.355C237.972 252.355 239.123 253.253 239.443 254.533L241.449 262.557C241.929 264.477 243.654 265.824 245.633 265.824C247.492 265.824 249 264.317 249 262.457V250.671C249 245.402 244.729 241.13 239.459 241.13H233.468C231.117 241.13 228.781 241.509 226.551 242.253"
        fill="white"
      />
      <path
        d="M195.306 265.824H213.5C218.263 265.824 222.831 263.932 226.199 260.564L232.821 253.942C233.837 252.926 235.216 252.355 236.653 252.355C237.972 252.355 239.123 253.253 239.443 254.533L241.449 262.557C241.929 264.477 243.654 265.824 245.633 265.824C247.492 265.824 249 264.317 249 262.457V250.671C249 245.402 244.729 241.13 239.459 241.13H233.468C231.117 241.13 228.781 241.509 226.551 242.253"
        stroke="black"
      />
      <path
        d="M338.98 117.857C331.567 120.553 326.633 127.597 326.633 135.485V211.02C326.633 216.6 322.11 221.122 316.531 221.122"
        stroke="#0090FF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M315.408 157.935C315.408 155.018 313.043 152.653 310.126 152.653H306.99C304.2 152.653 301.939 154.914 301.939 157.704C301.939 160.619 304.046 163.106 306.921 163.585L310.426 164.17C313.301 164.649 315.408 167.136 315.408 170.051C315.408 172.841 313.147 175.102 310.357 175.102H307.221C304.304 175.102 301.939 172.737 301.939 169.82"
        stroke="#0090FF"
      />
      <path d="M308.513 175.102V177.347" stroke="#0090FF" />
      <path d="M308.673 152.653V150.408" stroke="#0090FF" />
    </svg>
  );
};
