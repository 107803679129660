import styled from 'styled-components';

interface IModal {
  black?: boolean;
  left?: boolean;
  modalconfirm?: boolean;
  removeJustifyContent?: boolean;
  noMarginBottom?: boolean;
  marginBottom40?: boolean;
  banner?: boolean;
}

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10000;
  background: rgba(10, 10, 10, 0.5);
`;

export const ModalContent = styled.div<IModal>`
  background: #ffffff;
  border: 1px solid #f6f6f6;
  box-sizing: border-box;
  box-shadow: 20px 0px 30px -10px rgba(0, 0, 0, 0.07), -20px 0px 20px -10px rgba(0, 0, 0, 0.07),
    0px 20px 30px -10px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  justify-content: center;
  padding-top: 33px;
  flex-direction: column;
  position: fixed;
  width: 480px;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10001;
  ${({ black }) =>
    black &&
    `
    background: #101010;
    border: 1px solid #000000;
    justify-content: center;
    padding-top: 0;
    `}
  ${({ banner }) =>
    banner &&
    `
    background: inherit;
    padding: 0;
    width: auto;
    border: none;

    a{
      position: absolute;
      right: 8px;
      top: 8px;
    }
    > div{
      padding: 0;
    }
    img {
      border-radius: 8px;
    }
    `}
`;

export const ModalBody = styled.div`
  width: 100%;
  margin: 40px 0;
  flex-direction: column;
`;

export const ModalContainer = styled.div<IModal>`
  flex-direction: column;
  width: 100%;
  position: relative;
  align-items: center;
  padding-left: 48px;
  padding-right: 48px;
  svg {
    margin-bottom: 32px;
  }
  ${({ black }) =>
    black &&
    `
    padding-left: 96px;
    padding-right: 96px;
    padding-top: 55px;
    padding-bottom: 0;
    button {
      width: 100%;
    }
    h2 {
      color: #ffffff;
      margin-bottom: 16px;
      justify-content: center;
    }
    p {
      margin-bottom: 52px;
    }
    img {
      margin-bottom: 42px;
    }
  `}
`;

export const IconClose = styled.img<IModal>`
  cursor: pointer;
  position: absolute;
  right: 40px;
  top: 8px;
  ${({ black }) =>
    black &&
    `
    top: 23px;
    right: 23px;
  `}
`;

export const ModalTitle = styled.h2<IModal>`
  font-size: 17px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #000000;
  font-family: 'Clan Web Pro Medium';
  margin-bottom: 16px;
  display: flex;
  ${({ marginBottom40 }) =>
    marginBottom40 &&
    `
    margin-bottom: 40px;
  `}
`;

export const ModalSubtitle = styled.p<IModal>`
  font-size: 16px;
  line-height: 32px;
  color: #646464;
  font-family: 'Clan Web Pro News';
  padding-left: 48px;
  padding-right: 48px;
  text-align: center;
`;

export const ActionButtons = styled.div<IModal>`
  display: flex;
  background-color: #f6f6f6;
  width: 100%;
  justify-content: center;
  padding: 24px 48px;
  margin-top: 39px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;

  ${({ modalconfirm }) =>
    modalconfirm &&
    `
    background-color: var(--color-white-primary);
    margin-top: 0;
    div {
      justify-content: initial;
      svg {
        margin-right: 18px;
      }
    }
    button{
      color: #f6f6f6;
      &.blue{
        background-color: #0090FF;
      }
      &.black{
        background-color: #101010;
      }
    }
  `}

  button {
    width: 100%;
  }

  ${({ black }) =>
    black &&
    `
    background-color: #1a1a1a;
    margin-top: 0; 
  `}

  ${({ left }) =>
    left &&
    `
      justify-content: flex-start;
  `}
`;

export const Close = styled.div`
  cursor: pointer;
  justify-content: flex-end;
  padding: 0 33px;
  margin-bottom: 16px;
`;

export const LinkContent = styled.div`
  align-items: center;
  padding: 12px 16px;
  font-size: 13px;
  line-height: 150%;
  color: #626568;
  background-color: #f3f3f3;
  margin-left: 48px;
  margin-right: 48px;
  border-radius: 8px;
`;

export const Link = styled.p`
  word-wrap: break-word;
  width: 100%;
`;
