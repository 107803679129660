import { ReactElement } from 'react';
// COMPONENTS
import { Button } from '../../../components/UI/Button';
import { Container, ContainerButton } from './styles';
import { ArrowLeft, ArrowRight } from './svgs';

// SERVICES
import ProposalService from '../../../services/ProposalService';

// CONTEXT
import { useProposal } from '../../../hooks/AppContext';
import { usePortals } from '../../../hooks/PortalsContext';

// ENUMS
import { Modals } from '../../../enums/portals.enum';
import { normalizeProposal } from '../../../utils/proposal';

const Footer = (props: any): ReactElement => {
  const {
    currentStep,
    prevStep,
    nextStep,
    nextStepDisabled = false,
    prevStepDisabled = false,
    nextDescription = 'Próximo',
    loading = false,
  }: any = props;

  const { formOpened } = useProposal();

  const { handleOpenPortal } = usePortals();

  const handleTransformProposal = () => {
    handleOpenPortal(Modals.CONFIRMATIONPROPOSAL, {
      onTransform: async () => {
        return await ProposalService.createProposal(normalizeProposal(formOpened));
      },
    });
  };

  return (
    <Container>
      <ContainerButton>
        {currentStep !== 1 ? (
          <Button outline big spaced onClick={prevStep} iconLeft={ArrowLeft} {...{ disabled: prevStepDisabled || '' }}>
            Voltar
          </Button>
        ) : null}

        <Button
          black
          big
          spaced
          onClick={currentStep === 4 ? handleTransformProposal : nextStep}
          iconRight={ArrowRight}
          {...{ disabled: nextStepDisabled || loading }}
        >
          {loading ? 'Carregando...' : nextDescription}
        </Button>
      </ContainerButton>
    </Container>
  );
};

export default Footer;
