import styled, { css } from 'styled-components';

import iconCheck from '../../../assets/img-signature/check.svg';

interface proposalLabel {
  border?: boolean;
  big?: boolean;
}

export const ContainerLabel = styled.label<proposalLabel>`
  position: relative;
  display: flex;
  background: #f6f6f6;
  justify-content: initial;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 16px;
  align-items: center;
  height: 52px;
  cursor: pointer;
  width: 100%;

  ${({ border }) =>
    border &&
    `
    border: 1px solid #E5E5E5;
      
  `}

  ${({ big }) =>
    big &&
    `
    height: 68px;
    
  `}
`;

export const StyledCardContent = styled.div`
  flex-direction: column;
`;

export const StyledTitleCard = styled.p`
  font-size: 14px;
  margin-left: 16px;
  line-height: 148%;
  color: var(--color-black10);
  font-family: 'Clan Web Pro News';
`;

export const AlignCheck = styled.div`
  input[type='checkbox'] {
    -moz-appearance: none;
    -webkit-appearance: none;
    -o-appearance: none;
    outline: none;
    content: none;
    border: none;
    background-color: inherit;
  }

  input[type='checkbox']:before {
    content: '';
    background-image: url(${iconCheck});
    background-position: center;
    background-repeat: no-repeat;
    color: transparent !important;
    display: block;
    height: 16px;
    width: 16px;
    background-color: #fff;
    border: 2px solid #101010;
    border-radius: 4px;
  }

  input[type='checkbox']:checked:before {
    color: white !important;
    background-color: #0090ff;
    border-color: #0090ff;
  }
`;

export const ContentAlign = styled.div`
  align-items: center;
`;
