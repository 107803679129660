import ProposalResource from '../resource/PoposalResource';

export default class ProposalService {
  static fetchAll = async (params?: any) => {
    return ProposalResource.fetchAll(params)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  };

  static createProposal = async (data: any) => {
    return ProposalResource.createProposal(data)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  };

  static getProposal = async (proposal: any) => {
    return ProposalResource.getProposal(proposal)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  };

  static postDocument = async (id: string, contentType: string, name: string, documentType: string) => {
    return ProposalResource.postDocuments(id, contentType, name, documentType)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  };

  static uploadAWSService = async (url: string, file: any, cancelFileUpload?: any) => {
    return ProposalResource.uploadAWSService(url, file, cancelFileUpload)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        if (cancelFileUpload) {
          cancelFileUpload.current.setLoading(0);
        }

        return error;
      });
  };

  static deleteDocuments = async (url: string, data: any) => {
    return ProposalResource.deleteDocuments(url, data)
      .then((response) => {
        try {
          return response;
        } catch (err) {
          return err;
        }
      })
      .catch((err) => {
        return err;
      });
  };

  static viewDocuments = async (id: string, data: any) => {
    return ProposalResource.viewDocuments(id, data)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  };

  static fetchLinkSignature = async (documentNumber: string, type: string) => {
    return ProposalResource.fetchLinkSignature(documentNumber, type)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  };

  // All agreements (except FGTS)
  static fetCnpjconsignees = async () => {
    return ProposalResource.fetCnpjconsignees()
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  };

  // FGTS Agreement
  static fetCnpjconsigneesFgts = async () => {
    return ProposalResource.fetCnpjconsigneesFgts()
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  };
}
