import styled, { css } from 'styled-components';

export const ContainerModal = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 98;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.3);
`;
export const ModalContent = styled.div`
  position: relative;
  z-index: 99;
  width: 100%;
  padding: 0 24px;
  margin: 0 auto;
`;
export const ModalHeader = styled.div`
  align-items: center;
`;

export const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 24px 32px;
  border-radius: 4px;
  background-color: white;

  .modal-close {
    border: 0;
    -webkit-appearance: none;
    background: none;
    color: white;
    cursor: pointer;
    margin-left: 10px;
  }
`;
export const ModalTitle = styled.h4`
  font-size: 16px;
  line-height: 24px;
  color: var(--color-black-primary);
  margin-bottom: 20px;
  font-family: 'Clan Web Pro News';
`;

export const Description = styled.p`
  font-size: 16px;
  line-height: 24px;
  color: var(--color-black-primary);
`;
