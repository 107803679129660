import { Navigate } from 'react-router-dom';
import { useAuth } from '../hooks/auth';

interface PropType {
  component: React.FC;
}

const PrivateRoute: React.FC<PropType> = ({ component: Component }) => {
  const { user } = useAuth();
  if (user) return <Component />;
  return <Navigate to="/" />;
};

export default PrivateRoute;
