// CONSTANTS
import tableFilters from '../constants/table-filters';

export const getStatusByStep = (step: any) => {
  const filtred = tableFilters.filter((opt: any) => opt.name === step);

  if ((filtred || []).length === 0) {
    return {};
  }

  let statusList: any = {};

  filtred.forEach((status: any) => {
    statusList = {
      label: status.label,
      color: status.badgeType,
    };
  });

  return statusList;
};
