import React from 'react';

import { ContentScreensError, TitleError, TextError } from './styles';
import { Alert } from './svg';

export const LinkExpired: React.FC = () => {
  return (
    <ContentScreensError className="paddingSides">
      <Alert />
      <TitleError>Oops! Esse link expirou.</TitleError>
      <TextError>
        Nossos links tem validade de 24 horas a partir do envio ao usuário.
        <br /> <br />
        Entre em contato, através do canal de relacionamento do Banco Bari e solicite o reenvio do link de assinatura.
      </TextError>
    </ContentScreensError>
  );
};
