import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;

  min-height: 98px;

  &.background {
    background-color: var(--color-white-primary);
    padding: 24px 32px;
  }
  &.fixed {
    position: fixed;
    bottom: 0;
    padding: 24px;
  }
`;

export const ContainerButton = styled.div`
  width: 100%;
  flex-direction: column;

  > button {
    width: 100%;
  }
  > button:last-child {
    margin-left: 0px !important;
    margin-top: 8px;
  }
`;
