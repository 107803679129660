import styled, { css } from 'styled-components';

interface ContainerProps {
  type?: 'success' | 'error' | 'info';
  hasDescription: boolean;
}
const toastTypeVariations = {
  info: css`
    border-left: 8px #101010 solid;
  `,
  success: css`
    border-left: 8px #6cb92f solid;
  `,
  error: css`
    border-left: 8px #f83d44 solid;
  `,
};

export const Container = styled.div<ContainerProps>`
  display: flex;
  align-items: center;
  position: relative;
  padding: 24px;
  box-shadow: 0px 4px 40px rgba(16, 16, 16, 0.16);
  border-radius: 10px;
  background: #ffffff;
  color: #101010;
  & + div {
    margin-top: 8px;
  }

  ${(props) => toastTypeVariations[props.type || 'info']}

  > svg {
    margin-right: 16px;
  }
  div {
    flex: 1;
    flex-direction: column;
    margin-right: 54px;
    h2 {
      font-weight: 600;
      font-size: 18px;
      line-height: 148%;

      color: #101010;
    }
    p {
      font-size: 16px;
      line-height: 148%;
      color: #585858;
    }
  }
  button {
    border: 0;
    background: transparent;
    color: inherit;
  }

  ${(props) =>
    !props.hasDescription &&
    css`
      align-items: center;
      svg {
        margin-top: 0;
      }
    `}
`;
