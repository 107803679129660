// @ts-nocheck
import React, { useCallback, useState, useEffect } from 'react';

import { useProposal } from '../../../hooks/AppContext';

import { useDebounce } from '../../../hooks/useDebounce';
import { pageSizes } from '../../../constants/grid';
import { Row, Col } from '../../GRID';
import { SearchInput } from '../SearchInput';
import { Pagination } from '../Pagination';
import MediaQuery from '../MediaQuery/MediaQuery';
import TableHeader from './TableHeader';
import TableBody from './TableBody';
import {
  Actions,
  Button as ButtonPage,
  Content,
  Divider,
  Header,
  SelectWrapper,
  TableOverflow,
  TableOverlay,
  TableWrapper,
  Wrapper,
} from './styles';
import { Proposal } from '../../../models/Proposal';
import { ArrowRight, ArrowLeft } from './svgs';
import { Select } from '../Select';

interface TableProps {
  data?: Proposal | any;
  loading?: boolean;
  meta?: any;
  onApplyFilter?: any;
  onChangePage?: any;
  onChangePageSize?: any;
  onOrdering?: any;
  onReload?: any;
  onSearch?: any;
  pageSize?: any;
  setTableSearch?: any;
  tableSearch?: string;
}

const Table = (props: TableProps) => {
  const {
    data,
    loading,
    meta,
    onApplyFilter,
    onChangePage,
    onChangePageSize,
    onOrdering,
    onReload,
    onSearch,
    pageSize,
    setTableSearch,
    tableSearch,
  } = props;

  const { tableConfiguration } = useProposal();
  const [searching, setSearching] = useState(false);
  const debouncedSearchTerm = useDebounce(tableSearch, 500);

  const handleSearch = useCallback((value) => {
    setSearching(true);
    setTableSearch(value.length > 0 ? value : '');
  }, []);

  const handleOrderingTable = useCallback(
    (column, direction) => {
      onOrdering(column, direction);
    },
    [onOrdering],
  );

  const handleChange = useCallback(
    (value: number) => {
      onChangePage(value - 1);
    },
    [onChangePage],
  );

  const handlePrev = useCallback(() => {
    if (!loading) {
      onChangePage(meta.pageNumber - 1);
    }
  }, [meta, onChangePage, loading]);

  const handleNext = useCallback(() => {
    if (!loading) {
      onChangePage(meta.pageNumber + 1);
    }
  }, [meta, onChangePage, loading]);

  const handleChangePageSize = useCallback(
    (e) => {
      onChangePageSize(e.value);
    },
    [onChangePageSize],
  );

  const handleReload = useCallback(() => {
    onReload();
  }, [onReload]);

  useEffect(() => {
    if (debouncedSearchTerm != null && searching) {
      onSearch(debouncedSearchTerm);
      setSearching(false);
    }
  }, [debouncedSearchTerm]);

  const filtredConfig = tableConfiguration.filter((config: any) => config.status);
  let mediaQueryProps: any = { minWidth: 1365 };
  if (filtredConfig?.length > 8) {
    mediaQueryProps = {
      maxWidth: 1,
    };
  }

  return (
    <>
      <Content>
        <Header>
          <Row centralized>
            <Col sm={9} md={9} lg={9}>
              <SearchInput
                name="searchTable"
                id="searchTable"
                type="text"
                placeholder="Procurar"
                className="SearchTable"
                onSearch={handleSearch}
                value={tableSearch}
              />
            </Col>
            <Col sm={9} md={9} lg={9}>
              <Actions>
                <SelectWrapper>
                  <span>Mostrar</span>
                  <Select
                    id="pageSize"
                    name="pageSize"
                    options={pageSizes}
                    defaultValue={pageSize}
                    onChange={handleChangePageSize}
                    isDisabled={loading}
                    isSearch
                  />
                </SelectWrapper>
                <Divider />
                <>
                  <ButtonPage disabled={!meta.hasPreviousPage || loading} onClick={handlePrev} className="Left">
                    <ArrowLeft />
                  </ButtonPage>
                  <ButtonPage disabled={!meta.hasNextPage || loading} onClick={handleNext} className="Right">
                    <ArrowRight />
                  </ButtonPage>
                </>
              </Actions>
            </Col>
          </Row>
        </Header>
        <TableWrapper>
          <MediaQuery {...mediaQueryProps}>
            <TableOverlay forceResponsive={filtredConfig?.length > 8}>
              <TableHeader
                key="table-overlay"
                onApplyFilter={onApplyFilter}
                onOrderingTable={handleOrderingTable}
                isTableOverlay={true}
                forceResponsive={false}
              />
              <TableBody
                items={data}
                onReload={handleReload}
                loading={loading}
                isTableOverlay={true}
                forceResponsive={false}
              />
            </TableOverlay>
          </MediaQuery>
          <TableOverflow forceResponsive={filtredConfig?.length > 8}>
            <Wrapper cellPadding="5" forceResponsive={filtredConfig?.length > 8}>
              <>
                <TableHeader
                  key="table-wrapper"
                  onApplyFilter={onApplyFilter}
                  onOrderingTable={handleOrderingTable}
                  isTableOverlay={false}
                  forceResponsive={filtredConfig?.length > 8}
                />
                <TableBody
                  items={data}
                  onReload={handleReload}
                  loading={loading}
                  isTableOverlay={false}
                  forceResponsive={filtredConfig?.length > 8}
                />
              </>
            </Wrapper>
          </TableOverflow>
        </TableWrapper>
        <Pagination
          loading={loading}
          currentPage={meta.pageNumber}
          totalPages={meta.totalPages}
          nextEnable={meta.hasNextPage}
          prevEnable={meta.hasPreviousPage}
          onChange={handleChange}
          onNext={handleNext}
          onPrev={handlePrev}
        />
      </Content>
    </>
  );
};

export default Table;
