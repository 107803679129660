import React from 'react';
import { isResponsiveFunction } from '../../../styles';
import { Container } from './styles';

interface TooltipProps {
  origin?: string;
  title: string;
  percentAlignSpan?: string;
  children: any;
  active?: boolean;
}

const Tooltip: React.FC<TooltipProps> = ({
  origin = 'card',
  title,
  active,
  percentAlignSpan,
  children,
}: TooltipProps) => {
  return (
    <Container percentAlignSpan={percentAlignSpan} origin={origin} length={title?.length * 1.5 || 1} active={active}>
      {children}
      {origin === 'step-card' && <span>{title}</span>}
      {title?.length > 31 && (origin == 'card' || origin == 'icon') && !isResponsiveFunction() && <span>{title}</span>}
      {title?.length > 16 && origin == 'list' && !isResponsiveFunction() && <span>{title}</span>}
      {title?.length > 19 && (origin == 'card' || origin == 'icon') && isResponsiveFunction() && <span>{title}</span>}
    </Container>
  );
};

export default Tooltip;
