import React from 'react';

// STYES
import {
  BoxDataResume,
  ChangeData,
  ContainerListProposal,
  ContentBoxData,
  DataList,
  ListName,
  ListProposalResume,
  ListValue,
  Paginator,
  ResumeData,
  SubTitleStep,
  TextChange,
  TitleCardResume,
  TitleResumeData,
  TitleStep,
} from '../styles';

// COMPONENTS
import { Col, Row } from '../../../components/GRID';
import { Card } from '../../../components/UI/Card';

// CONTEXT
import { useProposal } from '../../../hooks/AppContext';

// CONSTANTS
import { LoanTypeLabel } from '../../../constants/loan-types';

// ICONS
import { Pencil } from '../svgs';
import { numberFormat } from '../../../utils/formatCurrency';
// INTERFACE
import Proposal from '../../../interfaces/Proposal';

interface IResume {
  formValues: Proposal;
}

export const Resume = ({ formValues }: IResume) => {
  const { setStep } = useProposal();

  const handlePrevstep = () => {
    setStep((previousStep: any) => previousStep - 2);
  };

  return (
    <Row>
      <Col sm={18} md={18} lg={18}>
        <Paginator>4/5</Paginator>
        <TitleStep className="RemoveMarginBotton">Resumo da proposta</TitleStep>
        <SubTitleStep>
          Confira abaixo os dados da proposta. Após o envio, eles não poderão ser editados ou excluídos.
        </SubTitleStep>
        <Row>
          <Col sm={18} md={16} lg={14}>
            <div style={{ flexDirection: 'column' }}>
              <Card>
                <TitleCardResume>Dados do cliente</TitleCardResume>
                <BoxDataResume>
                  <TitleResumeData>Nome do cliente</TitleResumeData>
                  <ResumeData className="name">{formValues.name}</ResumeData>
                </BoxDataResume>
                <ContentBoxData>
                  <div>
                    <BoxDataResume>
                      <TitleResumeData>CPF</TitleResumeData>
                      <ResumeData>{formValues.documentNumber}</ResumeData>
                    </BoxDataResume>
                    <BoxDataResume>
                      <TitleResumeData>Telefone</TitleResumeData>
                      <ResumeData>{formValues.phoneNumber}</ResumeData>
                    </BoxDataResume>
                  </div>
                  <div>
                    <BoxDataResume>
                      <TitleResumeData>Convênio</TitleResumeData>
                      <ResumeData>{formValues.cnpjConsigneeName}</ResumeData>
                    </BoxDataResume>
                  </div>
                </ContentBoxData>
              </Card>
            </div>
          </Col>

          <Col sm={18} md={16} lg={14}>
            <div style={{ flexDirection: 'column' }}>
              <Card>
                <TitleCardResume>proposta(s) Sicred</TitleCardResume>
                <ContainerListProposal>
                  {formValues.loanDetails.map(function (itemLoan: any, idx: any) {
                    return (
                      <ListProposalResume key={idx}>
                        <DataList hasMargin={itemLoan.pu}>
                          <ListName>Tipo de empréstimo</ListName>
                          <ListValue>{LoanTypeLabel[itemLoan.loanType]}</ListValue>
                        </DataList>
                        <DataList hasMargin={itemLoan.pupurchaseMargin}>
                          <ListName>Número da proposta</ListName>
                          <ListValue>{itemLoan.proposalNumber}</ListValue>
                        </DataList>
                        {itemLoan.purchaseMargin && (
                          <DataList hasMargin={itemLoan.pupurchaseMargin}>
                            <ListName>Margem para compras</ListName>
                            <ListValue>{numberFormat(itemLoan.purchaseMargin)}</ListValue>
                          </DataList>
                        )}
                      </ListProposalResume>
                    );
                  })}
                </ContainerListProposal>
              </Card>
              <ChangeData onClick={handlePrevstep}>
                <Pencil />
                <TextChange>Corrigir dados digitados</TextChange>
              </ChangeData>
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
