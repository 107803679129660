import { ReactElement, useCallback } from 'react';

// COMMPONENTS
import { Steps } from '../../components/UI/Steps';
import { Footer } from './Footer';
import StepDisplay from './Steps/Steps';

// STYLES
import { Container, Content, ContentStep } from './styles';

// MODEL
import { Proposal } from '../../models/Proposal';

//CONTEXT
import { useProposal } from '../../hooks/AppContext';

const Form = (): ReactElement => {
  const { prevStepDisabled, nextStepDisabled, stepLoading, formOpened, setFormOpened, step, prevStep, nextStep } =
    useProposal();

  const data = new Proposal(formOpened);

  const handleFormInput = useCallback(
    (e: any) => {
      if (e.name === 'cnpjConsigneeId') {
        setFormOpened((prev) => ({
          ...prev,
          cnpjConsigneeId: e.value,
          cnpjConsigneeName: e.label,
          loanType: '',
          proposalNumber: '',
          purchaseMargin: '',
          hasConsignCard: e.hasConsignCard,
          limitMultiply: e.limitMultiply,
        }));
      } else if (e.currentTarget?.name === 'type') {
        setFormOpened((prev) => ({ ...prev, [e.currentTarget.name]: e.currentTarget.value }));
      } else if (e?.name === 'purchaseMargin') {
        const handlerLoanDetails = (prev) => {
          return prev.loanDetails.map((loanDetail) => {
            if (Number(loanDetail.proposalNumber) === Number(prev.proposalNumber)) {
              return {
                ...loanDetail,
                purchaseMargin: e.value,
              };
            }

            return loanDetail;
          });
        };
        setFormOpened((prev) => ({ ...prev, loanDetails: handlerLoanDetails(prev) }));
      }
      setFormOpened((prev) => ({
        ...prev,
        [e.name]: e.value,
      }));
    },
    [data],
  );

  const stepsArray = [
    {
      label: 'Produto',
    },
    {
      label: 'Dados da proposta',
    },
    {
      label: 'Documentos',
    },
    {
      label: 'Resumo da proposta',
    },
    {
      label: 'Confirmação',
    },
  ];

  return (
    <Container>
      <Steps currentStep={step} steps={stepsArray} />

      <Content>
        <ContentStep className={` ${step === 5 ? 'confirmation' : ''}`}>
          <StepDisplay currentStep={step} onChange={handleFormInput} formValues={data} />
        </ContentStep>

        {step !== 5 ? (
          <Footer
            prevStep={prevStep}
            nextStep={nextStep}
            currentStep={step}
            nextStepDisabled={nextStepDisabled}
            prevStepDisabled={prevStepDisabled}
            loading={stepLoading}
          />
        ) : (
          ''
        )}
      </Content>
    </Container>
  );
};

export default Form;
