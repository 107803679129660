import styled from 'styled-components';

export const ContainerCardProposal = styled.div`
  background-color: var(--color-white-primary);
  padding: 24px 24px 0 24px;
  border-radius: 10px;
  margin-top: 16px;
  flex-direction: column;

  &.selected {
    border: 2px solid var(--color-blue-primary);
  }
`;

export const HeaderCardProposal = styled.div`
  align-items: center;
  margin-bottom: 16px;
  &.remove-margin {
    margin-bottom: 0;
  }
`;
export const Icon = styled.div`
  background: #f6f6f6;
  border-radius: 6px;
  min-width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
`;
export const TitleCardProposal = styled.h2`
  font-size: 16px;
  line-height: 24px;
  font-family: 'Clan Web Pro Medium';
  color: var(--color-black-primary);
  margin-left: 16px;
`;

export const DescriptionContent = styled.div`
  justify-content: space-between;
  padding-bottom: 24px;
  flex-direction: column;
  gap: 10px;
`;
export const DescriptionValueProposal = styled.div`
  flex-direction: column;
  font-family: 'Clan Web Pro Book';
`;
export const TextValueProposal = styled.p`
  font-size: 12px;
  line-height: 24px;
  color: #8e8e8e;
`;
export const ValueProposal = styled.p`
  font-size: 14px;
  line-height: 21px;
  color: var(--color-black-primary);
  font-family: 'Clan Web Pro News';
`;
// Accordion
export const AccordionContent = styled.div`
  background-color: white;
  overflow: hidden;
  transition: max-height 0.6s ease;
  width: 100%;
  flex-direction: column;
`;

export const AccordionCard = styled.div`
  position: relative;
  padding: 20px 0;
  border-top: 1px solid #f6f6f6;
  &.remove-border-top {
    border-top: 0px;
  }
  &.accordion-card-fgts {
    border-top: 0px;
    padding-top: 0px;
  }
  .row-accordion {
    position: absolute;
    left: 20px;
    border-left: 1px solid #e5e5e5;
    z-index: 1;
    height: 100%;
  }
  :last-child {
    [class~='row-accordion'] {
      display: none;
    }
  }
`;

export const IconAccordion = styled.div`
  background: #f6f6f6;
  border-radius: 6px;
  min-width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
`;
// FTGS
export const SplitChart = styled.div`
  background: #f6f6f6;
  border-radius: 6px;
  width: 22px;
  height: 22px;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  margin-left: 10px;
  z-index: 2;
`;
// FTGS

export const AccordionContentText = styled.div`
  flex-direction: column;
  > div {
    margin-bottom: 8px;
    :last-child {
      margin-bottom: 0px;
    }
  }
  &.action {
    width: 100%;
    justify-content: space-between;
    flex-direction: row;
  }
`;
export const AccordionText = styled.div`
  flex-direction: column;
`;

export const Paragraph = styled.p`
  font-size: 12px;
  line-height: 24px;
  color: #8e8e8e;
`;
export const SubParagraph = styled.p`
  font-size: 14px;
  line-height: 21px;
  color: var(--color-black-primary);
  font-family: 'Clan Web Pro News';
  &.color-text {
    color: var(--color-blue-primary);
  }
`;

export const FooterCardProposal = styled.div`
  flex-direction: column;
  margin-top: 18px;
`;

export const ActionCollapse = styled.div`
  font-size: 14px;
  line-height: 32px;
  color: var(--color-blue-primary);
  justify-content: center;
  align-items: center;
  svg {
    margin-right: 8px;
  }
  &.rotation {
    svg {
      transform: rotate(180deg);
    }
  }
  &.add-margin {
    margin: 14px 0;
  }
`;

// FGTS

export const TitleFgts = styled.h2`
  font-size: 12px;
  line-height: 150%;
  color: #8a8d8f;
`;
export const SubTitleFgts = styled.h4`
  font-size: 14px;
  line-height: 150%;
  color: var(--color-black-primary);
  font-family: 'Clan Web Pro Medium';
`;
