import React from 'react';

/**
 * @author Matheus Vieira <matheus.vieira@deal.com.br>
 * @since 2020-11-20
 */
export const Arrows = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      opacity="0.7"
      d="M21.8263 21.5634L25.2354 16.5634C25.4671 16.2236 25.4671 15.7766 25.2354 15.4368L21.8263 10.4368C21.5152 9.98045 20.8931 9.86275 20.4368 10.1739C19.9805 10.485 19.8628 11.1071 20.1739 11.5634L23.15 16L20.1739 20.4368C19.8628 20.8931 19.9805 21.5152 20.4368 21.8263C20.8931 22.1374 21.5152 22.0197 21.8263 21.5634Z"
      fill="#0090FF"
    />
    <path
      opacity="0.4"
      d="M15.8263 21.5634L19.2354 16.5634C19.4671 16.2236 19.4671 15.7766 19.2354 15.4368L15.8263 10.4368C15.5152 9.98045 14.8931 9.86275 14.4368 10.1739C13.9805 10.485 13.8628 11.1071 14.1739 11.5634L17.15 16L14.1739 20.4368C13.8628 20.8931 13.9805 21.5152 14.4368 21.8263C14.8931 22.1374 15.5152 22.0197 15.8263 21.5634Z"
      fill="#0090FF"
    />
    <path
      opacity="0.2"
      d="M9.82634 21.5634L13.2354 16.5634C13.4671 16.2236 13.4671 15.7766 13.2354 15.4368L9.82634 10.4368C9.51522 9.98045 8.89309 9.86275 8.43678 10.1739C7.98047 10.485 7.86277 11.1071 8.17389 11.5634L11.15 16L8.17389 20.4368C7.86277 20.8931 7.98047 21.5152 8.43678 21.8263C8.89309 22.1374 9.51522 22.0197 9.82634 21.5634Z"
      fill="#0090FF"
    />
    <path
      opacity="0.1"
      d="M3.82634 21.5634L7.23544 16.5634C7.46713 16.2236 7.46713 15.7766 7.23544 15.4368L3.82634 10.4368C3.51522 9.98045 2.89309 9.86275 2.43678 10.1739C1.98047 10.485 1.86277 11.1071 2.17389 11.5634L5.15 16L2.17389 20.4368C1.86277 20.8931 1.98047 21.5152 2.43678 21.8263C2.89309 22.1374 3.51522 22.0197 3.82634 21.5634Z"
      fill="#0090FF"
    />
    <path
      d="M27.8263 21.5634L31.2354 16.5634C31.4671 16.2236 31.4671 15.7766 31.2354 15.4368L27.8263 10.4368C27.5152 9.98045 26.8931 9.86275 26.4368 10.1739C25.9805 10.485 25.8628 11.1071 26.1739 11.5634L29.15 16L26.1739 20.4368C25.8628 20.8931 25.9805 21.5152 26.4368 21.8263C26.8931 22.1374 27.5152 22.0197 27.8263 21.5634Z"
      fill="#0090FF"
    />
  </svg>
);
