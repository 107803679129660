import React from 'react';

// COMPONENTS
import { Button } from '../../Button';

// STYLES
import { ActionButtons, ModalContainer, ModalContent, ModalTitle, ModalSubtitle } from '../styles';

// SVGS
import { ErrorProposal } from '../svgs';

interface IModalPendenceDocuments {
  onClose: any;
}

const ModalPendenceDocuments = ({ onClose }: IModalPendenceDocuments) => {
  return (
    <ModalContent>
      <ModalContainer>
        <ErrorProposal />
        <ModalTitle>Erro </ModalTitle>
      </ModalContainer>
      <ModalSubtitle>
        Pendências devem ser resolvidas individualmente, uma proposta por vez. Por favor, adicione apenas uma proposta
        com pendência e tente novamente
      </ModalSubtitle>
      <ActionButtons className="left">
        <Button onClick={onClose} big black textLeft>
          OK
        </Button>
      </ActionButtons>
    </ModalContent>
  );
};

export default ModalPendenceDocuments;
