// const token = localStorage.getItem('@BariConsig:token')

import axios from '../axios';
import logger from '../utils/logger.utils';

// TODO: create file starts configs
axios.interceptors.request.use(
  async (config: any) => {
    const token = await localStorage.getItem('@BariConsig:token');
    // BYPASS S3
    if (config.url.indexOf('s3.amazonaws.com') > -1) return config;

    config.headers.Authorization = `Bearer ${token}`;

    return config;
  },
  (error) => {
    // Catch errors
    return Promise.reject(error);
  },
);

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.message === 'Network Error' && !error.response) {
      logger.error('Network error');
    }

    const status = error.response ? error.response.status : 400;

    switch (status) {
      case 401:
        logger.warn('Error 401, Unauthorized');
        localStorage.removeItem('@BariConsig:user');
        document.location.reload();
        break;
      case 404:
        logger.warn('Error 404, Not found');
        break;
      case 403:
        logger.warn('Error 403, Forbidden  server refuses to run ');
        break;
      case 500:
        logger.warn('Error 500, Server error - check the terminal for more info');
        break;
      default:
        return Promise.reject(error);
    }
  },
);

export default axios;
