import React, { useEffect } from 'react';

// CONTEXT
import { useSignature } from '../../../../hooks/SignatureContext';

import {
  ContainerSelfieTips,
  HeaderSteps,
  TitleSteps,
  TitleContentSelfieTips,
  ContentSelfieTips,
  ItemSfieTips,
  IconsSelfieTipsIcons,
  ParagraphSelfieTips,
} from '../styles';

import { ArrowLeft, Selfie, Crosshairs, Emoji, Glasses, Eye } from '../svgs';

export const SelfieTips: React.FC = ({ formValues }: any) => {
  const { setStep, setNextStepDisabled } = useSignature();

  const ItemsTips = [
    {
      paragraph: 'A foto deve ser tirada de frente',
      icon: <Selfie />,
    },
    {
      paragraph: 'O rosto deve estar centralizado',
      icon: <Crosshairs />,
    },
    {
      paragraph: 'Não sorria ou faça caretas',
      icon: <Emoji />,
    },
    {
      paragraph: 'Sem óculos, chapéu, boné ou máscara.',
      icon: <Glasses />,
    },
    {
      paragraph: 'Posicione o celular na altura dos olhos',
      icon: <Eye />,
    },
  ];

  const handlePrevstep = () => {
    setStep((previousStep: any) => previousStep - 1);
  };

  useEffect(() => {
    setNextStepDisabled(false);
  }, []);

  return (
    <ContainerSelfieTips>
      <HeaderSteps>
        <button onClick={handlePrevstep}>
          <ArrowLeft />
        </button>
        <TitleSteps>Selfie</TitleSteps>
      </HeaderSteps>
      <ContentSelfieTips>
        <TitleContentSelfieTips>
          Agora, precisamos que você tire uma foto de seu rosto. Confira dicas para tirar uma boa foto:
        </TitleContentSelfieTips>
        {ItemsTips.map((item: any, idx) => {
          return (
            <ItemSfieTips key={idx}>
              <IconsSelfieTipsIcons>{item.icon}</IconsSelfieTipsIcons>
              <ParagraphSelfieTips>{item.paragraph}</ParagraphSelfieTips>
            </ItemSfieTips>
          );
        })}
      </ContentSelfieTips>
    </ContainerSelfieTips>
  );
};
