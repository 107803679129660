import React from 'react';

/**
 * @author Matheus Vieira <matheus.vieira@deal.com.br>
 * @since 2021-01-26
 */
export const ActionIconWhite = () => (
  <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 1.5C0 1.22386 0.223858 1 0.5 1H7.5C7.77614 1 8 1.22386 8 1.5C8 1.77614 7.77614 2 7.5 2H0.5C0.223858 2 0 1.77614 0 1.5Z"
      fill="white"
    />
    <path
      d="M1 4.5C1 4.22386 1.22386 4 1.5 4H6.5C6.77614 4 7 4.22386 7 4.5C7 4.77614 6.77614 5 6.5 5H1.5C1.22386 5 1 4.77614 1 4.5Z"
      fill="white"
    />
    <path
      d="M2.5 7C2.22386 7 2 7.22386 2 7.5C2 7.77614 2.22386 8 2.5 8H5.5C5.77614 8 6 7.77614 6 7.5C6 7.22386 5.77614 7 5.5 7H2.5Z"
      fill="white"
    />
  </svg>
);

export const ActionIcon = () => (
  <svg width="9" height="8" viewBox="0 0 9 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.00195312 1.5C0.00195312 1.22386 0.225811 1 0.501953 1H7.50195C7.7781 1 8.00195 1.22386 8.00195 1.5C8.00195 1.77614 7.7781 2 7.50195 2H0.501953C0.225811 2 0.00195312 1.77614 0.00195312 1.5Z"
      fill="#646464"
    />
    <path
      d="M1.00195 4.5C1.00195 4.22386 1.22581 4 1.50195 4H6.50195C6.7781 4 7.00195 4.22386 7.00195 4.5C7.00195 4.77614 6.7781 5 6.50195 5H1.50195C1.22581 5 1.00195 4.77614 1.00195 4.5Z"
      fill="#646464"
    />
    <path
      d="M2.50195 7C2.22581 7 2.00195 7.22386 2.00195 7.5C2.00195 7.77614 2.22581 8 2.50195 8H5.50195C5.7781 8 6.00195 7.77614 6.00195 7.5C6.00195 7.22386 5.7781 7 5.50195 7H2.50195Z"
      fill="#646464"
    />
  </svg>
);
