import styled from 'styled-components';

export const LoginContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  > div {
    max-width: 500px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  form {
    width: 100%;
  }
  .Alert {
    margin-bottom: 24px;
  }
  > svg {
    margin-bottom: 32px;
  }
  button {
    display: flex;
    width: 100%;
    > div {
      justify-content: space-between;
    }
  }
`;

export const Title = styled.h1`
  color: var(--color-black-primary);
  font-size: 16px;
  margin-bottom: 28px;
  width: 100%;
  font-family: 'Clan Web Pro News';
`;
