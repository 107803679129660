import styled from 'styled-components';
import checkIcon from '../../../static/icons/check-icon-white.svg';

/**
 * @author Matheus Vieira <matheus.vieira@deal.com.br>
 * @since 2020-11-23
 */

export const Container = styled.div`
  display: block;
  margin-bottom: 20px;
  width: 100%;
`;

export const Label = styled.label``;

export const Field = styled.input`
  display: none;
  + label {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-family: 'Clan Web Pro News', sans-serif;
    font-size: 14px;
    color: #101010;
    &:before {
      content: url(${checkIcon});
      float: left;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 24px;
      border: 2px solid #c2c2c2;
      box-sizing: border-box;
      border-radius: 6px;
      margin-right: 8px;
      color: #ffffff;
      transition: 0.2s;
    }
    &:not(:disabled):active:before {
      transform: scale(0);
    }
  }
  &:disabled + label {
    cursor: not-allowed;
    &:before {
      background-color: #c2c2c2;
    }
  }
  &:not(:disabled):hover + label:before {
    background-color: #0090ff;
    border-color: #0090ff;
  }
  &:not(:disabled):checked + label:before {
    background-color: #0090ff;
    border-color: #0090ff;
  }
`;
