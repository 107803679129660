import React, { ReactElement } from 'react';

export const ArrowRight = (): ReactElement => {
  return (
    <svg width="23" height="12" viewBox="0 0 23 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.8263 11.5634L22.2354 6.56343C22.4671 6.22361 22.4671 5.77658 22.2354 5.43676L18.8263 0.43676C18.5152 -0.0195528 17.8931 -0.137253 17.4368 0.17387C16.9804 0.484992 16.8627 1.10712 17.1739 1.56343L19.517 5H1C0.447715 5 0 5.44772 0 6C0 6.55228 0.447715 7 1 7H19.5171L17.1739 10.4368C16.8627 10.8931 16.9804 11.5152 17.4368 11.8263C17.8931 12.1374 18.5152 12.0197 18.8263 11.5634Z"
        fill="white"
      />
    </svg>
  );
};

export const ArrowLeft = (): ReactElement => {
  return (
    <svg width="23" height="12" viewBox="0 0 23 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.58286 11.5634L0.173773 6.56343C-0.0579243 6.22361 -0.0579243 5.77658 0.173773 5.43676L3.58286 0.43676C3.89399 -0.0195528 4.51612 -0.137253 4.97243 0.17387C5.42874 0.484992 5.54644 1.10712 5.23532 1.56343L2.8922 5H21.4092C21.9615 5 22.4092 5.44772 22.4092 6C22.4092 6.55228 21.9615 7 21.4092 7H2.89207L5.23532 10.4368C5.54644 10.8931 5.42874 11.5152 4.97243 11.8263C4.51612 12.1374 3.89399 12.0197 3.58286 11.5634Z"
        fill="#101010"
      />
    </svg>
  );
};

export const ActionSelfie = (): ReactElement => {
  return (
    <svg width="118" height="118" viewBox="0 0 118 118" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d)">
        <circle cx="59" cy="55" r="55" fill="white" />
        <circle cx="59" cy="55" r="55" fill="white" />
        <circle cx="59" cy="55" r="46" fill="white" />
        <circle cx="59" cy="55" r="46" fill="#EAEAEA" />
        <circle cx="59" cy="55" r="41" fill="white" />
        <circle cx="59" cy="55" r="41" fill="white" />
      </g>
      <defs>
        <filter
          id="filter0_d"
          x="0"
          y="0"
          width="118"
          height="118"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
      </defs>
    </svg>
  );
};
