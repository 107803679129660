import React, { useRef } from 'react';
import ReactSelect, { OptionProps, Props as SelectProps } from 'react-select';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import { Container, Label, Error } from './styles';

interface Props extends SelectProps<OptionProps> {
  id?: string;
  name: string;
  label?: string;
  subLabel?: string;
  defaultValue?: any;
  containerType?: any;
  options: any;
  value?: any;
}

const Select: React.FC<Props> = ({ name, label, subLabel, containerType, options, value, defaultValue, ...rest }) => {
  const customStyles = {
    container: (provided: any, state: any) => ({
      ...provided,
      flexDirection: 'column',
      width: '100%',
    }),
    control: (styles: any, state: any) => ({
      ...styles,
      width: '100%',
      borderRadius: '8px',
      padding: '8px',
      fontSize: '13px',
      '&:hover': {
        border: '1px solid #E5E5E5',
      },
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      fontFamily: state.isSelected ? 'Clan Web Pro Medium' : 'Clan Web Pro News',
      flexDirection: 'column',
      backgroundColor: '#FFFFFF',
      color: state.isSelected ? '#101010' : '#8E8E8E',
      padding: '8px 24px',
      fontSize: '13px',
      lineHeight: '24px',
      '&:hover': {
        color: '#101010',
        border: '1px solid #E5E5E5',
        borderLeft: 0,
        borderRight: 0,
        backgroundColor: 'rgba(246, 246, 246);',
      },
    }),

    valueContainer: () => ({
      padding: '2px 0',
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    menuList: (styles: any) => ({
      ...styles,
      flexDirection: 'column',
      width: '100%',
    }),
    menu: () => ({
      marginTop: '8px',
      border: '1px solid #F6F6F6',
      borderRadius: '8px',
      boxShadow: '0px 10px 30px 10px rgba(0, 0, 0, 0.07)',
    }),
  };

  return (
    <Container containerType={containerType}>
      <Label htmlFor={name}>{label}</Label>
      <ReactSelect
        styles={customStyles}
        placeholder="Escolha uma profissão"
        name={name}
        isSearchable={false}
        isClearable={false}
        classNamePrefix="react-select"
        options={options}
        menuPlacement="bottom"
        value={value || defaultValue}
        {...rest}
      />
    </Container>
  );
};

export default Select;
