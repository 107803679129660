import SignatureInterface from '../interfaces/Signature';

export class Signature {
  public id: string;
  public partnerId: string;
  public customerName: string;
  public documentNumber: Number;
  public type: string;
  public customerIP: string;
  public customerLatitude: string;
  public customerLongitude: string;
  public token: string;
  public base64: string;
  public selfieId: string;
  public proposals: Array<any>;
  public logicalGate: string;
  public operatingSystem: string;
  public browser: string;
  public terms: string[];

  constructor(data: SignatureInterface) {
    this.id = data?.id;
    this.partnerId = data?.partnerId;
    this.customerName = data?.customerName;
    this.documentNumber = data?.documentNumber;
    this.type = data?.type;
    this.customerIP = data?.customerIP;
    this.customerLatitude = data?.customerLatitude;
    this.customerLongitude = data?.customerLongitude;
    this.token = data?.token;
    this.base64 = data?.base64;
    this.selfieId = data?.selfieId;
    this.proposals = data?.proposals ?? [];
    this.logicalGate = data?.logicalGate;
    this.operatingSystem = data?.operatingSystem;
    this.browser = data?.browser;
    this.terms = data?.terms ?? [];

    return this;
  }
}
