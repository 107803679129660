import React, { ReactElement, useCallback, useEffect, useState } from 'react';

// COMPONENTS
import { If } from '../../Conditions';
import { Filter } from '../Filter';
import MediaQuery from '../MediaQuery/MediaQuery';

// CONTEXT
import tableFilters from '../../../constants/table-filters';

// CONTEXT
import { useProposal } from '../../../hooks/AppContext';

// UTILS
import { removeDuplicated } from '../../../utils/table';

// STYLES
import { Th, Tr } from './styles';

interface ComponentProps {
  forceResponsive: boolean;
  isTableOverlay: boolean;
  onApplyFilter: any;
  onOrderingTable: any;
}

const TableHeader = ({
  forceResponsive,
  isTableOverlay,
  onApplyFilter,
  onOrderingTable,
}: ComponentProps): ReactElement => {
  const { tableConfiguration } = useProposal();
  const [selectedSteps, setSelectedSteps] = useState(tableFilters);
  const [selectedStatus, setSelectedStatus] = useState([]);

  const handleOrderColumn = useCallback(
    (e) => {
      const data = e.target.closest('th');
      const direction = data.dataset.dir;
      document.querySelectorAll('thead th').forEach((item) => {
        item.classList.remove('Desc');
        item.classList.remove('Asc');
      });
      if (direction == 'Desc') {
        data.classList.remove('Desc');
        data.classList.add('Asc');
      } else {
        data.classList.remove('Asc');
        data.classList.add('Desc');
      }
      data.setAttribute('data-dir', direction == 'Desc' ? 'Asc' : 'Desc');
      onOrderingTable(data.dataset.attr, data.dataset.dir);
    },
    [onOrderingTable],
  );

  const handleApplyFilters = useCallback(
    (column, options) => {
      const filtered = options.filter((option: any) => option.active);

      if (column === 'proposalStep') {
        setSelectedSteps(filtered);
      }

      const filterStep = column === 'proposalStep' ? filtered : selectedSteps;
      const filterStatus = column === 'signatureStatus' ? filtered : [];

      onApplyFilter(filterStep, filterStatus);
    },
    [onApplyFilter, selectedSteps],
  );

  useEffect(() => {
    const status = selectedSteps.map((item: any) => {
      return item.status;
    });

    setSelectedStatus(removeDuplicated(status.flat(1)));
  }, [selectedSteps]);

  const options: any = {
    1: tableFilters,
    2: selectedStatus,
  };

  return (
    <thead>
      <Tr className="Header">
        {tableConfiguration
          ?.filter((config: any) => config.status)
          .map((config: any, index: number) => {
            const { className, hasOptions, id, label, panelId } = config;

            if (className === 'ProposalNumber' || className === 'Name' || className === 'LoanType') {
              const column =
                className === 'Filter' ? (
                  <Th className={className}>
                    <Filter
                      title={label}
                      panelId={panelId}
                      options={hasOptions && options[id]}
                      onApply={handleApplyFilters}
                    />
                  </Th>
                ) : (
                  <Th key={index} className={className} data-attr={panelId} data-dir="Desc" onClick={handleOrderColumn}>
                    <label>{label}</label>
                  </Th>
                );

              if (isTableOverlay) {
                return <React.Fragment key={index}>{column}</React.Fragment>;
              }

              if (!isTableOverlay && !forceResponsive) {
                return (
                  <React.Fragment key={index}>
                    <MediaQuery key={index} maxWidth={1365}>
                      {column}
                    </MediaQuery>
                  </React.Fragment>
                );
              }
            } else {
              if (!isTableOverlay) {
                return (
                  <Th key={index} className={className} data-attr={panelId} data-dir="Desc" onClick={handleOrderColumn}>
                    <label>{label}</label>
                  </Th>
                );
              }
            }
          })}
        <If condition={true}>
          <Th className="Actions" data-attr="actions">
            <label></label>
          </Th>
        </If>
      </Tr>
    </thead>
  );
};

export default TableHeader;
