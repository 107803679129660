import React, { useEffect } from 'react';

import { ToastMessage, useToast } from '../../../../hooks/ToastContext';

import { Container } from './styles';
import { Sucess, Warning, Generic, Close } from './svgs';

interface ToastProps {
  message: ToastMessage;
}

const icons = {
  info: <Generic />,
  error: <Warning />,
  success: <Sucess />,
};

const Toast: React.FC<ToastProps> = ({ message }) => {
  const { removeToast } = useToast();

  useEffect(() => {
    const timer = setTimeout(() => {
      removeToast(message.id);
    }, 3000);

    return () => {
      clearTimeout(timer);
    };
  }, [removeToast, message.id]);
  return (
    <Container type={message.type} hasDescription={!!message.description}>
      {icons[message.type || 'info']}
      <div>
        <h2>{message.title}</h2>
        <p>{message.description}</p>
      </div>
      <button onClick={() => removeToast(message.id)} type="button">
        <Close />
      </button>
    </Container>
  );
};

export default Toast;
