import React, { useState } from 'react';
import { Content, Container, Overview, Title } from './styles';
import { Card } from '../../components/UI/Card';
import { IlustacaoSvg } from './svgs';

const Dashboard: React.FC = () => {
  return (
    <Container>
      <Content>
        <Overview>
          <Card>
            <IlustacaoSvg />
            <Title>
              <span>Bem vindo </span>
              ao Portal Bari Consig.
            </Title>
          </Card>
        </Overview>
      </Content>
    </Container>
  );
};

export default Dashboard;
