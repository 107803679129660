import React from 'react';
import { Container, Dot } from './style';

// ENUMS
import { Themes } from '../../../enums/themes';

/**
 * @author Matheus Vieira <matheus.vieira@deal.com.br>
 * @since 2020-11-19
 */
interface badgeProps {
  label: any;
  className?: string;
  hasKebab?: boolean;
  color?: Themes;
}

const Badge = (props: badgeProps) => {
  const { label, ...rest } = props;

  return (
    <Container {...rest}>
      <Dot {...rest} />
      <span>{label}</span>
    </Container>
  );
};

export default Badge;
