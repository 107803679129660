import React from 'react';

// STYLES
import { Close, ModalContainer, ModalContent, ModalTitle, ModalSubtitle } from '../styles';

// SVGS
import { Close as CloseIcon } from '../svgs';

import img from '../../../../assets/img-portal/Banner-Black-Friday-Consignado.jpg';

interface IModalBannerProposal {
  onClose: any;
}

const ModalBanner = ({ onClose }: IModalBannerProposal) => {
  return (
    <ModalContent banner>
      <ModalContainer>
        <Close onClick={onClose}>
          <CloseIcon />
        </Close>

        <img src={img} alt="" width="680px" />
      </ModalContainer>
    </ModalContent>
  );
};

export default ModalBanner;
