// @ts-nocheck
import React from 'react';

// Service
import { useSignature } from '../../../../hooks/SignatureContext';

//Component
import Camera from '../../Cam/Cam';
import { ErrorPermissions } from '../../ScreensError/index';

import { ContainerSelfie } from '../styles';

// SVG
import { IconErrorPermissionCam } from '../../ScreensError/svg';

export const Selfie: React.FC = ({ formValues }: any) => {
  const { errorCam } = useSignature();

  return (
    <ContainerSelfie className={`banner ${errorCam ? 'errorPermissionCam' : ''}`}>
      {errorCam ? (
        <ErrorPermissions
          titlePage={'Selfie'}
          cardTitle={'Sem acesso à câmera'}
          cardFooterlink={'https://support.google.com/chrome/answer/2693767?hl=pt-BR&co=GENIE.Platform%3DAndroid&oco=0'}
          cardFooterText={'Como permitir acesso à câmera'}
          icon={IconErrorPermissionCam}
        >
          Precisamos ter acesso a câmera do seu celular para tirar uma selfie e assinar a confirmar a sua assinatura.
          <br />
          <br />
          Libere acesso a câmera para continuar
        </ErrorPermissions>
      ) : (
        <Camera formValues={formValues} />
      )}
    </ContainerSelfie>
  );
};
