export enum Modals {
  CONFIRMATIONPROPOSAL = 'ConfirmationProposal',
  ERRORPROPOSAL = 'ErrorProposal',
  ERRORPROPOSALGENERIC = 'ErrorProposalGeneric',
  ERRORDOCUMENT = 'ErrorDocument',
  ERRORPROPOSALINCONSISTENT = 'ErrorProposalInconsistent',
  ANALYSISPROPOSAL = 'AnalysisProposal',
  PENDINGDOCUMENT = 'PendingDocument',
  LINKPROPOSAL = 'LinkProposal',
  MODALBANNER = 'ModalBanner',
  MODALPENDENCEDOCUMENTS = 'ModalPendenceDocuments',
  MODALPURCHASEMARGIN = 'ModalPurchaseMargin',
  MODALPURCHASEMARGINLIMIT = 'ModalPurchaseMarginLimit',
}
