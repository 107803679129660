import React from 'react';
import { Container } from './styles';

interface ComponentProps {
  children?: any;
  className: string;
  height?: number;
  minWidthOf10px?: boolean;
  minWidthOf147px?: boolean;
  width?: number;
}

const Skeleton = (props: ComponentProps) => {
  return <Container {...props} />;
};

export default Skeleton;
