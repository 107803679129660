import React from 'react';

import { Container, Icon, Content, Title, Paragraph } from './styles';

interface AlertProps {
  title?: string;
  description?: string;
  red?: boolean;
  show?: boolean;
  className?: string;
  children?: React.ReactNode;
}

const Check = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="5" y="5" width="22" height="22" rx="11" stroke="#F83D44" strokeWidth="2" />
      <path
        d="M20.2662 12.5C20.4558 12.5 20.6376 12.5729 20.7711 12.7026C20.917 12.8322 21 13.0147 21 13.2059C21 13.3971 20.917 13.5796 20.7711 13.7093L14.9686 19.2974C14.8351 19.4271 14.6534 19.5 14.4638 19.5C14.2742 19.5 14.0924 19.4271 13.9589 19.2974L11.2283 16.6677C11.0378 16.4948 10.9586 16.2366 11.0209 15.9917C11.0832 15.7469 11.2775 15.5532 11.5294 15.4848C11.7814 15.4163 12.052 15.4836 12.2381 15.661L14.4638 17.8114L19.7613 12.7026C19.8949 12.5729 20.0766 12.5 20.2662 12.5Z"
        fill="#F83D44"
        stroke="#F83D44"
      />
    </svg>
  );
};

const Alert: React.FC<AlertProps> = ({ show, children, ...props }) => {
  return (
    <Container className={props.className} {...props}>
      <Icon>
        <Check />
      </Icon>
      <Content>
        <Title>{props.title}</Title>
        <Paragraph>{props.description}</Paragraph>
      </Content>
    </Container>
  );
};

export default Alert;
