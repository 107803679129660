// ENUMS
import { Status } from '../enums/proposal-status.enum';
import { Themes } from '../enums/themes';

export default [
  { id: 0, badgeType: Themes.WARNING, name: Status.CREATED, label: 'Proposta Criada.', active: true },

  {
    id: 1,
    badgeType: Themes.PRIMARY,
    name: Status.AWAITING_SIGNATURE,
    label: 'Aguardando ASS.',
    active: true,
  },
  { id: 2, badgeType: Themes.PRIMARY, name: Status.AWAITING_CONDITIONS, label: 'Aguardando COND.', active: true },
  {
    id: 3,
    badgeType: Themes.PRIMARY,
    name: Status.AWAITING_CHECKING_ACCOUNT,
    label: 'Aguardando CONTA.',
    active: true,
  },
  {
    id: 4,
    badgeType: Themes.DEFAULT,
    name: Status.FORMALIZATION,
    label: 'Em análise.',
    active: true,
  },
  {
    id: 5,
    badgeType: Themes.SECONDARY,
    name: Status.MADE_EFFECTIVE,
    label: 'Efetivado.',
    active: true,
  },
  {
    id: 6,
    badgeType: Themes.INFO,
    name: Status.REJECTED,
    label: 'Negado.',
    active: true,
  },
  {
    id: 7,
    badgeType: Themes.WARNING,
    name: Status.PENDING_DOCUMENTS,
    label: 'PENDÊNCIA DOC.',
    active: true,
  },
  {
    id: 8,
    badgeType: Themes.WARNING,
    name: Status.PENDING_SIGN,
    label: 'PENDÊNCIA ASS.',
    active: true,
  },
  {
    id: 9,
    badgeType: Themes.WARNING,
    name: Status.PENDING_DOCUMENTS_SIGN,
    label: 'PENDÊNCIAS.',
    active: true,
  },
  {
    id: 10,
    badgeType: Themes.INFO,
    name: Status.REFUSED,
    label: 'RECUSADO.',
    active: true,
  },
  {
    id: 11,
    badgeType: Themes.DEFAULT,
    name: Status.ENDORSED,
    label: 'EM ANÁLISE.',
    active: true,
  },
  {
    id: 12,
    badgeType: Themes.WARNING,
    name: Status.PENDING_PURCHASE_MARGIN,
    label: 'PENDÊNCIA MARGEM',
    active: true,
  },
  {
    id: 13,
    badgeType: Themes.WARNING,
    name: Status.PENDING_PURCHASE_MARGIN_WRONG_PROPOSAL,
    label: 'MARGEM PROP. ERRADA',
    active: true,
  },
];
