import React from 'react';

/**
 * @author Matheus Vieira <matheus.vieira@deal.com.br>
 * @since 2020-11-18
 */
export const SearchIcon: any = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.7192 18.3109L16.0114 14.6309C17.4506 12.8353 18.1476 10.5562 17.959 8.2622C17.7705 5.9682 16.7106 3.83368 14.9975 2.29754C13.2844 0.761407 11.0481 -0.0595894 8.74862 0.00337096C6.44911 0.0663314 4.26109 1.00846 2.63448 2.63604C1.00786 4.26362 0.066292 6.45294 0.00336896 8.75382C-0.059554 11.0547 0.760954 13.2923 2.29618 15.0064C3.83141 16.7206 5.96466 17.781 8.25729 17.9697C10.5499 18.1584 12.8277 17.461 14.6222 16.0209L18.3 19.7009C18.3929 19.7946 18.5035 19.869 18.6253 19.9198C18.747 19.9706 18.8777 19.9967 19.0096 19.9967C19.1415 19.9967 19.2722 19.9706 19.3939 19.9198C19.5157 19.869 19.6263 19.7946 19.7192 19.7009C19.8993 19.5144 20 19.2652 20 19.0059C20 18.7466 19.8993 18.4974 19.7192 18.3109ZM9.01554 16.0209C7.63189 16.0209 6.27932 15.6104 5.12886 14.8412C3.9784 14.072 3.08172 12.9788 2.55223 11.6997C2.02273 10.4206 1.88419 9.01314 2.15412 7.65527C2.42406 6.2974 3.09035 5.05012 4.06873 4.07115C5.04712 3.09219 6.29366 2.4255 7.65072 2.1554C9.00778 1.88531 10.4144 2.02393 11.6927 2.55374C12.9711 3.08356 14.0637 3.98076 14.8324 5.13191C15.6011 6.28305 16.0114 7.63643 16.0114 9.0209C16.0114 10.8774 15.2743 12.6579 13.9623 13.9706C12.6504 15.2834 10.871 16.0209 9.01554 16.0209Z"
        fill="#101010"
      />
    </svg>
  );
};
