import React, { useCallback, useState } from 'react';

// COMPONENTS
import { Row, Col } from '../../../components/GRID';
import { Button } from '../../../components/UI/Button';

// STYLES
import { ContentConfirmation, TitleConfirmation, SubtileConfirmation, ContentAction } from '../styles';
//ICON
import { ChekcConfirmation, Clip } from '../svgs';
// ENUMS
import { Modals } from '../../../enums/portals.enum';

// CONTEXT
import { useProposal } from '../../../hooks/AppContext';
import { usePortals } from '../../../hooks/PortalsContext';

// SERVICES
import ProposalService from '../../../services/ProposalService';

export const Confirmation = () => {
  const { formOpened, setFormOpened } = useProposal();
  const [isDisabled, setDisabled] = useState(false);
  const { handleOpenPortal } = usePortals();

  const handleOpenModalConfirm = useCallback(async () => {
    setDisabled(true);
    if (formOpened.url === null) {
      const fetchData = await ProposalService.fetchLinkSignature(formOpened.documentNumber, formOpened.type);

      setFormOpened({
        ...formOpened,
        ...fetchData.data,
      });
      setDisabled(false);
      handleOpenPortal(Modals.LINKPROPOSAL);
    } else {
      setDisabled(false);
      handleOpenPortal(Modals.LINKPROPOSAL);
    }
  }, [formOpened]);

  const handleNewProposal = () => {
    location.reload();
  };

  return (
    <Row>
      <Col sm={18} md={18} lg={18} justifyContent="center">
        <ContentConfirmation>
          <ChekcConfirmation />
          <TitleConfirmation>Proposta enviada com sucesso!</TitleConfirmation>
          <SubtileConfirmation>
            Para finalizar, gere um link para que o cliente possa assinar a proposta.
          </SubtileConfirmation>
          <ContentAction>
            <Button black big spaced onClick={handleOpenModalConfirm} iconLeft={Clip} {...{ disabled: isDisabled }}>
              Gerar link de assinatura
            </Button>

            <Button white big spaced onClick={handleNewProposal}>
              Cadastrar nova proposta
            </Button>
          </ContentAction>
        </ContentConfirmation>
      </Col>
    </Row>
  );
};
