import React from 'react';
import { Container, UserName, ItemDropDown } from './styles';
import { Avatar } from './svgs';

interface ProfileProps {
  username: string;
}

const Profile = (props: ProfileProps) => {
  const { username } = props;

  return (
    <Container>
      <UserName>{username}</UserName>
      <Avatar />
    </Container>
  );
};

export default Profile;
