import React, { ReactElement } from 'react';

export const ArrowRight = (): ReactElement => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="#ffffff" xmlns="http://www.w3.org/2000/svg">
      <path
        opacity="0.7"
        d="M21.8263 21.5634L25.2354 16.5634C25.4671 16.2236 25.4671 15.7766 25.2354 15.4368L21.8263 10.4368C21.5152 9.98045 20.8931 9.86275 20.4368 10.1739C19.9805 10.485 19.8628 11.1071 20.1739 11.5634L23.15 16L20.1739 20.4368C19.8628 20.8931 19.9805 21.5152 20.4368 21.8263C20.8931 22.1374 21.5152 22.0197 21.8263 21.5634Z"
      />
      <path
        opacity="0.4"
        d="M15.8263 21.5634L19.2354 16.5634C19.4671 16.2236 19.4671 15.7766 19.2354 15.4368L15.8263 10.4368C15.5152 9.98045 14.8931 9.86275 14.4368 10.1739C13.9805 10.485 13.8628 11.1071 14.1739 11.5634L17.15 16L14.1739 20.4368C13.8628 20.8931 13.9805 21.5152 14.4368 21.8263C14.8931 22.1374 15.5152 22.0197 15.8263 21.5634Z"
      />
      <path
        opacity="0.2"
        d="M9.82634 21.5634L13.2354 16.5634C13.4671 16.2236 13.4671 15.7766 13.2354 15.4368L9.82634 10.4368C9.51522 9.98045 8.89309 9.86275 8.43678 10.1739C7.98047 10.485 7.86277 11.1071 8.17389 11.5634L11.15 16L8.17389 20.4368C7.86277 20.8931 7.98047 21.5152 8.43678 21.8263C8.89309 22.1374 9.51522 22.0197 9.82634 21.5634Z"
      />
      <path
        opacity="0.1"
        d="M3.82634 21.5634L7.23544 16.5634C7.46713 16.2236 7.46713 15.7766 7.23544 15.4368L3.82634 10.4368C3.51522 9.98045 2.89309 9.86275 2.43678 10.1739C1.98047 10.485 1.86277 11.1071 2.17389 11.5634L5.15 16L2.17389 20.4368C1.86277 20.8931 1.98047 21.5152 2.43678 21.8263C2.89309 22.1374 3.51522 22.0197 3.82634 21.5634Z"
      />
      <path d="M27.8263 21.5634L31.2354 16.5634C31.4671 16.2236 31.4671 15.7766 31.2354 15.4368L27.8263 10.4368C27.5152 9.98045 26.8931 9.86275 26.4368 10.1739C25.9805 10.485 25.8628 11.1071 26.1739 11.5634L29.15 16L26.1739 20.4368C25.8628 20.8931 25.9805 21.5152 26.4368 21.8263C26.8931 22.1374 27.5152 22.0197 27.8263 21.5634Z" />
    </svg>
  );
};

export const ArrowLeft = (): ReactElement => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="#000000" xmlns="http://www.w3.org/2000/svg">
      <path
        opacity="0.7"
        d="M11.5828 10.4368L8.17374 15.4368C7.94205 15.7766 7.94205 16.2237 8.17374 16.5635L11.5828 21.5635C11.894 22.0198 12.5161 22.1375 12.9724 21.8264C13.4287 21.5153 13.5464 20.8931 13.2353 20.4368L10.2592 16.0002L13.2353 11.5635C13.5464 11.1072 13.4287 10.485 12.9724 10.1739C12.5161 9.8628 11.894 9.9805 11.5828 10.4368Z"
      />
      <path
        opacity="0.4"
        d="M17.5828 10.4368L14.1737 15.4368C13.942 15.7766 13.942 16.2237 14.1737 16.5635L17.5828 21.5635C17.894 22.0198 18.5161 22.1375 18.9724 21.8264C19.4287 21.5153 19.5464 20.8931 19.2353 20.4368L16.2592 16.0002L19.2353 11.5635C19.5464 11.1072 19.4287 10.485 18.9724 10.1739C18.5161 9.8628 17.894 9.9805 17.5828 10.4368Z"
      />
      <path
        opacity="0.2"
        d="M23.5828 10.4368L20.1737 15.4368C19.942 15.7766 19.942 16.2237 20.1737 16.5635L23.5828 21.5635C23.894 22.0198 24.5161 22.1375 24.9724 21.8264C25.4287 21.5153 25.5464 20.8931 25.2353 20.4368L22.2592 16.0002L25.2353 11.5635C25.5464 11.1072 25.4287 10.485 24.9724 10.1739C24.5161 9.8628 23.894 9.9805 23.5828 10.4368Z"
      />
      <path
        opacity="0.1"
        d="M29.5828 10.4368L26.1737 15.4368C25.942 15.7766 25.942 16.2237 26.1737 16.5635L29.5828 21.5635C29.894 22.0198 30.5161 22.1375 30.9724 21.8264C31.4287 21.5153 31.5464 20.8931 31.2353 20.4368L28.2592 16.0002L31.2353 11.5635C31.5464 11.1072 31.4287 10.485 30.9724 10.1739C30.5161 9.8628 29.894 9.9805 29.5828 10.4368Z"
      />
      <path d="M5.58284 10.4368L2.17374 15.4368C1.94205 15.7766 1.94205 16.2237 2.17374 16.5635L5.58284 21.5635C5.89396 22.0198 6.51609 22.1375 6.9724 21.8264C7.42871 21.5153 7.54641 20.8931 7.23529 20.4368L4.25918 16.0002L7.23529 11.5635C7.54641 11.1072 7.42871 10.485 6.9724 10.1739C6.51609 9.8628 5.89396 9.9805 5.58284 10.4368Z" />
    </svg>
  );
};
