import React, { useCallback } from 'react';
import { Container, Label, Field } from './styles';

/**
 * @author Matheus Vieira <matheus.vieira@deal.com.br>
 * @since 2020-11-23
 */
interface CheckboxProps {
  label: string;
  name: string;
  onChange: any;
  checked: boolean;
  disabled?: boolean;
  value?: any;
}

const Checkbox = (props: CheckboxProps) => {
  const { name, label, checked, onChange, disabled = false, ...rest } = props;

  const handleChange = useCallback(
    (e) => {
      if (!e.target.disabled) {
        onChange(e);
      }
    },
    [onChange],
  );

  return (
    <Container>
      <Field
        type="checkbox"
        id={name}
        name={name}
        checked={checked}
        onChange={handleChange}
        disabled={disabled}
        {...rest}
      />
      <Label htmlFor={name}>{label}</Label>
    </Container>
  );
};

export default Checkbox;
