import { cpf, cnpj } from 'cpf-cnpj-validator';

export const isRegularTerm = (term: any) => {
  if (!term) {
    return false;
  }

  const isDocument = cpf.isValid(term) || cnpj.isValid(term);
  const isNumber = !!parseFloat(term);
  const isDate = !!new Date(term).toJSON();

  if (isDocument || isNumber || isDate) {
    return false;
  }

  return true;
};

export const changeColumns = (items: any) => {
  const ids = [7, 8];

  items.forEach((item: any) => {
    if (ids.includes(item.id)) {
      item.status = true;
    }
  });

  return items;
};
