import React from 'react';

// COMPONENTS
import { Button } from '../../Button';

// STYLES
import { ActionButtons, ModalContainer, ModalContent, ModalTitle, ModalSubtitle } from '../styles';
import { Warning } from '../svgs';

interface IModalPurchaseMargin {
  message: string;
  onClose: () => null;
  onConfirm: () => null;
}

const ModalPurchaseMarginLimit = ({ message, onClose, onConfirm }: IModalPurchaseMargin) => {
  return (
    <ModalContent>
      <ModalContainer>
        <Warning width="40" height="40" />
        <ModalTitle>Deseja confirmar ação?</ModalTitle>
      </ModalContainer>
      <ModalSubtitle>{message}</ModalSubtitle>
      <ActionButtons className="left">
        <Button onClick={onClose} big>
          Cancelar
        </Button>
        <Button onClick={onConfirm} big black>
          Confirmar
        </Button>
      </ActionButtons>
    </ModalContent>
  );
};

export default ModalPurchaseMarginLimit;
