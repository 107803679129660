import React from 'react';

import {
  ContentScreensErrorPermissions,
  TitlePermissions,
  CardPermissions,
  CardTitle,
  CardText,
  CardFooter,
  CardFooterText,
} from './styles';
// SVG
import { IconErrorPermissionGeolocation, IconErrorPermissionCam, IconDoubt } from './svg';

interface ComponentProps {
  removePadding?: boolean;
  background?: boolean;
  titlePage: string;
  cardTitle: string;
  cardFooterlink: string;
  cardFooterText: string;
  icon: React.ComponentType;
  children: React.ReactNode;
}

export const ErrorPermissions: React.FC<ComponentProps> = ({ icon: Icon, children, ...props }) => {
  return (
    <ContentScreensErrorPermissions {...props}>
      <TitlePermissions>{props.titlePage}</TitlePermissions>
      <CardPermissions>
        {/* @ts-ignore */}
        {<Icon />}
        <CardTitle>{props.cardTitle}</CardTitle>
        <CardText>{children}</CardText>

        <CardFooter>
          <IconDoubt />
          <CardFooterText href={props.cardFooterlink}>{props.cardFooterText}</CardFooterText>
        </CardFooter>
      </CardPermissions>
    </ContentScreensErrorPermissions>
  );
};
