import styled, { css } from 'styled-components';

interface propsContent {
  iconRight?: any;
  iconLeft?: any;
}

interface propsButton {
  black?: any;
  white?: any;
  outline?: any;
  color?: string;
  big?: any;
  small?: any;
  large?: any;
  transparent?: any;
  width?: any;
  iconRight?: any;
  iconLeft?: any;
}

export const Content = styled.div<propsContent>`
  width: 100%;
  align-items: center;

  ${(props: any) =>
    props.iconRight || props.iconLeft
      ? `
      justify-content: space-between;
  `
      : `
      justify-content: center;
  `}
`;
export const LeftContent = styled.div`
  margin-right: 12px;
`;
export const RightContent = styled.div`
  margin-left: 12px;
`;

export const Container = styled.button<propsButton>`
  padding: 10px 23px 10px 16px;
  cursor: pointer;
  outline: 0;
  border: none;
  border-radius: 10px;
  font-family: 'Clan Web Pro Medium';
  font-weight: 500;
  line-height: 24px;
  font-size: 14px;
  transition: all 0.65s;
  white-space: nowrap;

  & + button {
    margin-left: 16px;
  }

  &:disabled {
    cursor: not-allowed;
    background: #c2c2c2;
  }
  &.disabled {
    cursor: not-allowed;
    background: #c2c2c2;
  }

  ${(props: any) =>
    props.primary &&
    `
      background: #0090FF;
      color: #ffffff;
      &:hover {
        background-color: #008af5;
      }
  `}
  ${(props: any) =>
    props.white &&
    `
      background: #FFFFFF;
      color: #101010;
      font-size: 14px;
      line-height: 24px;
      border: 1px solid #C2C2C2;
      &:hover {
        background-color: #E6F5FE;
        border: 1px solid #A0D8FD;
        color: #0090FF;
        & path {
          fill: #0090FF;
        }
      }
  `}
    ${(props: any) =>
    props.black &&
    `
      background: #101010;
      color: #FFFFFF;
      font-size: 14px;
      line-height: 24px;
      
  `}

${({ black, outline }) =>
    black &&
    outline &&
    css`
      background: none;
      color: #101010;
      border: 1px solid #c2c2c2;
      &:hover {
        background: #e6f5fe;
        border: 1px solid #a0d8fd;
        color: #0090ff;
        & path {
          fill: #0090ff;
        }
      }
      & path {
        fill: #101010;
      }
    `}

${(props: any) =>
    props.color &&
    `
      background: ${props.color};
      color: #FFFFFF;
      font-size: 14px;
      line-height: 24px;
  `}

      ${(props: any) =>
    props.transparent &&
    `
      background: #FFFFFF;
      color: #101010;
      font-size: 14px;
      line-height: 24px;
      color: #0090ff;
  `}

  ${(props: any) =>
    props.big &&
    `
    display: flex;
    height: 50px;
    align-items: center;
    padding: 10px 32px;
  `}

  ${(props: any) =>
    props.small &&
    `   
    padding: 14px 12px 14px 12px;
    position: relative;
    text-align: left;
    min-width: 223px;
    svg {
      position: absolute;
      right: 12px;
      margin-left: 22px;
    }
  `}
  ${(props: any) =>
    props.large &&
    `
    padding: 9px 12px 9px 16px;
    position: relative;
    text-align: left;
    min-width: 223px;
    div{
      display: flex;
      justify-content: space-between;
    }
    svg {
      position: absolute;
      right: 12px;
      margin-left: 22px;
    }
  `}

  ${(props: any) =>
    props.full &&
    `   
    width: 100%;
  `}

  ${(props: any) =>
    props.uploader &&
    `   
    padding: 8px 16px;
    position: relative;
    text-align: left;
    svg {
      position: absolute;
      right: 12px;
      margin-left: 22px;
    }
  `}

  ${(props: any) =>
    props.centered &&
    `
      min-width: inherit;
      padding: 13px 11px;
    `}

  ${(props: any) =>
    props.disabled &&
    props.black &&
    `
      background: #c2c2c2;
      &:hover {
        background-color: #c2c2c2;
      }
    `}

  ${(props: any) =>
    props.disabled &&
    props.white &&
    `
      background: #c2c2c2;
      &:hover {
        background-color: #c2c2c2;
        color: #101010;
        border-color: #c2c2c2;
        & path {
          fill: #101010;
        }
      }
    `} 

  ${(props: any) =>
    props.fixedWidth &&
    `
    width: 240px;
    `}

${(props: any) =>
    props.width &&
    `
    width: ${props.width};
    `}
`;
