import React from 'react';

// COMPONENTS
import { Button } from '../../../UI/Button';

// STYLES
import { ActionButtons, Close, ModalContainer, ModalContent, ModalTitle, ModalSubtitle } from '../styles';

// SVGS
import { AnalysisProposal } from '../svgs';

import { useNavigate } from 'react-router-dom';

interface IModalErrorProposalInconsistent {
  onClose: any;
}

const ModalAnalysisProposal = ({ onClose }: IModalErrorProposalInconsistent) => {
  const navigate = useNavigate();

  return (
    <ModalContent>
      <ModalContainer>
        <AnalysisProposal />
        <ModalTitle>Proposta em análise</ModalTitle>
      </ModalContainer>
      <ModalSubtitle>
        A proposta já foi enviada e está em análise. Avisaremos por e-mail assim que a análise for concluída.
      </ModalSubtitle>
      <ActionButtons className="left">
        <Button onClick={() => navigate('/manage-proposal')} big black>
          Gerenciar propostas
        </Button>
        <Button onClick={onClose} big transparent>
          Nova proposta
        </Button>
      </ActionButtons>
    </ModalContent>
  );
};

export default ModalAnalysisProposal;
