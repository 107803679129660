import React, { useCallback } from 'react';
import { Container, Options, Actions, Button, Icon } from './styles';
import { Arrows } from './svgs';

/**
 * @author Matheus Vieira <matheus.vieira@deal.com.br>
 * @since 2020-11-20
 */
interface PanelProps {
  children: any;
  active: boolean;
  leftSide?: boolean;
  rightSide?: boolean;
  disabled?: boolean;
  onApply: any;
  className?: string;
  id?: string;
}

const Panel = (props: PanelProps) => {
  const { children, active, leftSide, rightSide, onApply, className = '', id, disabled = false } = props;

  const handleApply = useCallback(() => {
    if (!disabled) {
      onApply();
    }
  }, [onApply, disabled]);

  return (
    <Container active={active} leftSide={leftSide} rightSide={rightSide}>
      <Options className={className} id={id}>
        {children}
      </Options>
      <Actions onClick={handleApply}>
        <Button onClick={handleApply} disabled={disabled}>
          Aplicar
        </Button>
        <Icon onClick={handleApply} className={disabled ? 'Disabled' : ''}>
          <Arrows />
        </Icon>
      </Actions>
    </Container>
  );
};

export default Panel;
