import { useState, useEffect } from 'react';

const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;

  return {
    width,
    height,
  };
};

interface ComponentProps {
  children: any;
  maxWidth?: number;
  minWidth?: number;
}

const MediaQuery: React.FC<ComponentProps> = ({ children, maxWidth = 0, minWidth = 0 }: ComponentProps) => {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  if ((windowDimensions.width >= maxWidth && maxWidth > 0) || (windowDimensions.width < minWidth && minWidth > 0)) {
    return children;
  }

  return null;
};

export default MediaQuery;
