import React, { useCallback } from 'react';
import { Container, Input } from './styles';
import { SearchIcon } from './svgs';

interface ComponentProps {
  name: string;
  id: string;
  type: string;
  placeholder: string;
  className?: string;
  onSearch: any;
  value: string;
}

const SearchInput = ({ onSearch, ...props }: ComponentProps) => {
  const handleChange = useCallback(
    (e: any) => {
      onSearch(e.target.value);
    },
    [onSearch],
  );

  return (
    <Container className={props.className}>
      <SearchIcon />
      <Input {...props} onChange={handleChange} />
    </Container>
  );
};

export default SearchInput;
