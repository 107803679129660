import styled from 'styled-components';

export const HeaderCam = styled.div`
  padding: 32px 20px;
  align-items: center;
  justify-content: center;
  position: relative;

  button {
    background-color: inherit;
    border: none;
    position: absolute;
    left: 18px;
  }
`;

export const Image = styled.div`
  img {
    object-fit: cover;
    width: 100%;
    height: 366px;
  }
`;

export const TitleCam = styled.h4`
  color: var(--color-black-primary);
  font-size: 16px;
  line-height: 24px;
  text-align: center;
`;

export const ContainerButton = styled.div`
  width: 100%;
  flex-direction: column;
  padding: 0 32px 32px 32px;

  > button {
    width: 100%;
  }
  > button:last-child {
    margin-left: 0px !important;
    margin-top: 8px;
  }
`;

export const Mask = styled.div`
  position: relative;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  flex: 1;
  :after {
    content: '';
    position: absolute;
    border-radius: 100%;
    box-shadow: 0px 0px 0px 2000px #101010;
    opacity: 0.8;
    border: 3px solid #0090ff;
    width: 247px;
    height: 346px;
    bottom: 160px;
  }
`;

export const ActionCaptureSelfie = styled.div`
  position: absolute;
  bottom: 26px;
  width: 100%;
  justify-content: center;
  z-index: 1;
  button {
    svg {
      width: 84px;
      height: 84px;
    }
  }
  .action-selfie {
    background: none;
    border: none;
  }
`;

export const FooterCam = styled.div`
  height: 68px;
  justify-content: center;

  align-items: center;
  p {
    font-size: 14px;
    line-height: 16px;
    color: var(--color-black-primary);
  }
`;

export const TitleButton = styled.h4`
  color: var(--color-black-primary);
  font-size: 16px;
  line-height: 22px;
  margin: 24px 0px;
  text-align: center;
`;
