import styled from 'styled-components';
import { boolean } from 'yup';

export const Content = styled.div`
  flex-direction: column;
  form {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .inputNumberProposal {
      height: 100%;
      align-items: flex-start;

      & div {
        margin-right: 0px !important;
      }
      & label {
        margin-bottom: 12px;
      }
    }
    .purchaseMargin {
      width: 250px;
    }
    .button {
      display: flex;
      margin-top: 30px;
    }
  }

  button {
    max-height: 58px;
    min-height: 58px;
    max-width: 58px;
    min-width: 58px;
    padding: 0px;
    div {
      align-items: center;
      justify-content: center;
    }
    svg {
      fill: #ffffff !important;
    }
  }
`;

export const TitleListProposal = styled.h4`
  font-size: 16px;
  line-height: 32px;
  color: var(--color-black-primary);
  font-family: 'Clan Web Pro News';
  margin-bottom: 4px;
`;
export const ParagraphListProposal = styled.h6`
  font-size: 12px;
  line-height: 22px;
  color: #8a8d8f;
  font-family: 'Clan Web Pro Medium';
  margin-top: 8px;
`;

export const LisItems = styled.div`
  flex-direction: column;
  margin-top: 24px;
  border: 1px solid #f5f6f7;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 24px;
  &:nth-child(1n) {
    margin-top: 16px;
  }
`;

export const ContentItems = styled.div`
  align-items: center;
  justify-content: space-between;
  font-family: 'Clan Web Pro News';
  font-size: 13px;
  div {
    flex-direction: column;
  }
  button {
    background: none;
    border: none;
    margin-right: 24px;
    :focus {
      outline: none;
    }
  }
`;

export const Count = styled.p`
  font-family: 'Clan Web Pro News';
  color: #8a8d8f;
  font-size: 24px;
  line-height: 30px;

  width: 50px;
`;

export const ValueContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 16px;
`;

export const TitleItem = styled.p`
  font-family: 'Clan Web Pro Medium';
  color: #8a8d8f;
  font-size: 12px;
  line-height: 16px;
  margin-right: 16px;
  width: 100%;
`;
export const ValueItem = styled.p`
  font-family: 'Clan Web Pro Medium';
  color: var(--color-black-primary);
  font-size: 14px;
  line-height: 24px;
  margin-top: 4px;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Badge = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  padding: 5px 10px;
  background-color: #e6f5fe;
  color: #0073c8;
`;

export const BadgeContainer = styled.div`
  width: 130px;
`;

export const FormContainer = styled.div<{ hasConsignCard: boolean }>`
  display: grid;
  grid-template-columns: ${({ hasConsignCard }) => (hasConsignCard ? '1fr 1fr' : '1fr 1fr 50px')};
  gap: 20px;
  row-gap: 0px;
`;
