export enum Status {
  CREATED = 'CREATED',
  AWAITING_SIGNATURE = 'AWAITING_SIGNATURE',
  AWAITING_CHECKING_ACCOUNT = 'AWAITING_CHECKING_ACCOUNT',
  AWAITING_CONDITIONS = 'AWAITING_CONDITIONS',
  FORMALIZATION = 'FORMALIZATION',
  MADE_EFFECTIVE = 'MADE_EFFECTIVE',
  REJECTED = 'REJECTED',
  PENDING_DOCUMENTS = 'PENDING_DOCUMENTS',
  PENDING_SIGN = 'PENDING_SIGN',
  PENDING_DOCUMENTS_SIGN = 'PENDING_DOCUMENTS_SIGN',
  REFUSED = 'REFUSED',
  ENDORSED = 'ENDORSED',
  PENDING_PURCHASE_MARGIN = 'PENDING_PURCHASE_MARGIN',
  PENDING_PURCHASE_MARGIN_WRONG_PROPOSAL = 'PENDING_PURCHASE_MARGIN_WRONG_PROPOSAL',
}
