import React from 'react';
import { Form } from '../Form';

// import { Container } from './styles';

const Proposal: React.FC = () => {
  return <Form></Form>;
};

export default Proposal;
