// @ts-nocheck
import React, { useCallback } from 'react';
import { Container, Button, Pages, CurrentPage, TotalPages } from './styles';
import { ArrowRight, ArrowLeft } from './svgs';

interface PaginationProps {
  currentPage?: number;
  totalPages?: number;
  nextEnable?: boolean;
  prevEnable?: boolean;
  onNext?: any;
  onPrev?: any;
  onChange?: any;
  loading?: boolean;
}

const Pagination = (props: PaginationProps) => {
  const { currentPage, totalPages, nextEnable, prevEnable, onNext, onPrev, onChange, loading } = props;
  const pageNumber = currentPage + 1;

  const handleNext = useCallback(() => {
    if (nextEnable || !loading) {
      onNext();
    }
  }, [onNext, loading]);

  const handlePrev = useCallback(() => {
    if (prevEnable || !loading) {
      onPrev();
    }
  }, [onPrev, loading]);

  const handleChange = useCallback(
    (e) => {
      onChange(e.target.value);
    },
    [onChange, totalPages],
  );

  return (
    <Container>
      <Button disabled={!prevEnable || loading} onClick={handlePrev}>
        <ArrowLeft />
      </Button>
      <Pages>
        <CurrentPage type="text" id="page" name="page" value={pageNumber} onChange={handleChange} disabled={loading} />
        <TotalPages>de {totalPages}</TotalPages>
      </Pages>
      <Button disabled={!nextEnable || loading} onClick={handleNext}>
        <ArrowRight />
      </Button>
    </Container>
  );
};

export default Pagination;
