import styled from 'styled-components';

interface ISkeleton {
  height?: number;
  minWidthOf10px?: boolean;
  minWidthOf147px?: boolean;
  width?: number;
}

export const Container = styled.span<ISkeleton>`
  &.SkeletonBadge {
    padding: 13px;
    width: 117px;
    max-width: 117px;
    border-radius: 41px;
    &:before {
      width: 117px;
      opacity: 0.3;
      background: #e5e5e5;
      border-radius: 23px;
    }
  }
  &.SkeletonHeader {
    padding: 16px;
    min-width: ${({ minWidthOf147px }) => (minWidthOf147px ? '147px' : '395px')};
    border-radius: 41px;
    &:before {
      min-width: ${({ minWidthOf147px }) => (minWidthOf147px ? '147px' : '395px')};
      opacity: 0.3;
      background: #e5e5e5;
      border-radius: 8px;
    }
  }
  &.SkeletonCard {
    padding: 35px;
    border-radius: 8px;
    &:before {
      min-width: 100%;
      opacity: 0.3;
      background: #e5e5e5;
      border-radius: 8px;
    }
  }

  &.SkeletonText {
    ${({ height }) =>
      height
        ? `
      height: ${height}px;
    `
        : `
      padding: 8px;
    `}

    width: ${({ width }) => (width ? `${width}px` : '80%')};
    min-width: ${({ minWidthOf10px }) => (minWidthOf10px ? '10px' : '100px')};
    border-radius: 4px;
    @media only screen and (max-width: 1365px) {
      padding: 13px;
    }
    &:before {
      width: 100%;
      opacity: 0.3;
      background: #e5e5e5;
      border-radius: 8px;
    }
  }

  &.SkeletonTimelineIndicator {
    position: absolute;
    z-index: 1;
    content: '';
    width: 8px;
    height: 8px;
    left: -4px;
    top: 0;
    bottom: 0;
    margin: auto;
    background: #e5e5e5;
    border-radius: 50%;
  }

  &.SkeletonTitle {
    padding: 8px;
    width: 76px;
    min-width: 76px;
    border-radius: 4px;
    margin-bottom: 4px;
    &:before {
      width: 100%;
      opacity: 0.3;
      background: #e5e5e5;
      border-radius: 8px;
    }
  }

  display: block;
  background: #f6f6f6;
  position: relative;
  overflow: hidden;

  &:before {
    content: '';
    position: absolute;
    left: -70%;
    top: 0;
    bottom: 0;
    animation: loading 1.5s linear infinite;
  }
`;
