import styled from 'styled-components';

interface Container {
  percentAlignSpan?: string;
  origin?: string;
  length: number;
  active?: boolean;
}

export const Container = styled.div<Container>`
  position: relative;

  span {
    background: #101010;
    padding: 8px;
    border-radius: 4px;
    font-size: ${({ origin }) => (origin === 'step-card' ? '10' : '14')}px;
    font-weight: 500;
    opacity: 0;
    transition: opacity 0.4s;
    visibility: hidden;
    position: absolute;
    bottom: ${({ origin }) => (origin == 'step-card' ? 'calc(100% + 24px)' : 'calc(100% + 10px)')};
    z-index: 10000;

    width: ${({ origin, length }) =>
      origin == 'list' ? `calc(100% + ${length}%)` : origin == 'icon' || origin == 'step-card' ? '216px ' : ''};
    left: ${({ percentAlignSpan }) => (percentAlignSpan ? percentAlignSpan : '50%')};
    transform: translateX(-50%);
    display: flex;
    justify-content: center;

    color: #fff;

    &::before {
      content: '';
      border-style: solid;
      border-color: #101010 transparent;
      border-width: 8px 8px 0 8px;
      top: 100%;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  ${({ origin }) =>
    origin != 'step-card' &&
    `
    &:hover span {
      opacity: 1;
      visibility: visible;
    }
  `}

  ${({ origin, active }) =>
    origin == 'step-card' &&
    active &&
    `
    &:hover span {
      opacity: 1;
      visibility: visible;
    }
  `}
`;
